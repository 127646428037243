import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { MetadataParent } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/metadata-parent';

@Component({
  selector: 'app-as-applied-uploaded-files',
  templateUrl: './as-applied-uploaded-files.component.html',
  styleUrls: ['./as-applied-uploaded-files.component.scss'],
})
export class AsAppliedUploadedFilesComponent {
  @Input() public metadataParents!: MetadataParent[];
  @Output() public uploadSelect = new EventEmitter<MetadataParent>();

  public uploadedFilesTableColumn = {
    displayDate: this.languageService.getText('main.asApplied.metaData.uploadDate'),
    name: this.languageService.getText('main.asApplied.metaData.fileName'),
  };

  public readonly tableStyles = {
    productName: {
      color: '#4e808d',
      fontWeight: 'bold',
      padding: '0.7rem 0.5rem 0.7rem 0',
      textAlign: 'left',
    },
    cropName: {
      color: '#4e808d',
      fontWeight: 'bold',
      padding: '0.7rem 1.5rem 0.7rem 0',
      textAlign: 'left',
    },
    displayDate: { whiteSpace: 'nowrap', padding: '0.7rem 0.5rem', textAlign: 'left' },
    name: {
      color: '#4e808d',
      fontWeight: 'bold',
      padding: '0.7rem 0.5rem 0.7rem 0',
      textAlign: 'left',
      overflow: 'hidden',
    },
  };

  constructor(private languageService: LanguageService) {}

  public onFileSelect(metadataParent: MetadataParent) {
    this.uploadSelect.emit(metadataParent);
  }

  public fileInitSortFn = (a: MetadataParent, b: MetadataParent) => CompareHelper.compare(a.createdDate, b.createdDate);

  public readonly sortFileFn = (item: MetadataParent, property: keyof MetadataParent) => {
    return item[property];
  };
}

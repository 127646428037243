import { NgModule } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { AsAppliedUploadModule } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/as-applied-upload.module';
import { ScaleLegendModule } from '@app/shared/scale-legend/scale-legend.module';
import { SharedModule } from '@app/shared/shared.module';
import { AddAdditionalLogsModalComponent } from './add-additional-logs-modal/add-additional-logs-modal.component';
import { AsAppliedFieldTasksComponent } from './as-applied-field-tasks/as-applied-field-tasks.component';
import { AsAppliedLinkToTaskComponent } from './as-applied-link-to-task/as-applied-link-to-task.component';
import { AsAppliedTaskDetailsComponent } from './as-applied-task-details/as-applied-task-details.component';
import { AsAppliedTaskInfoComponent } from './as-applied-task-details/as-applied-task-info/as-applied-task-info.component';
import { AsAppliedTaskInfoProductTableComponent } from './as-applied-task-details/as-applied-task-info/product-table/product-table.component';
import { AsAppliedTaskLogListComponent } from './as-applied-task-details/as-applied-task-log-list/as-applied-task-log-list.component';
import { AsAppliedTaskAccordionComponent } from './as-applied-tasks/as-applied-task-accordion/as-applied-task-accordion.component';
import { AsAppliedTasksComponent } from './as-applied-tasks/as-applied-tasks.component';
import { AsAppliedTooltipComponent } from './as-applied-tooltip/as-applied-tooltip.component';
import { AsAppliedTooltipModule } from './as-applied-tooltip/as-applied-tooltip.module';
import { AsAppliedUploadedFilesComponent } from './as-applied-uploaded-files/as-applied-uploaded-files.component';
import { AsAppliedComponent } from './as-applied.component';
import { AsAppliedConfigFileComponent } from './file-upload/as-applied-meta-data/as-applied-config-file/as-applied-config-file.component';

@NgModule({
  declarations: [
    AsAppliedComponent,
    AsAppliedTasksComponent,
    AsAppliedFieldTasksComponent,
    AsAppliedTaskDetailsComponent,
    AsAppliedTaskAccordionComponent,
    AsAppliedTaskInfoComponent,
    AsAppliedTaskInfoProductTableComponent,
    AsAppliedUploadedFilesComponent,
    AsAppliedLinkToTaskComponent,
    AsAppliedConfigFileComponent,
    AsAppliedTaskLogListComponent,
    AddAdditionalLogsModalComponent,
  ],
  exports: [AsAppliedComponent, AsAppliedTooltipComponent],
  imports: [SharedModule, ScaleLegendModule, AsAppliedTooltipModule, AsAppliedUploadModule],
  providers: [{ provide: MAT_DATE_FORMATS, useFactory: LanguageConstants.getMatDateFormat, deps: [LanguageService] }],
})
export class AsAppliedModule {}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateValidators } from '@app/helpers/validators/forms/date-validators';
import { AsAppliedTaskDetailsService } from '@app/new-map/features/field-analysis/features/as-applied/as-applied-task-details/services/as-applied-task-details.service';
import { ExecutedTask } from '@app/new-map/features/field-analysis/features/as-applied/executed-task.class';
import { OperationTypeGroupTaskCollection } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/meta-data-child-form/meta-data-child-form.component';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-as-applied-link-to-task',
  templateUrl: './as-applied-link-to-task.component.html',
  styleUrls: ['./as-applied-link-to-task.component.scss'],
})
export class AsAppliedLinkToTaskComponent implements OnInit {
  public readonly MAX_DATE = DateTime.now();

  @Input() public executedTasks!: ExecutedTask[];
  @Input() public disableDateSelection = true;
  @Input() public disableFieldSelection = true;
  @Input() public disableTaskSelection = false;
  @Input() public taskSuggestions$!: Observable<OperationTypeGroupTaskCollection[]>;

  public form!: FormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private asAppliedTaskDetailsService: AsAppliedTaskDetailsService
  ) {}

  ngOnInit(): void {
    this.form = this.createChildFormGroup();
  }

  private createChildFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      appliedDate: new FormControl({ value: this.executedTasks.first()?.date ?? '', disabled: this.disableDateSelection }, [
        Validators.required,
        DateValidators.maxDate(this.MAX_DATE),
      ]),
      // If the element has a fieldId, it should not be editable.
      // This is because BE cant handle a change in field, if the element is already matched to a specific fieldYearId.
      fieldNumber: new FormControl(
        { value: this.executedTasks.first()?.fieldName ?? '', disabled: this.disableFieldSelection },
        Validators.required
      ),
      metadataTask: new FormControl({ value: '', disabled: this.disableTaskSelection }, Validators.required),
    });
  }

  public saveClicked() {
    const values = this.form.value;
    if (values) {
      this.asAppliedTaskDetailsService.updateExecutedTask(
        this.executedTasks,
        values.appliedDate,
        values.metadataTask.taskId,
        values.metadataTask.operationTypeGroup.id
      );
    }
  }
}

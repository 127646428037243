import { LayerId } from '@app/new-map/services/layer/layer.store';

export class LayerIDHelper {
  private static layerIdToLabelMap: { [key in LayerId]: string } = {
    [LayerId.NONE]: 'NONE',
    [LayerId.OSM]: 'OSM',
    [LayerId.AIRPHOTO_DK]: 'AIRPHOTO_DK',
    [LayerId.AIRPHOTO_FOREIGN]: 'AIRPHOTO_FOREIGN',
    [LayerId.GEO_LOCATION]: 'GEO_LOCATION',
    [LayerId.BES_NATURE]: 'BES_NATURE',
    [LayerId.BES_WATERCOURSES]: 'BES_WATERCOURSES',
    [LayerId.BES_STONES]: 'BES_STONES',
    [LayerId.BUFFER_MARGINS]: 'BUFFER_MARGINS',
    [LayerId.GLM_MEMORIES]: 'GLM_MEMORIES',
    [LayerId.GLM_LAKES]: 'GLM_LAKES',
    [LayerId.FIELD_SHRUBS]: 'FIELD_SHRUBS',
    [LayerId.BNBO_STATUS]: 'BNBO_STATUS',
    [LayerId.DIGI_SOIL]: 'DIGI_SOIL',
    [LayerId.FIELDS]: 'FIELDS',
    [LayerId.FIELD_LABELS]: 'FIELD_LABELS',
    [LayerId.FIELD_FILL]: 'FIELD_FILL',
    [LayerId.FIELD_MARKERS]: 'FIELD_MARKERS',
    [LayerId.HOTSPOTS]: 'HOTSPOTS',
    [LayerId.FIELD_BLOCKS]: 'FIELD_BLOCKS',
    [LayerId.HOTSPOT_MARKERS]: 'HOTSPOT_MARKERS',
    [LayerId.BIOMASS]: 'BIOMASS',
    [LayerId.VRA_CELLS]: 'VRA_CELLS',
    [LayerId.BASIS_LAYERS]: 'BASIS_LAYERS',
    [LayerId.HEIGHT_MAP]: 'HEIGHT_MAP',
    [LayerId.SHADOW_MAP]: 'SHADOW_MAP',
    [LayerId.SOILSAMPLE]: 'SOILSAMPLE',
    [LayerId.BLIGHT_FIELDS]: 'BLIGHT_FIELDS',
    [LayerId.BLIGHT_POLYGONS]: 'BLIGHT_POLYGONS',
    [LayerId.BLIGHT_FIELD_POLYGONS]: 'BLIGHT_FIELD_POLYGONS',
    [LayerId.VRA_PROGNOSIS]: 'VRA_PROGNOSIS',
    [LayerId.YIELD_PROGNOSIS]: 'YIELD_PROGNOSIS',
    [LayerId.CORN_PROGNOSIS]: 'CORN_PROGNOSIS',
    [LayerId.GROWTH_REGULATION]: 'GROWTH_REGULATION',
    [LayerId.ISOXML_POINTS]: 'ISOXML_POINTS',
    [LayerId.ISOXML_POINTS_NODATA]: 'ISOXML_POINTS_NODATA',
    [LayerId.ISOXML]: 'ISOXML',
    [LayerId.AS_APPLIED_POINTS]: 'AS_APPLIED_POINTS',
    [LayerId.FIELD_PLANS]: 'FIELD_PLANS',
    [LayerId.CELL_HOVER]: 'CELL_HOVER',
    [LayerId.CELL_DRAW]: 'CELL_DRAW',
    [LayerId.SOILSAMPLE_LABELS]: 'SOILSAMPLE_LABELS',
    [LayerId.HIGH_RES_FIELD_MARKERS]: 'HIGH_RES_FIELD_MARKERS',
    [LayerId.HIGH_RES_POTATO_MARKERS]: 'HIGH_RES_POTATO_MARKERS',
    [LayerId.POTATO_BLIGHT]: 'POTATO_BLIGHT',
    [LayerId.AS_APPLIED]: 'AS_APPLIED',
    [LayerId.DRONE_IMAGE_IMPORT]: 'DRONE_IMAGE_IMPORT',
    [LayerId.CLAY]: 'CLAY',
    [LayerId.HUMUS]: 'HUMUS',
    [LayerId.DEXTER]: 'DEXTER',
    [LayerId.GEO_SOIL]: 'GEO_SOIL',
  };

  static getDefaultLayerLabelFromId(layerId: LayerId): string {
    const translationLabel = 'main.map.layers.';
    return translationLabel + (this.layerIdToLabelMap[layerId] || '');
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { SoilSampleGroup } from '@app/core/repositories/soil-samples/soil-sample-group.class';
import { SampleResult, SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { SoilSamplesRepoService } from '@app/core/repositories/soil-samples/soil-samples-repo.service';
import { SoilSampleShortName } from '@app/new-map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import { SoilSampleParameterIds } from '@app/new-map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-samples-analysis-parameter-ids.enum';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { BehaviorSubject, filter, map, switchMap, tap, withLatestFrom } from 'rxjs';

export interface Measurement {
  parameterId: SoilSampleParameterIds;
  value: number;
}
export interface Measurements {
  reaction: Measurement;
  potassium: Measurement;
  magnesium: Measurement;
  phosphor: Measurement;
  boron: Measurement;
  copper: Measurement;
  humus: Measurement;
  clayPct: Measurement;
  texture: Measurement;
  nitrogen: Measurement;
  carbon: Measurement;
  orgCarbon: Measurement;
}
@Injectable({
  providedIn: 'root',
})
export class MapControlSoilsampleService implements OnDestroy {
  constructor(
    private farmService: FarmStateService,
    private soilSamplesRepo: SoilSamplesRepoService
  ) {}

  private _soilSampleGroupSubject_ = new BehaviorSubject<SoilSampleGroup[] | undefined>([]);

  private _selectedSoilSampleGroupSubject_ = new BehaviorSubject<SoilSampleGroup[] | undefined>([]);

  private _soilSamplesSubject_ = new BehaviorSubject<SoilSampleDTO[] | undefined>([]);

  private _selectedSoilsampleTypeSubject_ = new BehaviorSubject<SoilSampleParameterIds | undefined>(undefined);

  private _subs = new SubscriptionArray();

  public measurementNumbersSubject_ = new BehaviorSubject<Measurements>(this.createEmptyMeasurements());

  public soilSampleGroups$ = this._soilSampleGroupSubject_.asObservable().pipe(
    // sort dates
    map((groups) => groups?.sort((a, b) => b.date.toMillis() - a.date.toMillis()))
  );
  public selectedSoilSampleGroups$ = this._selectedSoilSampleGroupSubject_.asObservable();
  public soilSamples$ = this._soilSamplesSubject_.asObservable();
  public measurementNumbers$ = this.measurementNumbersSubject_.asObservable();
  public selectedSoilsampleType$ = this._selectedSoilsampleTypeSubject_.asObservable();

  public selectableMeasurementType$ = this.measurementNumbers$.pipe(
    map((measurements) => {
      const result = Object.entries(measurements).map(([key, value]) => ({
        name: key,
        count: value.value,
        enabled: value.value > 0,
        parameterId: value.parameterId,
      }));

      return result;
    })
  );

  public hasSelectedSoilSamples$ = this.selectedSoilSampleGroups$.pipe(
    filterNullOrEmpty(),
    map((selectedGroups) => selectedGroups?.length > 0),
    filterNullish()
  );

  private _resetFormSubject = new BehaviorSubject<boolean>(false);
  public resetForm$ = this._resetFormSubject.asObservable();

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public setSelectedType(type: SoilSampleParameterIds | SoilSampleShortName | undefined) {
    if (typeof type === 'string') {
      type = this.getParameterId(type as SoilSampleShortName);
    }

    this._selectedSoilsampleTypeSubject_.next(type);
  }

  public setSelectedSampleGroups(groups: SoilSampleGroup[] | undefined) {
    this._selectedSoilSampleGroupSubject_.next(groups);
  }

  public getSoilSampleGroups() {
    this._subs.add(
      this.farmService.selectedFarmIds$
        .pipe(
          switchMap((farmIds) => this.soilSamplesRepo.getSoilSampleGroups(farmIds)),
          tap((soilSampleGroup) => {
            this._soilSampleGroupSubject_.next(soilSampleGroup);
          })
        )
        .subscribe()
    );
  }

  public getSoilSamples() {
    this._subs.add(
      this.farmService.selectedFarmIds$
        .pipe(
          withLatestFrom(
            this.selectedSoilSampleGroups$.pipe(map((soilSampleGroups) => soilSampleGroups!.map((soilSampleGroup) => soilSampleGroup.date)))
          ),
          tap(([_, dates]) => {
            if (dates.length <= 0) {
              this._soilSamplesSubject_.next([]);
              this.measurementNumbersSubject_.next(this.createEmptyMeasurements());
            }
          }),
          filter(([farmIds, dates]) => dates.length > 0 && farmIds.length > 0),
          switchMap(([farmIds, dates]) => this.soilSamplesRepo.getSoilSamples(farmIds, dates)),
          filterNullish(),
          tap((SoilSampleDTOs) => {
            this._soilSamplesSubject_.next(SoilSampleDTOs);
            const measurementNumbers = this.getNumberOfMeasurements(SoilSampleDTOs);
            this.measurementNumbersSubject_.next(measurementNumbers);
          })
        )
        .subscribe()
    );
  }

  public getNumberOfMeasurements(soilSamples: SoilSampleDTO[]): Measurements {
    const allResults: SampleResult[] = soilSamples.reduce(
      (previous: SampleResult[], current) => [...previous, ...current.sampleResults],
      []
    );
    return {
      reaction: this.filterResultsById(allResults, SoilSampleParameterIds.ReactionNumber),
      potassium: this.filterResultsById(allResults, SoilSampleParameterIds.PotassiumNumber),
      magnesium: this.filterResultsById(allResults, SoilSampleParameterIds.MagnesiumNumber),
      phosphor: this.filterResultsById(allResults, SoilSampleParameterIds.PhosphorNumber),
      clayPct: this.filterResultsById(allResults, SoilSampleParameterIds.ClayPercentage),
      humus: this.filterResultsById(allResults, SoilSampleParameterIds.Humus),
      copper: this.filterResultsById(allResults, SoilSampleParameterIds.CopperNumber),
      boron: this.filterResultsById(allResults, SoilSampleParameterIds.BoronNumber),
      nitrogen: this.filterResultsById(allResults, SoilSampleParameterIds.NitrogenPercentage),
      carbon: this.filterResultsById(allResults, SoilSampleParameterIds.CarbonPercentage),
      orgCarbon: this.filterResultsById(allResults, SoilSampleParameterIds.OrganicCarbon),
      texture: { parameterId: SoilSampleParameterIds.Texture, value: soilSamples.length },
    } as Measurements;
  }

  public resetForm() {
    this._resetFormSubject.next(true);
  }

  public reset() {
    this._selectedSoilSampleGroupSubject_.next([]);
    this._soilSamplesSubject_.next([]);
    this._selectedSoilsampleTypeSubject_.next(undefined);
    this.measurementNumbersSubject_.next(this.createEmptyMeasurements());
    this.resetForm();
  }

  private filterResultsById(allResults: SampleResult[], id: SoilSampleParameterIds) {
    return { parameterId: id, value: allResults.filter((result) => result.parameterId === id).length } as Measurement;
  }

  private createEmptyMeasurements() {
    return {
      reaction: { parameterId: SoilSampleParameterIds.ReactionNumber, value: 0 },
      potassium: { parameterId: SoilSampleParameterIds.PotassiumNumber, value: 0 },
      magnesium: { parameterId: SoilSampleParameterIds.MagnesiumNumber, value: 0 },
      phosphor: { parameterId: SoilSampleParameterIds.PhosphorNumber, value: 0 },
      boron: { parameterId: SoilSampleParameterIds.BoronNumber, value: 0 },
      copper: { parameterId: SoilSampleParameterIds.CopperNumber, value: 0 },
      humus: { parameterId: SoilSampleParameterIds.Humus, value: 0 },
      clayPct: { parameterId: SoilSampleParameterIds.ClayPercentage, value: 0 },
      texture: { parameterId: SoilSampleParameterIds.Texture, value: 0 },
      nitrogen: { parameterId: SoilSampleParameterIds.NitrogenPercentage, value: 0 },
      carbon: { parameterId: SoilSampleParameterIds.CarbonPercentage, value: 0 },
      orgCarbon: { parameterId: SoilSampleParameterIds.OrganicCarbon, value: 0 },
    } as Measurements;
  }

  getShortHandType(type: SoilSampleParameterIds) {
    switch (type) {
      case SoilSampleParameterIds.ReactionNumber:
        return SoilSampleShortName.Reaction;
      case SoilSampleParameterIds.PotassiumNumber:
        return SoilSampleShortName.Potassium;
      case SoilSampleParameterIds.MagnesiumNumber:
        return SoilSampleShortName.Magnesium;
      case SoilSampleParameterIds.PhosphorNumber:
        return SoilSampleShortName.Phosphor;
      case SoilSampleParameterIds.BoronNumber:
        return SoilSampleShortName.Boron;
      case SoilSampleParameterIds.CopperNumber:
        return SoilSampleShortName.Copper;
      case SoilSampleParameterIds.Humus:
        return SoilSampleShortName.Humus;
      case SoilSampleParameterIds.ClayPercentage:
        return SoilSampleShortName.ClayPercentage;
      case SoilSampleParameterIds.Texture:
        return SoilSampleShortName.SoilType;
      case SoilSampleParameterIds.NitrogenPercentage:
        return SoilSampleShortName.NitrogenPercentage;
      case SoilSampleParameterIds.CarbonPercentage:
        return SoilSampleShortName.CarbonPercentage;
      case SoilSampleParameterIds.OrganicCarbon:
        return SoilSampleShortName.OrganicCarbon;
      default:
        return undefined;
    }
  }

  getParameterId(shortName: SoilSampleShortName) {
    switch (shortName) {
      case SoilSampleShortName.Reaction:
        return SoilSampleParameterIds.ReactionNumber;
      case SoilSampleShortName.Potassium:
        return SoilSampleParameterIds.PotassiumNumber;
      case SoilSampleShortName.Magnesium:
        return SoilSampleParameterIds.MagnesiumNumber;
      case SoilSampleShortName.Phosphor:
        return SoilSampleParameterIds.PhosphorNumber;
      case SoilSampleShortName.Boron:
        return SoilSampleParameterIds.BoronNumber;
      case SoilSampleShortName.Copper:
        return SoilSampleParameterIds.CopperNumber;
      case SoilSampleShortName.Humus:
        return SoilSampleParameterIds.Humus;
      case SoilSampleShortName.ClayPercentage:
        return SoilSampleParameterIds.ClayPercentage;
      case SoilSampleShortName.SoilType:
        return SoilSampleParameterIds.Texture;
      case SoilSampleShortName.NitrogenPercentage:
        return SoilSampleParameterIds.NitrogenPercentage;
      case SoilSampleShortName.CarbonPercentage:
        return SoilSampleParameterIds.CarbonPercentage;
      case SoilSampleShortName.OrganicCarbon:
        return SoilSampleParameterIds.OrganicCarbon;
      default:
        return undefined;
    }
  }
}

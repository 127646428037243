import { NgModule } from '@angular/core';
import { AsAppliedMetaDataComponent } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/meta-data-component/as-applied-meta-data.component';
import { AsAppliedFilePickerModule } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/file-picker/as-applied-file-picker.module';
import { ScaleLegendModule } from '@app/shared/scale-legend/scale-legend.module';
import { SharedModule } from '@app/shared/shared.module';
import { AsAppliedTooltipModule } from '../as-applied-tooltip/as-applied-tooltip.module';
import { AsAppliedFileDetailsComponent } from './as-applied-meta-data/as-applied-file-details/as-applied-file-details.component';
import { MetaDataChildFormComponent } from './as-applied-meta-data/meta-data-child-form/meta-data-child-form.component';

@NgModule({
  declarations: [AsAppliedMetaDataComponent, MetaDataChildFormComponent, AsAppliedFileDetailsComponent],
  exports: [AsAppliedMetaDataComponent, AsAppliedFilePickerModule, AsAppliedFileDetailsComponent],
  imports: [SharedModule, ScaleLegendModule, AsAppliedFilePickerModule, AsAppliedTooltipModule],
})
export class AsAppliedUploadModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { HttpClient } from '@app/core/http/http-client';
import { HttpErrorHelper } from '@app/core/http/http-error-helper';
import { Unit } from '@app/core/interfaces/unit.type';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { generateRandomId } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { trackRequestResult } from '@ngneat/elf-requests';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  UnaryFunction,
  catchError,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  iif,
  map,
  of,
  pipe,
  shareReplay,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { BasisLayerCategory } from '../../interfaces/basis-layer-category.interface';
import { CustomLevel } from '../../interfaces/custom-level.interface';
import {
  PrescriptionMap,
  PrescriptionMapCell,
  PrescriptionMapContainerDto,
  SatelliteImageSource,
} from '../../interfaces/prescription-map.interface';
import { VraAllocationTypes } from '../../interfaces/vra-allocation-types.enum';
import { VraCropNormNumbers } from '../../interfaces/vra-crop-norm-numbers.interface';
import { VraErrorCodes } from '../../interfaces/vra-error-codes.enum';
import { LimeSetting, LimeTypeNeed } from '../../interfaces/vra-lime-setting.interface';
import { VraTask } from '../../interfaces/vra-task.interface';
import { ApiParamsService } from '../_api-params.service';
import { AdjustAmountRepository } from '../adjust-amount-repository';
import { BasisLayerRepository } from '../basis-layer.repository';
import { CellRepository } from '../cell.repository';
import { MinMax, MinMaxRepository } from '../min-max.repository';
import { SoilSampleAdjustment, SoilSampleAdjustmentsRepository } from '../soil-sample-adjustments.repository';
import { SoilSampleCorrection, SoilSampleCorrectionsRepository } from '../soil-sample-corrections.repository';
import { VraRepository } from '../vra.repository';
import {
  CalculatePrescriptionMapBody,
  CreatePrescriptionMapBody,
  OperationLinePatch,
  PrescriptionMapRequest,
  SaveLimePrescriptionMapBody,
  UpdatePrescriptionMapBody,
  createDeletedPrescriptionMapRequestBody,
  createLimeSaveRequestBody,
  createOperationLinesPatchRequestBody,
  createPrescriptionMapCalculateBody,
  createPrescriptionMapRequestBody,
  createPrescriptionMapsCalculateBody,
  createSaveRequestBody,
  createTemporaryPotassiumPrescriptionMapsRequestBody,
} from './prescription-map-dtos';
import { PrescriptionMapQuery } from './prescription-map.query';
import { PrescriptionMapStore } from './prescription-map.store';

type SaveStrategy = 'set' | 'upsert';

@Injectable({ providedIn: 'root' })
export class PrescriptionMapService implements OnDestroy {
  constructor(
    private store: PrescriptionMapStore,
    private query: PrescriptionMapQuery,
    private http: HttpClient,
    private apiParams: ApiParamsService,
    private endpoints: EndpointsService,
    private vraRepo: VraRepository,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private farmStateService: FarmStateService,
    private bLayerRepo: BasisLayerRepository,
    private cellRepo: CellRepository,
    private adjustAmountRepo: AdjustAmountRepository,
    private minMaxRepo: MinMaxRepository,
    private soilSampleAdjustmentsRepo: SoilSampleAdjustmentsRepository,
    private soilSampleCorrectionsRepo: SoilSampleCorrectionsRepository,
    private accessControlService: AccessControlService
  ) {}

  private readonly _store = this.store.store;
  private readonly _history = this.store.history;

  private readonly _apiUrl = this.endpoints.bffApi;
  private readonly _foApiUrl = this.endpoints.foApi;

  private readonly _activeTask$ = this.vraRepo.activeTask$;

  private readonly _applyingTemplatesSubject = new BehaviorSubject(false);
  public readonly applyingTemplates$ = this._applyingTemplatesSubject.asObservable();

  private readonly refetch$ = new BehaviorSubject<void>(undefined);

  private _showTaskFirstOpenErrors = true;

  private _showTaskFirstOpenErrorsSideEffect = this._activeTask$
    .pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      tap(() => (this._showTaskFirstOpenErrors = true))
    )
    .subscribe();

  private readonly _init$ = this.refetch$.pipe(
    switchMap(() => this._activeTask$),
    filterNullish(),
    filter((task) => task.errorCode === null || task.errorCode === VraErrorCodes.NoError),
    // if prescription maps are present, get them
    // else create them
    switchMap((task) => iif(() => task.fields.every((x) => x.vraPrescriptionMapId), this.getByIds(task), this.create(task))),
    shareReplay(latest)
  );

  private readonly _ongoingRequests = new Map<PrescriptionMap['id'] | 'lime' | 'all', Subscription>();

  private _ongoingConcurrentRequest = new BehaviorSubject<number[]>([]);

  public readonly anyOnGoingRequestsSideEffect$ = combineLatest([this._activeTask$, this._ongoingConcurrentRequest])
    .pipe(
      takeUntilDestroyed(),
      map(([task, ongoingRequest]) => !!task && !!ongoingRequest.length),
      tap((isOngoing) => this.query.anyOngoingRequests$.next(isOngoing))
    )
    .subscribe();

  public readonly initialized$ = new BehaviorSubject<boolean>(false);
  public templateDateChangeFlag = false;
  public templateConflictChangeFlag = false;

  public readonly shouldRefetchAfterPatch$ = combineLatest([this._activeTask$, this.vraRepo.refetchException$]).pipe(
    map(([task, refetchException]) => !task && refetchException)
  );

  public ngOnDestroy() {
    this._showTaskFirstOpenErrorsSideEffect.unsubscribe();
  }

  public init() {
    this.resetHistory();
    return this._init$;
  }

  public resetHistory() {
    this._history.clear([], (history) => ({ ...history, past: [], future: [] }));

    // reset the history of the other repositories as well
    this.bLayerRepo.clearHistory();
    this.cellRepo.clearHistory();
    this.adjustAmountRepo.clearHistory();
    this.minMaxRepo.clearHistory();
  }

  public clearHistory() {
    this._history.jumpToPast(0);
    this._history.clear();

    this._store.update((state) => ({
      ...state,
      limeTypeNeed: LimeTypeNeed.None,
      cutoff: 0,
    }));
  }

  public refetch() {
    this.resetHistory();
    this.clearHistory();
    this.refetch$.next();
  }

  /**
   * Get existing prescription maps from the API
   */
  public getByIds(task: VraTask) {
    const prescriptionMapIds = task.fields
      .map((x) => x.vraPrescriptionMapId)
      .filter(Boolean)
      .join(',');

    return this.apiParams.farmAndHarvestYear$.pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.http
          .get<PrescriptionMapContainerDto>(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps/${prescriptionMapIds}`)
          .pipe(
            // Need an intermediate request track, as there may be a chained request from handlePostFetchWarnings -> createDeleted
            trackRequestResult(['prescription-maps', farmIds, harvestYear], { skipCache: true }),
            switchMap((response) => this.handlePostFetchWarnings(response)),
            tap((response) => (this._showTaskFirstOpenErrors = false)),
            this.setStore({ key: ['prescription-maps', farmIds, harvestYear] }),
            this.catchError()
          )
      )
    );
  }

  /**
   * Create a new, temporary prescription map from the API and save it to the store
   */
  public create(task: VraTask, skipPotassiumCheck?: boolean) {
    if (this.isPotassiumTask(task) && !skipPotassiumCheck) {
      return this.handlePotassiumTask(task);
    }

    const body = createPrescriptionMapRequestBody(task);

    return this.apiParams.farmAndHarvestYear$.pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.http
          .post<
            PrescriptionMapContainerDto,
            CreatePrescriptionMapBody
          >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps`, body)
          .pipe(
            switchMap((response) => this.handlePostFetchWarnings(response)),
            tap((response) => (this._showTaskFirstOpenErrors = false)),
            this.setStore({ key: ['prescription-maps', farmIds, harvestYear] }),
            this.catchError(),
            tap(() => this.resetHistory())
          )
      )
    );
  }

  public createAfterPotassiumFail(task: VraTask) {
    const body = createPrescriptionMapRequestBody(task);

    return this.apiParams.farmAndHarvestYear$.pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.http
          .post<
            PrescriptionMapContainerDto,
            CreatePrescriptionMapBody
          >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps`, body)
          .pipe(
            // Need an intermediate request track, as there may be a chained request from handlePostFetchWarnings -> createDeleted
            trackRequestResult(['prescription-maps', farmIds, harvestYear], { skipCache: true })
          )
      )
    );
  }

  public getByIdsAfterPotassiumFail(task: VraTask) {
    const prescriptionMapIds = task.fields
      .map((x) => x.vraPrescriptionMapId)
      .filter(Boolean)
      .join(',');

    return this.apiParams.farmAndHarvestYear$.pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.http
          .get<PrescriptionMapContainerDto>(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps/${prescriptionMapIds}`)
          .pipe(
            // Need an intermediate request track, as there may be a chained request from handlePostFetchWarnings -> createDeleted
            trackRequestResult(['prescription-maps', farmIds, harvestYear], { skipCache: true })
          )
      )
    );
  }

  /**
   * Create a new, temporary prescription map from the API and save it to the store for deleted maps
   */
  public createDeleted(pMapContainer: PrescriptionMapContainerDto) {
    const deletedFields = pMapContainer.deletedPrescriptionMaps;
    this.vraRepo.refetchException$.next(true);

    return this._activeTask$.pipe(
      filterNullish(),
      filter((task) => task.fields.some((field) => deletedFields.includes(field.featureId))),
      first(),
      switchMap((task) => {
        const body = createDeletedPrescriptionMapRequestBody(task, deletedFields);

        return this.apiParams.farmAndHarvestYear$.pipe(
          switchMap(([farmIds, harvestYear]) =>
            this.http
              .post<
                PrescriptionMapContainerDto,
                CreatePrescriptionMapBody
              >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps`, body)
              .pipe(
                map((response) => {
                  if (response?.vraPrescriptionMaps) {
                    // additively update the prescription map container
                    pMapContainer.area += response.area;
                    pMapContainer.totalProductQuantity += response.totalProductQuantity;
                    pMapContainer.totalQuantity += response.totalQuantity;
                    if (pMapContainer.avgQuantity === 0) {
                      pMapContainer.avgQuantity = response.avgQuantity;
                    }
                    // replace props if none exist
                    pMapContainer.limeNeed ?? response.limeNeed;
                    pMapContainer.soilSampleCorrections ?? response.soilSampleCorrections;
                    pMapContainer.info = response.info;
                    // additively update the lists
                    pMapContainer.vraPrescriptionMaps.push(...response.vraPrescriptionMaps);
                    pMapContainer.soilSampleGroups.push(...response.soilSampleGroups);
                  }

                  return pMapContainer;
                })
              )
          )
        );
      })
    );
  }

  /**
   * Re-calculate a prescription map from the API
   */
  public calculate(updatedMap: Partial<PrescriptionMap> & Pick<PrescriptionMap, 'id'>) {
    // cancel previous requests
    this._ongoingRequests.get(updatedMap.id)?.unsubscribe();
    this.addIdToConcurrentRequests(updatedMap.id);

    const sub = this.query.prescriptionMapsByActiveTask$
      .pipe(
        map((maps) => maps.find((map) => map.id === updatedMap.id)),
        filterNullish(),
        first(),
        map((map) => ({ ...map, ...updatedMap })),
        withLatestFrom(
          this.bLayerRepo.categories$,
          this.cellRepo.cells$,
          this.cellRepo.customLevels$,
          this.adjustAmountRepo.adjustPlannedAmount$,
          this.minMaxRepo.minMax$,
          this.query.limeSetting$,
          this.soilSampleAdjustmentsRepo.adjustments$,
          this.soilSampleCorrectionsRepo.soilSampleCorrection$,
          this.apiParams.farmAndHarvestYear$
        ),
        switchMap(([map, categories, cells, levels, adjust, minMax, limeSetting, soilSampleAdjustments, pNeed, [farmIds, harvestYear]]) =>
          this.calculatePrescriptionMap(
            map,
            categories,
            cells,
            levels,
            adjust,
            minMax,
            limeSetting,
            soilSampleAdjustments,
            pNeed,
            farmIds,
            harvestYear
          )
        ),
        finalize(() => {
          this.removeIdFromConcurrentRequests(updatedMap.id);
        })
      )
      .subscribe();

    this._ongoingRequests.set(updatedMap.id, sub);
  }

  public calculateAll() {
    // set pre-loading due to the delay
    this._store.update((state) => ({ ...state, preLoading: true }));
    // set post-loading after 1s
    setTimeout(() => this._store.update((state) => ({ ...state, preLoading: false })), 1500);

    // cancel previous requests
    this._ongoingRequests.get('all')?.unsubscribe();

    const sub = this.query.prescriptionMapsByActiveTask$
      .pipe(
        first(),
        delay(1000), // <--- Delay to make sure stores are updated before calculation
        withLatestFrom(
          this.bLayerRepo.categories$,
          this.cellRepo.cells$,
          this.cellRepo.customLevels$,
          this.adjustAmountRepo.adjustPlannedAmount$,
          this.minMaxRepo.minMax$,
          this.query.limeSetting$,
          this.soilSampleAdjustmentsRepo.adjustments$,
          this.soilSampleCorrectionsRepo.soilSampleCorrection$,
          this.apiParams.farmAndHarvestYear$
        ),
        switchMap(([maps, categories, cells, levels, adjust, minMax, limeSetting, soilSampleAdjustments, pNeed, [farmIds, harvestYear]]) =>
          this.calculatePrescriptionMaps(
            maps,
            categories,
            cells,
            levels,
            adjust,
            minMax,
            limeSetting,
            soilSampleAdjustments,
            pNeed,
            farmIds,
            harvestYear
          )
        )
      )
      .subscribe();

    this._ongoingRequests.set('all', sub);
  }

  private ongoingTemplatesCounter = 0;

  /**
   * Calculate a prescription map from templates
   */
  public calculateFromTemplate(
    updatedMap: Partial<PrescriptionMap> & Pick<PrescriptionMap, 'id'>,
    basisLayers: BasisLayerCategory[],
    minMaxes: MinMax[]
  ) {
    // cancel previous requests
    this._ongoingRequests.get(updatedMap.id)?.unsubscribe();
    this.addIdToConcurrentRequests(updatedMap.id);

    // Increment the ongoing requests counter and set applyingTemplates to true if needed
    this.ongoingTemplatesCounter++;
    if (this.ongoingTemplatesCounter === 1) {
      this._applyingTemplatesSubject.next(true);
    }

    const sub = this.query.prescriptionMapsByActiveTask$
      .pipe(
        map((maps) => maps.find((map) => map.id === updatedMap.id)),
        filterNullish(),
        first(),
        map((map) => ({ ...map, ...updatedMap })),
        withLatestFrom(
          this.query.limeSetting$,
          this.soilSampleAdjustmentsRepo.adjustments$,
          this.soilSampleCorrectionsRepo.soilSampleCorrection$,
          this.apiParams.farmAndHarvestYear$
        ),
        switchMap(([map, limeSetting, soilSampleAdjustments, pNeed, [farmIds, harvestYear]]) =>
          this.calculatePrescriptionMap(
            map,
            basisLayers,
            map.cells,
            map.customLevels,
            map.adjustPlannedAmount,
            minMaxes,
            limeSetting,
            soilSampleAdjustments,
            pNeed,
            farmIds,
            harvestYear
          )
        ),
        finalize(() => {
          this.removeIdFromConcurrentRequests(updatedMap.id);
          this.changeSatelliteImageSource();
          this.changeSatelliteImageSource();
          // Decrement the ongoing requests counter and set applyingTemplates to false if needed
          this.ongoingTemplatesCounter--;
          if (this.ongoingTemplatesCounter === 0) {
            this._applyingTemplatesSubject.next(false);
            if (this.templateDateChangeFlag) {
              this.setSatelliteImageSource(SatelliteImageSource.Sentinel);
            }
            if (this.templateDateChangeFlag || this.templateConflictChangeFlag) {
              this.notificationService.showWarning(
                this.languageService.getText('vra.settings.notification.date-and-basisLayer-change'),
                10000
              );
            }
            this.templateDateChangeFlag = false;
            this.templateConflictChangeFlag = false;
          }
        })
      )
      .subscribe();

    this._ongoingRequests.set(updatedMap.id, sub);
  }

  /**
   * Re-calculate all prescription maps from the API
   */
  public calculateLime(limeTypeNeed: LimeTypeNeed, cutoff: number) {
    const limeSetting: LimeSetting = { cutoff, limeTypeNeed };

    // cancel previous requests
    this._ongoingRequests.get('lime')?.unsubscribe();

    const sub = this.query.prescriptionMapsByActiveTask$
      .pipe(
        filterNullOrEmpty(),
        first(),
        withLatestFrom(
          this.bLayerRepo.categories$,
          this.cellRepo.cells$,
          this.cellRepo.customLevels$,
          this.adjustAmountRepo.adjustPlannedAmount$,
          this.minMaxRepo.minMax$,
          this.soilSampleAdjustmentsRepo.adjustments$,
          this.soilSampleCorrectionsRepo.soilSampleCorrection$,
          this.apiParams.farmAndHarvestYear$
        ),
        switchMap(([maps, categories, cells, levels, adjust, minMax, soilSampleAdjustments, pNeed, [farmIds, harvestYear]]) =>
          this.calculatePrescriptionMaps(
            maps,
            categories,
            cells,
            levels,
            adjust,
            minMax,
            limeSetting,
            soilSampleAdjustments,
            pNeed,
            farmIds,
            harvestYear
          )
        )
      )
      .subscribe();

    this._ongoingRequests.set('lime', sub);
  }

  /**
   * Patch operation lines of the VRA Task based on the prescription maps
   */
  public patchOperationLines() {
    return combineLatest([this.vraRepo.activeTask$.pipe(filterNullish()), this.query.prescriptionMapsByActiveTask$]).pipe(
      filterNullOrEmpty(),
      first(),
      withLatestFrom(this.apiParams.farmAndHarvestYear$),
      switchMap(([[task, prescriptionMaps], [farmIds, harvestYear]]) => {
        const operationLineIds = task?.fields.flatMap((field) => field.operationLines.map((line) => line.id));
        const uniqueOperationLineIds = [...new Set(operationLineIds)];
        const operationLineIdsString = uniqueOperationLineIds.join(',');

        const patchBody = createOperationLinesPatchRequestBody(task, prescriptionMaps);

        return this.http.patch<Omit<OperationLinePatch, 'vraPrescriptionMapId'>[], OperationLinePatch[]>(
          `${this._foApiUrl}/farms/${farmIds}/${harvestYear}/operationlines/${operationLineIdsString}`,
          patchBody
        );
      })
    );
  }

  public setStateSaved() {
    this.query.prescriptionMapsByActiveTask$
      .pipe(
        first(),
        tap((maps) => {
          maps.forEach((map) => {
            map.state = 'saved';

            this._store.update(updateEntities(map.id, map));
          });
        })
      )
      .subscribe();
  }

  /**
   * Save prescription maps to the API
   * Check if lime, as we need to save differently.
   */
  public save() {
    return this.query.prescriptionMapsByActiveTask$
      .pipe(
        filterNullOrEmpty(),
        first(),
        map((maps) => ({ maps, ...this.getPreSaveWarnings(maps) })),
        tap(
          ({ canProceed, translationKey }) =>
            !canProceed && this.notificationService.showError(this.languageService.getText(translationKey))
        ),
        filter(({ canProceed }) => canProceed),
        withLatestFrom(this.apiParams.farmAndHarvestYear$),
        switchMap(([{ maps: prescriptionMaps }, [farmIds, harvestYear]]) =>
          this.isLimeOperation(prescriptionMaps)
            ? this.saveLimePrescriptionMaps(prescriptionMaps, farmIds, harvestYear)
            : this.savePrescriptionMaps(prescriptionMaps, farmIds, harvestYear)
        ),
        tap((pMaps) => {
          this.vraRepo.activeTask$
            .pipe(
              first(),

              tap((task) => {
                pMaps.forEach((map) => {
                  task?.fields.forEach((field) => {
                    if (field.featureId === map.featureId) {
                      field.vraPrescriptionMapId = map.id;
                    }
                  });
                });

                if (task) this.vraRepo.updateTask(task);
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  private savePrescriptionMaps(prescriptionMaps: PrescriptionMap[], farmIds: string, harvestYear: number): Observable<PrescriptionMap[]> {
    const { intervals } = this._store.getValue();

    const body = createSaveRequestBody(prescriptionMaps, intervals);

    return this.http
      .put<
        PrescriptionMapContainerDto,
        UpdatePrescriptionMapBody
      >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps`, body as UpdatePrescriptionMapBody)
      .pipe(
        this.setStore({ key: ['prescription-maps', farmIds, harvestYear], strategy: 'set' }),
        this.catchError(),
        tap(() => this.bLayerRepo.save()),
        tap(() => {
          this.setStateSaved();
          this.resetHistory();
        })
      );
  }

  private saveLimePrescriptionMaps(
    prescriptionMaps: PrescriptionMap[],
    farmIds: string,
    harvestYear: number
  ): Observable<PrescriptionMap[]> {
    const { cutoff, limeTypeNeed, unit } = this._store.getValue();

    const body: SaveLimePrescriptionMapBody = createLimeSaveRequestBody(prescriptionMaps, cutoff, limeTypeNeed, unit);

    return this.http
      .post<
        PrescriptionMapContainerDto,
        SaveLimePrescriptionMapBody
      >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps/lime/save`, body)
      .pipe(
        this.setStore({ key: ['prescription-maps', farmIds, harvestYear], strategy: 'set' }),
        this.catchError(),
        tap(() => this.bLayerRepo.save()),
        tap(() => {
          this.setStateSaved();
          this.resetHistory();
        })
      );
  }

  private calculatePrescriptionMap(
    prescriptionMap: PrescriptionMap,
    categories: BasisLayerCategory[],
    cells: PrescriptionMapCell[],
    levels: CustomLevel[],
    adjust: boolean,
    minMax: MinMax[],
    limeSetting: LimeSetting | undefined,
    soilSampleAdjustments: SoilSampleAdjustment[],
    pNeed: SoilSampleCorrection[],
    farmIds: string,
    harvestYear: number
  ): Observable<PrescriptionMap[]> {
    const body = createPrescriptionMapCalculateBody(
      prescriptionMap,
      categories,
      cells,
      adjust,
      minMax,
      levels,
      limeSetting,
      soilSampleAdjustments,
      pNeed
    );
    const ids = [prescriptionMap.id];

    return this.http
      .post<
        PrescriptionMapContainerDto,
        CalculatePrescriptionMapBody
      >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmapswithbasislayers`, body)
      .pipe(
        this.setStore({ key: ['prescription-maps', farmIds, harvestYear], ids, strategy: 'upsert' }),
        tap(() => this.resetHistory())
      );
  }

  private calculatePrescriptionMaps(
    prescriptionMaps: PrescriptionMap[],
    categories: BasisLayerCategory[],
    cells: PrescriptionMapCell[],
    levels: CustomLevel[],
    adjust: boolean,
    minMax: MinMax[],
    limeSetting: LimeSetting | undefined,
    soilSampleAdjustments: SoilSampleAdjustment[],
    pNeed: SoilSampleCorrection[],
    farmIds: string,
    harvestYear: number
  ): Observable<PrescriptionMap[]> {
    const body = createPrescriptionMapsCalculateBody(
      prescriptionMaps,
      categories,
      cells,
      adjust,
      minMax,
      levels,
      limeSetting,
      soilSampleAdjustments,
      pNeed
    );
    const ids = prescriptionMaps.flatMap((x) => x.id);

    switch (limeSetting?.limeTypeNeed) {
      case LimeTypeNeed.Type1Need:
        if (prescriptionMaps.some((map) => (map.minAmount ?? map.minAmountDefault ?? 0) > (limeSetting?.cutoff ?? 0))) {
          this.notificationService.showError(
            this.languageService.getText('vra.components.lime-need.validator.error.not-possible-need-1'),
            8000
          );
          // revert the lime need to allowed need
          this._store.update((state) => ({ ...state, limeTypeNeed: state.limeTypeNeed, cutoff: state.cutoff }));

          return of(prescriptionMaps);
        }
        break;
      case LimeTypeNeed.Type2Need:
        if (prescriptionMaps.some((map) => (map.maxAmount ?? map.maxAmountDefault ?? 0) < (limeSetting.cutoff ?? 0))) {
          this.notificationService.showError(
            this.languageService.getText('vra.components.lime-need.validator.error.not-possible-need-2'),
            8000
          );
          // revert the lime need to allowed need
          this._store.update((state) => ({ ...state, limeTypeNeed: state.limeTypeNeed }));

          return of(prescriptionMaps);
        }
        break;
      default:
        break;
    }

    return this.http
      .post<
        PrescriptionMapContainerDto,
        CalculatePrescriptionMapBody
      >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmapswithbasislayers`, body)
      .pipe(
        filterNullish(),
        this.setStore({ key: ['prescription-maps', farmIds, harvestYear], ids }),
        tap(() => this.resetHistory())
      );
  }

  /**
   * Examines provided prescription maps to identify potential issues.
   * Returns an object containing a boolean flag indicating whether the save operation should continue and a relevant translation key for a potential error message.
   */
  private getPreSaveWarnings(prescriptionMaps: PrescriptionMap[]): { canProceed: boolean; translationKey: string } {
    const operationTypeGroup = prescriptionMaps[0].operationTypeGroup;
    // If there are warnings in the prescription maps from calculation, return error translation key and canProceed false to prevent saving
    const hasWarning = prescriptionMaps.some((map) => (map.warnings?.length ?? 0) > 0);

    if (hasWarning && operationTypeGroup !== OperationTypeGroupEnum.Lime) {
      return { canProceed: false, translationKey: 'vra.snackbar.error.quantity-cannot-be-maintained' };
    }

    // If there are no planned quantities in the prescription maps, return error translation key and canProceed false to prevent saving
    if (operationTypeGroup === OperationTypeGroupEnum.Lime) {
      if (prescriptionMaps.every((map) => map.plannedQuantityField === 0)) {
        return { canProceed: false, translationKey: 'vra.snackbar.error.lime-need-zero' };
      }
    }

    return { canProceed: true, translationKey: '' };
  }

  /**
   * Examines fetched prescription maps to identify potential issues.
   * Displays a snackbar with relevant information if necessary.
   * The order of the checks is important, as the snackbar should only show the first relevant message.
   */
  private handlePostFetchWarnings(response: PrescriptionMapContainerDto | null) {
    if (!response) return EMPTY;

    switch (response.warning) {
      case 50032:
        if (this._showTaskFirstOpenErrors) {
          this.notificationService.showWarning(HttpErrorHelper.getErrorMessageFromCode(response.warning));
          return of(response);
        } else {
          break;
        }
      default:
        break;
    }

    // 1. If there are deleted prescription maps, show a snackbar
    if (response.deletedPrescriptionMaps.length) {
      return this.farmStateService.fields$.pipe(
        filterNullOrEmpty(),
        map((fields) => fields.filter((field) => response.deletedPrescriptionMaps.some((featureId) => featureId === field.featureId))),
        filterNullOrEmpty(),
        tap((deletedFields) => {
          const fieldNumbers = deletedFields.map((field) => field.number).join(', ');

          this.notificationService.showWarning(
            this.languageService.getText('vra.snackbar.error.deleted-maps', { fieldNumbers: fieldNumbers }),
            10000
          );
        }),
        switchMap(() => this.createDeleted(response))
      );
    }

    const noRedistribution = response.vraPrescriptionMaps.some((map) => map.allocationType === VraAllocationTypes.noRedistribution);
    const isLime = response.vraPrescriptionMaps[0].operationTypeGroup === OperationTypeGroupEnum.Lime;

    // 2. If there are no lime maps for a lime task, show a snackbar
    if (noRedistribution && isLime) {
      this._showTaskFirstOpenErrors &&
        this.notificationService.showWarning(this.languageService.getText('vra.snackbar.error.lime-no-map'), 8000);
      return of(response);
    }

    // 3. If the task is a protection task, show a generic snackbar
    if (response.vraPrescriptionMaps[0].operationTypeGroup === OperationTypeGroupEnum.PlantProtection) {
      this.notificationService.showInfo(this.languageService.getText('vra.snackbar.error.protection-generic'));
      return of(response);
    }

    return of(response);
  }

  private handlePotassiumTask(task: VraTask) {
    const body = createTemporaryPotassiumPrescriptionMapsRequestBody(task);

    return this.apiParams.farmAndHarvestYear$.pipe(
      switchMap(([farmIds, harvestYear]) =>
        this.http
          .post<
            PrescriptionMapContainerDto,
            Array<PrescriptionMapRequest>
          >(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/optimal/amount`, body)
          .pipe(
            catchError((error) => {
              if (task.fields.every((x) => x.vraPrescriptionMapId)) return this.getByIdsAfterPotassiumFail(task);
              return this.createAfterPotassiumFail(task);
            }),
            switchMap((response) => this.handlePostFetchWarnings(response)),
            tap((response) => (this._showTaskFirstOpenErrors = false)),
            this.setStore({ key: ['prescription-maps', farmIds, harvestYear] }),
            this.catchError(),
            tap(() => this.resetHistory())
          )
      )
    );
  }

  public update(prescriptionMap: Partial<PrescriptionMap> & Pick<PrescriptionMap, 'id'>) {
    this._store.update(updateEntities(prescriptionMap.id, prescriptionMap));
  }

  public updateAll(ids: PrescriptionMap['id'][], updatedValues: Partial<PrescriptionMap>) {
    this._store.update(updateEntities(ids, updatedValues));
  }

  public changeSatelliteImageSource() {
    this.query.satelliteImageSource$.pipe(first()).subscribe((source) => {
      this._store.update((state) => ({
        ...state,
        satelliteImageSource: source === SatelliteImageSource.Cloudless ? SatelliteImageSource.Sentinel : SatelliteImageSource.Cloudless,
      }));
    });
  }

  public setSatelliteImageSource(source: SatelliteImageSource) {
    this._store.update((state) => ({
      ...state,
      satelliteImageSource: source,
    }));
  }

  // maps HTTP response to store update with request tracking
  private setStore({
    key,
    ids,
    strategy = 'set',
  }: {
    key: unknown[];
    ids?: PrescriptionMap['id'][];
    strategy?: SaveStrategy;
  }): UnaryFunction<Observable<PrescriptionMapContainerDto | null>, Observable<PrescriptionMap[]>> {
    return pipe(
      filterNullish(),
      // TODO: Remove withLatestFrom when Naesgaard has access to cloudless
      withLatestFrom(this.accessControlService.hasAccessTo('naesgaard_specific_information')),
      tap(([{ info, avgQuantity, totalQuantity, limeNeed, soilSampleCorrections, vraPrescriptionMaps }, naesgaard]) =>
        this._store.update((state) => ({
          ...state,
          unit: (info.unitText as Unit) || state.unit,
          operationTypeGroup: vraPrescriptionMaps.first()?.operationTypeGroup,
          avg: avgQuantity,
          total: totalQuantity,
          cutoff: limeNeed?.cutoff,
          limeTypeNeed: limeNeed?.limeTypeNeed,
          intervals: soilSampleCorrections?.intervals,
          satelliteImageSource: naesgaard
            ? SatelliteImageSource.Sentinel
            : vraPrescriptionMaps.some(({ satelliteImageDate }) => satelliteImageDate == null)
              ? SatelliteImageSource.Sentinel
              : (vraPrescriptionMaps.first()?.satelliteImageDate?.source ?? state.satelliteImageSource ?? SatelliteImageSource.Cloudless),
        }))
      ),
      map(([{ vraPrescriptionMaps }]) =>
        vraPrescriptionMaps.map<PrescriptionMap>((map, i) => ({
          ...map,
          state: map.id === null ? 'calculated' : 'saved',
          id: map.id ?? ids?.[i] ?? generateRandomId('number', 31), // 32 bit signed integer on API
          cells: map.cells.map((cell) => ({ ...cell, id: cell.id || generateRandomId('number', 31) })), // OR'd since cells will have ID 0 if not calculated
        }))
      ),
      tap((prescriptionMaps) =>
        this._store.update(strategy === 'upsert' ? upsertEntities(prescriptionMaps) : setEntities(prescriptionMaps))
      ),
      trackRequestResult(key, { additionalKeys: (ids) => ids.map((id) => [id]), skipCache: true }),
      tap(() => this.initialized$.next(true))
    );
  }

  private isLimeOperation(prescriptionMaps: PrescriptionMap[]): boolean {
    return prescriptionMaps[0].operationTypeGroup === OperationTypeGroupEnum.Lime;
  }

  public isPotassiumTask(task: VraTask): boolean {
    return (
      task.allocationType === VraAllocationTypes.PotassiumSoilSamples && task.directorateCropNormNumber === VraCropNormNumbers.PotatoStarch
    );
  }

  private catchError<T>(opts?: { throwError?: boolean }): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      catchError((e: HttpErrorResponse) => {
        const isInternalError = e.status >= 500;

        if (isInternalError || opts?.throwError) return throwError(() => e);

        this.vraRepo.setActiveTask(null);

        return EMPTY;
      })
    );
  }

  private addIdToConcurrentRequests(mapId: number) {
    this._ongoingConcurrentRequest.next([...this._ongoingConcurrentRequest.getValue(), mapId]);
  }

  private removeIdFromConcurrentRequests(mapId: number) {
    const filteredIds = this._ongoingConcurrentRequest.getValue().filter((id) => id !== mapId);
    this._ongoingConcurrentRequest.next(filteredIds);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { FeatureService } from '@app/core/feature/feature.service';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { PointCoordinates } from '@app/core/interfaces/geometry-coordinates.types';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { AppLayoutService } from '@app/core/layout/app-layout.service';
import { MapService } from '@app/core/map/map.service';
import { ArrayElement } from '@app/core/types/array-element.type';
import { OverloadedParameters } from '@app/core/types/overloads.type';
import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import { getSelectedStyle } from '@app/helpers/map/map-styles/map-styles';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import DenmarkBoundary from '@app/shared/openlayers-map/data/denmark_boundary.json';
import { LayerService } from '@app/shared/openlayers-map/services/layer/layer.service';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import MapEvent from 'ol/MapEvent';
import { Coordinate } from 'ol/coordinate';
import { easeOut } from 'ol/easing';
import BaseEvent from 'ol/events/Event';
import { click, never } from 'ol/events/condition';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON.js';
import Geometry, { Type } from 'ol/geom/Geometry';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import Draw from 'ol/interaction/Draw';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import PointerInteraction from 'ol/interaction/Pointer';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import Cluster from 'ol/source/Cluster';
import { default as Vector, default as VectorSource, VectorSourceEvent } from 'ol/source/Vector';
import Style from 'ol/style/Style';
import { BehaviorSubject, Observable, Subject, Subscription, fromEventPattern } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { filterNullish } from '../operators';
import { OpenlayersMapService } from './openlayers-map.service';

type MapEventTypes = ArrayElement<OverloadedParameters<Map['on']>>[0];

@Component({
  selector: 'app-openlayers-map',
  templateUrl: './openlayers-map.component.html',
  styleUrls: ['./openlayers-map.component.scss'],
  providers: [OpenlayersMapService],
})
export class OpenLayersMapComponent implements OnChanges, AfterViewInit, OnDestroy {
  private config$ = new BehaviorSubject<MapConfig>({
    center: [10, 56],
    defaultZoom: 7,
    minZoom: 7,
    maxZoom: 19,
    shouldShowAirphoto: true,
    airphotoMaxZoomLevel: 12,
  });

  private readonly isReadySubject = new BehaviorSubject(false);
  private moveEndSubscription?: Subscription;
  private map?: Map;
  private layers: Layer[] = [];
  private drawInteraction?: Draw | null;
  private modifyInteraction?: Modify;
  private selectInteraction?: Select | null;
  private hoverInteraction?: PointerInteraction | null;
  private selectObservable?: Observable<SelectEvent>;
  private subscriptions: Subscription[] = [];
  private _isDrawing$ = new BehaviorSubject<boolean>(false);

  private _denmarkLayer = this.loadDenmarkBoundaries();

  public get isSelectInteractionEnabled(): boolean {
    return (this.selectInteraction && this.selectInteraction.getActive()) ?? false;
  }

  private _doubleClickZoomInteraction: DoubleClickZoom | undefined;
  private get doubleClickInteraction(): DoubleClickZoom | undefined {
    if (this._doubleClickZoomInteraction) {
      return this._doubleClickZoomInteraction;
    }

    const interactions = this.getMap().getInteractions().getArray();
    for (const interact of interactions) {
      if (interact instanceof DoubleClickZoom) {
        this._doubleClickZoomInteraction = interact;
        break;
      }
    }

    return this._doubleClickZoomInteraction;
  }

  // vals are feature id, and feature revision
  private modifyFeaturesRevisions: [any, number][] = [];

  @Input() public config?: MapConfig;
  @Output() public readonly ready = new EventEmitter();
  @Output() public readonly moveEnd = new EventEmitter();
  public readonly isReady$ = this.isReadySubject.asObservable();

  constructor(
    private mapService: MapService,
    private layerService: LayerService,
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private featureService: FeatureService,
    private openlayersMapService: OpenlayersMapService,
    private layoutService: AppLayoutService
  ) {}

  public ngAfterViewInit() {
    const configSubscription = this.config$
      .pipe(map((config) => ({ config, olMap: this.createMap(config, this.hostElement) })))
      .subscribe(({ olMap, config }) => {
        this.map = olMap;
        this.zoom(config.defaultZoom);
        this.isReadySubject.next(true);
        this.ready.emit();

        this.layoutService.menuMinimized$.subscribe(() => {
          setTimeout(() => {
            this.map?.updateSize();
          });
        });
      });
    this.subscriptions.push(configSubscription);
    this.map?.on('moveend', (event) => this.moveEnd.emit(event));
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.emitChange(changes, 'config', this.config$);
  }

  private emitChange<TDataType>(changes: SimpleChanges, key: keyof OpenLayersMapComponent, subject: Subject<TDataType>) {
    if (changes[key].currentValue !== undefined) {
      subject.next(changes[key].currentValue);
    }
  }

  public ngOnDestroy() {
    this.cleanup();
  }

  public cleanup() {
    this.isReadySubject.next(false);
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.removeNonCoreLayersFromMap();

    this.isReadySubject.next(true);
  }

  /**
   * Return map instance
   */
  public getMap(): Map {
    return this.map!;
  }

  /**
   * Function to increment the zoom level.
   * @param zoomLevel
   */
  public zoom(zoomLevel: number = 7) {
    if (zoomLevel === undefined) {
      zoomLevel = 7;
    }

    this.map?.getView().animate({
      zoom: zoomLevel,
      duration: 100,
    });
  }

  public zoomIncrementally(increment: number) {
    this.zoom(this.map?.getView().getZoom()! + increment);
  }

  public getMapEvent$(pointerEvent: MapEventTypes) {
    const map = this.getMap();

    // TODO: Check parameter to get type infer from correct overload

    // type casting due to function overloading
    return fromEventPattern<BaseEvent>(
      (handler) => map?.on(pointerEvent as any, handler),
      (handler) => map?.un(pointerEvent as any, handler)
    );
  }

  /**
   * Function determines whether or a layer with the provided id it present on map
   * @param id The id of the layer to check
   */
  public isLayerInMap(id: MapLayerId): boolean {
    return this.layers.find((mapLayer: Layer) => mapLayer.get('id') === id) !== undefined;
  }

  /**
   * Function updates the visibility for the provided layer settings. If a layer does not exists, it will be added
   * @param layerSettings Settings for layers to update visibility on
   */
  public updateLayersVisibility(layerSettings: MapLayerSetting[]): void {
    layerSettings.forEach((layerOption) => {
      // check if layer already exists on map
      let mapLayer = this.getLayerFromMap(layerOption.layerId! as MapLayerId);

      if (mapLayer) {
        const layerPreVisible = mapLayer.getVisible();

        // check if layer visibility needs to be updated
        mapLayer.setVisible(layerOption.isVisible);

        // if layer is hotspot, also check marker layer
        if (layerOption.layerId === MapLayerId.HOTSPOTS) {
          mapLayer = this.getLayerFromMap(MapLayerId.HOTSPOTMARKERS);
          if (mapLayer?.getVisible() !== layerOption.isVisible) {
            mapLayer?.setVisible(layerOption.isVisible);
          }
        }

        // If VRA layer is changed from visible to hidden - then delete all features
        // TODO: this should be managed by the lifecycle of the map feature
        if (layerOption.layerId === MapLayerId.VRA && layerPreVisible && !layerOption.isVisible) {
          const imageVectorSource = mapLayer?.getSource() as unknown as any;
          if (imageVectorSource) {
            const vectorSource = imageVectorSource.getSource() as Vector;
            if (vectorSource) {
              vectorSource.clear();
            }
          }
        }
      } else {
        // add new layer to map
        switch (layerOption.layerId) {
          case MapLayerId.HOTSPOTS:
            this.addOrUpdateLayerToMap(
              Object.assign({}, layerOption, {
                zIndex: layerOption.zIndex + 1,
                layerId: MapLayerId.HOTSPOTMARKERS,
              })
            );

            this.addOrUpdateLayerToMap(layerOption);

            break;
          default:
            this.addOrUpdateLayerToMap(layerOption);
        }
      }
    });
  }

  public updateMapCoverFlowLayersVisibility(mapCoverFlowItems: MapCoverFlowItem[]) {
    const mapLayerSettings = mapCoverFlowItems.reduce(
      (prev: MapLayerSetting[], currentMapCoverFlowItem: MapCoverFlowItem) => [...prev, ...currentMapCoverFlowItem.layers],
      []
    );

    this.updateLayersVisibility(mapLayerSettings);
  }

  /**
   * Adds the field layer to map, with or without fill
   * @param fields a list of fieldLayerItems
   * @param layerId the layer to add the fields
   * @param transparent should the fields have a nice fill or not?
   * @param fitMap should the map slide to the extend of the fields
   */
  public addFieldsToMap(fields: FieldFeatures | null, layerId: MapLayerId, transparent: boolean = true, fitMap: boolean = false): void {
    if (fitMap) {
      this.mapService.getMap().slideMapToExtent(fields!.extent!);
    }
    const features = this.featureService.getFieldFeatures(fields!.fieldFeatures, layerId as unknown as LayerId);
    this.addOrUpdateLayerToMap(
      {
        clustered: true,
        isVisible: true,
        layerId: layerId,
        layerType: MapLayerType.VECTOR,
        zIndex: 4,
      },
      features
    );
  }

  /**
   * Removes the fill from field layers
   */
  public removeFieldsFill() {
    this.getFeaturesFromLayer(MapLayerId.FIELDS).forEach((feature) =>
      feature.setProperties({
        fill: 'rgba(255,255,255,0.1)',
      })
    );
  }

  /**
   * Adds given features to given layer
   * @param layerId layer to add features to
   * @param feature features to add
   */
  public addFeatureToLayer(layerId: MapLayerId, feature: Feature): void {
    const mapLayer = this.getLayerFromMap(layerId) as unknown as any;

    if (mapLayer) {
      mapLayer.getSource().addFeature(feature);
    }
  }

  /**
   * Adds a new layer corresponding to the given setting to map.
   * @param layerSetting settings describing new layer
   * @param features optinal initial features
   */
  public addOrUpdateLayerToMap(layerSetting?: MapLayerSetting, features?: Feature[]): Layer {
    if (layerSetting && this.isLayerInMap(layerSetting.layerId! as MapLayerId)) {
      this.removeLayerFromMap(layerSetting.layerId! as MapLayerId);
    }

    const mapLayer = this.layerService.getLayer(layerSetting!, features!, this.map!);
    this.layers.push(mapLayer!);
    this.map?.addLayer(mapLayer!);

    return mapLayer!;
  }

  /**
   * Adds a new layer corresponding to the given setting to map if no layer exists.
   * @param layerSetting settings describing new layer
   * @param features optinal initial features
   */
  public addOrUpdateLayerToMapWithoutOverwrite(layerSetting: MapLayerSetting, features?: Feature[]): Layer {
    const mapLayer = this.layerService.getLayer(layerSetting, features!, this.map!);
    this.layers.push(mapLayer!);
    this.map?.addLayer(mapLayer!);

    return mapLayer!;
  }

  /**
   * Function removes layers with given id from map, if it is present
   * @param id Id of layer to remove
   */
  public removeLayerFromMap(id: MapLayerId): void {
    this.layers = this.layers.filter((layer) => {
      if (layer.get('id') === id) {
        this.map?.removeLayer(layer);
        return false;
      }
      return true;
    });
  }

  public hideLayer(id: MapLayerId): void {
    this.layers.map((layer) => {
      if (layer.get('id') === id) {
        layer.setVisible(false);
      }
    });
  }

  public showLayer(id: MapLayerId): void {
    this.layers.map((layer) => {
      if (layer.get('id') === id) {
        layer.setVisible(true);
      }
    });
  }

  private removeNonCoreLayersFromMap() {
    const layersToKeep = [
      MapLayerId.OSM,
      MapLayerId.FIELDS,
      MapLayerId.HIGH_RES_FIELD_MARKERS,
      MapLayerId.AIRPHOTO,
      MapLayerId.AIRPHOTO_BING,
    ];

    const layerToRemove = this.layers.filter((mapLayer) => {
      const mapLayerId = mapLayer.get('id');
      return !layersToKeep.some((mLayer) => mLayer === mapLayerId);
    });
    layerToRemove.forEach((mapLayer) => {
      setTimeout(() => {
        this.removeLayerFromMap(mapLayer.get('id'));
      });
    });
  }

  /**
   * Function returns layer with provided id. If layer is not found, function return undefined
   * @param id id of layer to return
   */
  public getLayerFromMap(id: MapLayerId) {
    return this.layers.find((mapLayer: Layer) => mapLayer.get('id') === id);
  }

  /**
   * Updates a feature with a new geomatry
   * @param layerId The layer in which the features exists
   * @param featureId The unique 'id' key of the feature
   * @param newGeometry the new geometry to apply to the feature
   */
  public updateFeatureGeometry(layerId: MapLayerId, featureId: any, newGeometry: Geometry): void {
    const mapLayer = this.layers.find((x: Layer) => x.get('id') === layerId);

    if (!mapLayer) {
      return;
    }

    // find feature
    let feature: Feature | undefined;

    if (mapLayer.getSource() instanceof Cluster) {
      const vectorLayer = mapLayer as VectorLayer<any>;
      const clusterSource = vectorLayer.getSource() as Cluster;
      feature = clusterSource
        .getSource()
        ?.getFeatures()
        .find((f: Feature) => {
          return f.get('features') ? f.get('features')[0].getId() === featureId : f.getId() === featureId;
        });
    } else if (mapLayer.getSource() instanceof Vector) {
      const vectorSource = mapLayer.getSource() as Vector;
      feature = vectorSource.getFeatures().find((f: Feature) => {
        return f.get('features') ? f.get('features')[0].getId() === featureId : f.getId() === featureId;
      });
    }

    if (!feature) {
      return;
    }

    // update feature geometry
    feature.setGeometry(newGeometry);
  }

  /**
   * Returns the center of a feature as a point geometry
   * @param feature The feature to get the center of
   */
  public getCenterOfFeature(feature: Feature): PointCoordinates {
    return this.mapService.getCenterOfFeature(feature);
  }

  /**
   * Add given features to layer width given id
   * @param layerId layer to add features onto
   * @param features features to add
   */
  public addFeaturesToLayer(layerId: MapLayerId, features: Feature[]): void {
    const mapLayer = this.layers.find((l) => l.get('id') === layerId);

    if (!mapLayer) return;

    if (mapLayer.getSource() instanceof Cluster) {
      const layerSource = mapLayer.getSource() as Cluster;
      layerSource.getSource()?.addFeatures(features);
      layerSource.getSource()?.refresh();
    } else {
      const layerSource = mapLayer.getSource() as Vector;
      layerSource.addFeatures(features);
      layerSource.refresh();
    }
  }

  public addPointerMoveInteraction() {
    return fromEventPattern<MapBrowserEvent<UIEvent>>(
      (handler) => this.map?.on('pointermove', handler),
      (handler) => this.map?.un('pointermove', handler)
    );
  }

  /**
   * Function adds select interaction to specified layer
   * @param toggle toggle rather or not multiselect should be used: default value is false
   * @param layers Layer ids for the layers to become selectable
   * @param selectedFeatures mark features as selected
   */
  public addSelectInteraction(toggle = false, layers?: MapLayerId[], selectedFeatures: Feature[] = []): Observable<SelectEvent> {
    if (this.selectInteraction) {
      this.removeSelectInteraction();
    }

    this.selectInteraction = new Select({
      style: (feature) => {
        return getSelectedStyle(
          this.map?.getView().getZoom()!,
          feature.get('features') ? feature.get('features').length : 1,
          feature.get('features') ? feature.get('features')[0] : feature
        );
      },
      condition: (event: MapBrowserEvent<PointerEvent>) => click(event),
      toggleCondition: (event: MapBrowserEvent<PointerEvent>) => (toggle ? click(event) : never()),
      features: new Collection(selectedFeatures),
      layers: (selectedLayer) => {
        if (!layers) {
          return true;
        }
        return layers.includes(selectedLayer.get('id'));
      },
    });

    this.map?.addInteraction(this.selectInteraction);
    this.addHoverInteraction(layers);

    this.selectObservable = fromEventPattern<SelectEvent>(
      (handler) => this.selectInteraction?.on('select', handler),
      (handler) => this.selectInteraction?.un('select', handler)
    ).pipe(
      tap((event) => {
        event.mapBrowserEvent.map.getViewport().style.cursor = '';
      })
    );

    return this.selectObservable;
  }

  private addHoverInteraction(layers?: MapLayerId[]) {
    if (this.hoverInteraction) {
      this.map?.removeInteraction(this.hoverInteraction);
    }

    this.hoverInteraction = new PointerInteraction({
      handleMoveEvent: (event) => {
        this.mapService.showPointerOnMapFeature(event, layers!);
      },
    });

    this.map?.addInteraction(this.hoverInteraction);
  }

  public removeSelectInteraction() {
    this.map?.removeInteraction(this.selectInteraction!);
    this.map?.removeInteraction(this.hoverInteraction!);
    this.selectInteraction = null;
    this.hoverInteraction = null;
  }

  public removeFeatureFromLayer(layerId: MapLayerId, feature: Feature | null) {
    if (!feature) {
      return;
    }

    if (!this.getLayerFromMap(layerId)) {
      return;
    }

    const vSource = this.getLayerFromMap(layerId)?.getSource() as Vector;

    // A bug in openlayers causes the field to (most of the time) stay visually on the map dispite being removed
    // Setting the style to 'invisible' is a workaround for it.
    feature.setStyle(
      new Style({
        stroke: undefined,
      })
    );
    // This sometimes throws an exception but still removes the polygon
    try {
      vSource.removeFeature(feature);
    } catch (e) {}
  }

  public disableSelectInteraction(): void {
    if (!this.selectInteraction) {
      return;
    }
    this.hoverInteraction?.setActive(false);
    this.selectInteraction.setActive(false);
  }

  public enableSelectInteraction(): void {
    if (!this.selectInteraction) {
      return;
    }
    this.hoverInteraction?.setActive(true);
    this.selectInteraction.setActive(true);
  }

  public enableDoubleClickZoom() {
    const doubleClickZoomInteraction = this.doubleClickInteraction;
    if (!doubleClickZoomInteraction || doubleClickZoomInteraction.getActive()) {
      return;
    }

    doubleClickZoomInteraction.setActive(true);
  }

  public disableDoubleClickZoom() {
    const doubleClickZoomInteraction = this.doubleClickInteraction;
    if (!doubleClickZoomInteraction || !doubleClickZoomInteraction.getActive()) {
      return;
    }

    doubleClickZoomInteraction.setActive(false);
  }

  public deselectFeatures(): void {
    /**
     * HERE BE DRAGONS
     */
    if (!this.selectInteraction) {
      return;
    }

    try {
      // At first call this will apparently fail, for no good reason
      // This is the closest we have of an explanation:
      // https://github.com/openlayers/openlayers/issues/6183
      this.selectInteraction.getFeatures().clear();
    } catch (error) {
      // Second time around, it seems to succeed, but wont update the map
      this.selectInteraction.getFeatures().clear();
      // That is why we trigger an invisble zoom, to get the map to rerender
      this.forceRerender();
      throw error;
    }
  }

  public forceRerender() {
    this.zoomIncrementally(0.00001);
  }

  public selectFeatures(features: Feature[]): void {
    if (!this.selectInteraction) {
      return;
    }
    this.deselectFeatures();
    features.forEach((feature) => this.selectInteraction?.getFeatures().push(feature));
  }

  /**
   * Function adds drawing interaction
   * @param layerId layer to add interaction to
   * @param drawType Type of geomery to draw
   * @param drawingStyle styling for the drawn elements
   */
  public addDrawingInteraction(
    layerId: MapLayerId,
    drawType: GeometryType,
    drawingStyle: Style[]
  ): Observable<VectorSourceEvent> | undefined {
    const mapLayer = this.getLayerFromMap(layerId);

    if (!mapLayer?.getSource()) return;

    let type: Type;
    const mapSource = mapLayer.getSource() as Vector;

    switch (drawType) {
      case GeometryType.POINT:
        type = 'Point';
        break;
      case GeometryType.LINE:
        type = 'LineString';
        break;
      case GeometryType.POLYGON:
        type = 'Polygon';
        break;
      case GeometryType.MULTIPOLYGON:
        type = 'MultiPolygon';
        break;
    }

    this.drawInteraction = new Draw({ source: mapSource, type, style: drawingStyle });

    this.map?.addInteraction(this.drawInteraction);

    this.drawInteraction.on('drawstart', () => {
      this._isDrawing$.next(true);
    });

    this.drawInteraction.on('drawend', () => {
      this._isDrawing$.next(false);
    });

    return fromEventPattern<VectorSourceEvent>(
      (handler) => mapSource?.on('addfeature', handler),
      (handler) => mapSource?.un('addfeature', handler)
    ).pipe(tap((event) => event.feature?.set('layerId', layerId)));
  }

  public get isDrawing$() {
    return this._isDrawing$.asObservable();
  }

  /**
   * Removed drawing interaction
   * @param layerId layer to remove interaction from
   */
  public removeDrawingInteraction(layerId?: MapLayerId): void {
    this._isDrawing$.next(false);

    if (this.drawInteraction) {
      this.map?.removeInteraction(this.drawInteraction);
      this.drawInteraction = null;
    }
  }

  /**
   * Add modify interaction to features in specified layer
   * @param layerId layer to modify
   */
  public addModifyInteraction(layerId: MapLayerId): Observable<Feature> | undefined {
    if (this.modifyInteraction) {
      this.map?.removeInteraction(this.modifyInteraction);
    }

    const mapLayer = this.getLayerFromMap(layerId);
    this.modifyFeaturesRevisions = [];

    if (!mapLayer?.getSource()) return;

    const mapSource = mapLayer.getSource() as Vector;
    // TODO: Enable when we start clustering again
    // const vectorSource = clusterSource.getSource() as source.Vector;
    const features = new Collection(mapSource.getFeatures());
    const revisions = mapSource.getFeatures().map<[any, number]>((feature) => [feature.getId(), feature.getRevision()]);

    this.modifyInteraction = new Modify({ features });
    this.modifyFeaturesRevisions.push(...revisions);
    this.map?.addInteraction(this.modifyInteraction);

    return fromEventPattern<ModifyEvent>(
      (handler) => this.modifyInteraction?.on('modifyend', handler),
      (handler) => this.modifyInteraction?.un('modifyend', handler)
    ).pipe(
      map((event) => this.mapModifyEvent(event, this.modifyFeaturesRevisions)),
      filterNullish(),
      map((res) => res.feature)
    );
  }

  /**
   * Add modify interaction to current feature in specified layer
   */
  public addModifyInteractionForFeature(featureArg: Feature | null): Observable<Feature> | undefined {
    this.removeModifyInteraction();
    this.modifyFeaturesRevisions = [];

    if (!featureArg) return;

    // TODO: Enable when we start clustering again
    // const vectorSource = clusterSource.getSource() as source.Vector;
    const featuresList = [featureArg];
    const revisions = featuresList.map<[any, number]>((feature) => [feature.getId(), feature.getRevision()]);

    const features = new Collection(featuresList);
    this.modifyInteraction = new Modify({
      features,
      pixelTolerance: 5,
      style: FieldStyles.getFieldDrawingStyle(),
    });
    this.modifyFeaturesRevisions.push(...revisions);
    this.map?.addInteraction(this.modifyInteraction);

    return fromEventPattern<ModifyEvent>(
      (handler) => this.modifyInteraction?.on('modifyend', handler),
      (handler) => this.modifyInteraction?.un('modifyend', handler)
    ).pipe(
      map((event) => this.mapModifyEvent(event, this.modifyFeaturesRevisions)),
      filterNullish(),
      map((res) => res.feature)
    );
  }

  public removeModifyInteraction(layerId?: MapLayerId): void {
    // Todo look in supplied layer for interaction
    if (this.modifyInteraction) {
      this.map?.removeInteraction(this.modifyInteraction);
    }
  }

  public setCenter(lonLat: [number, number]) {
    this.map?.getView().setCenter(fromLonLat(lonLat));
  }

  public slideMapToPoint(longLat: number[], zoomLevel?: number, doTransform: boolean = true) {
    if (longLat) {
      this.map?.getView().animate({
        center: doTransform ? transform(longLat, 'EPSG:4326', 'EPSG:3857') : longLat,
        zoom: zoomLevel,
        easing: easeOut,
        duration: 500,
      });
    }
  }

  public slideMapToExtent(e: Extent | undefined, padding?: number[]) {
    if (!e) return;

    if (!this.isValidExtend(e)) return;

    this.map?.getView().fit(e, {
      padding,
      easing: easeOut,
      duration: 500,
    });
  }

  public getFeaturesFromLayer(layerId: MapLayerId): Feature[] {
    const mapLayer = this.getLayerFromMap(layerId);
    const mapSource = mapLayer?.getSource() as Vector;
    return mapSource?.getFeatures();
  }

  private createMap(config: MapConfig, hostElement: ElementRef): Map | undefined {
    if (!hostElement || !hostElement.nativeElement) {
      return;
    }

    const $hostElement: HTMLElement = hostElement.nativeElement;
    const $mapElement = this.renderer.createElement('div');

    this.renderer.appendChild($hostElement, $mapElement);

    this.renderer.setStyle($mapElement, 'display', 'block');
    this.renderer.setStyle($mapElement, 'height', '100%');
    this.renderer.setStyle($mapElement, 'width', '100%');

    const osmLayer = this.layerService.getOSMLayer();
    this.layers.push(osmLayer);

    const olMap = this.openlayersMapService.createMap($mapElement, config);

    this.addOSMLayer(olMap);
    this.addAirPhotos(olMap, config.airphotoMaxZoomLevel!);

    return olMap;
  }

  private addOSMLayer(olMap: Map) {
    const osmLayer = this.layerService.getOSMLayer();
    this.layers.push(osmLayer);
    olMap.addLayer(osmLayer);
  }

  private addAirPhotos(olMap: Map, airphotoMaxZoomLevel: number) {
    const airphoto = this.layerService.getOrtoPhotoLayer();
    const airphotoBing = this.layerService.getBingMapsLayer();

    this.layers.push(airphotoBing, airphoto);
    olMap.addLayer(airphotoBing);
    olMap.addLayer(airphoto);

    if (this.moveEndSubscription) {
      this.moveEndSubscription.unsubscribe();
    }

    this.moveEndSubscription = fromEventPattern<MapEvent>(
      (handler) => olMap.on('moveend', handler),
      (handler) => olMap.un('moveend', handler)
    ).subscribe((event: MapEvent) => {
      const airphotoVisible = event.map.getView().getZoom()! > airphotoMaxZoomLevel;
      if (!airphotoVisible) {
        airphoto.setVisible(false);
        airphotoBing.setVisible(false);
      } else {
        // Show the right airPhoto - KortForsyningen if inside DK, Bing if not
        const coordinate = event.frameState?.viewState.center;
        const insideDk = this.coordinateInsideDk(coordinate);
        airphoto.setVisible(insideDk);
        airphotoBing.setVisible(true);
      }
      this.mapService.airPhotoIsVisible$.next(airphotoVisible);
    });
  }

  private loadDenmarkBoundaries() {
    let vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(DenmarkBoundary, {
        dataProjection: 'EPSG:4326', // Source projection (original data)
        featureProjection: 'EPSG:3857', // Target projection (map's projection)
      }),
    }) as VectorSource;

    return new VectorLayer({
      source: vectorSource,
    });
  }

  private coordinateInsideDk(coordinate: Coordinate | undefined): boolean {
    if (!coordinate) {
      return false;
    }

    let inside = false;

    this._denmarkLayer.getSource()?.forEachFeature((feature) => {
      if (feature.getGeometry()?.intersectsCoordinate(coordinate)) {
        inside = true;
      }
    });

    return inside;
  }

  private mapModifyEvent(event: ModifyEvent, modifyFeaturesRevisions: [any, number][]) {
    // determine which feature has changed
    return event.features
      .getArray()
      .map((feature) => ({
        feature,
        revision: modifyFeaturesRevisions.find((rev) => rev[0] === feature.getId()),
      }))
      .find((temp) => temp.feature && temp.revision && temp.revision[1] !== temp.feature.getRevision());
  }

  private isValidExtend(extent: Coordinate) {
    if (!extent || extent.length < 2) {
      // extent is null, undefined, or does not have 2 or more elements
      return false;
    }

    // check if each element in extent is a finite number
    for (const coordinate of extent) {
      if (!Number.isFinite(coordinate)) {
        // one of the elements is not a finite number
        return false;
      }
    }

    // all checks passed; extent is valid
    return true;
  }
}

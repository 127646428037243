import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'ol';
import { Subscription } from 'rxjs';
import { BlightStateService } from './blight-state.service';

@Injectable({
  providedIn: 'root',
})
export class BlightLogicService {
  constructor(
    private _mapService: OlMapService,
    private _layerService: OlLayerService,
    private blightStateService: BlightStateService,
    private translateService: TranslateService
  ) {}

  public startRenderingFieldsToMap(): Subscription {
    return this.blightStateService.potatoFields$.subscribe((fields) => {
      const mapFeatures = this.createBlightLayerFeatures(fields);
      this.addFeaturesToMap(mapFeatures);
      const fieldFeatures = this.createFieldMarkerFeatures(fields);
      this.addMarkersToMap(fieldFeatures);
      this._layerService.setLayerVisibility(LayerId.HIGH_RES_POTATO_MARKERS, true);
      this._layerService.setLayerVisibility(LayerId.HIGH_RES_FIELD_MARKERS, false);

      this._mapService.magicReRender();
    });
  }

  public resetHighResFieldMarkers() {
    this._layerService.setLayerVisibility(LayerId.HIGH_RES_FIELD_MARKERS, true);
    this._layerService.setLayerVisibility(LayerId.HIGH_RES_POTATO_MARKERS, false);
    this._layerService.removeLayers(LayerId.HIGH_RES_POTATO_MARKERS);
  }

  public enableSelectInteraction() {
    this._mapService.enableSelectInteraction();
  }

  public disableSelectInteraction() {
    this._mapService.disableSelectInteraction();
  }

  private createBlightLayerFeatures(blightFields: Field[]): Feature[] {
    const languageBlightMark = this.translateService.instant('main.yieldPrognosis.blightMarker');
    return blightFields.map((field) => {
      const { geometry, blightRegistrationDate, blightTreatmentDaysSince } = field;

      const geoType = WKTUtil.getGeometryTypeFromWktString(geometry!);
      const coords = WKTUtil.getCoordinatesFromWktString(geometry!);
      const feature = FeatureUtil.getMapFeature(LayerId.BLIGHT_FIELDS, geoType, coords);
      feature.set('field', field);
      const blightmarker = !!blightRegistrationDate ? languageBlightMark + '\n' : '';
      feature.set('text', blightmarker + (blightTreatmentDaysSince?.toString() ?? '-'));
      return feature;
    });
  }

  private createFieldMarkerFeatures(fields: Field[]): Feature[] {
    return fields.map((field) => {
      const { geometry } = field;

      const geoType = WKTUtil.getGeometryTypeFromWktString(geometry!);
      const coords = WKTUtil.getCoordinatesFromWktString(geometry!);
      const feature = FeatureUtil.getMapFeature(LayerId.HIGH_RES_POTATO_MARKERS, geoType, coords);
      feature.set('field', field);
      return feature;
    });
  }

  private addMarkersToMap(features: Feature[]) {
    this._layerService.createFeatureLayer(LayerId.HIGH_RES_POTATO_MARKERS, features);
    // features.forEach((feature) => {
    //   this._layerService.addFeature(feature, LayerId.HIGH_RES_POTATO_MARKERS);
    // });
    this._layerService.setLayerVisibility(LayerId.HIGH_RES_POTATO_MARKERS, true);
  }

  private addFeaturesToMap(features: Feature[]) {
    this._layerService.createFeatureLayer(LayerId.BLIGHT_FIELDS, []);
    features.forEach((feature) => {
      this._layerService.addFeature(feature, LayerId.BLIGHT_FIELDS);
    });
  }

  public createBlightMapLayer(features: Feature[]) {
    this._layerService.createFeatureLayer(LayerId.BLIGHT_POLYGONS, []);
    features.forEach((feat) => {
      this._layerService.addFeature(feat, LayerId.BLIGHT_POLYGONS);
    });
    this._layerService.setLayerOpacity(LayerId.BLIGHT_POLYGONS, 50);
  }

  public createBlightFieldLayer(features: Feature[]) {
    this._layerService.createFeatureLayer(LayerId.BLIGHT_FIELD_POLYGONS, []);
    features.forEach((feat) => {
      this._layerService.addFeature(feat, LayerId.BLIGHT_FIELD_POLYGONS);
    });
  }
}

import { Injectable, TemplateRef } from '@angular/core';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { LoadingState } from '@app/helpers/loading-state';
import { LocalState } from '@app/helpers/local-state';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrognosisShownComponent } from './prognosis-side-drawer/prognosis-shown-component.enum';

@Injectable({
  providedIn: 'root',
})
export class PrognosisService {
  private _sideDrawerFeatureContentRefSubject = new Subject<TemplateRef<any>>();
  private drawerWidthSubject = new ReplaySubject<string>(1);
  public drawerWidth$: Observable<string>;

  private shownComponentState = new LocalState<{ shownComponent: PrognosisShownComponent }>({
    shownComponent: PrognosisShownComponent.PrognosisPicker,
  });
  public shownComponentState$ = this.shownComponentState.changes$;

  public mapLoadingState = new LoadingState();

  constructor(private accessControlService: AccessControlService) {
    this.drawerWidth$ = this.drawerWidthSubject.asObservable();
  }

  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }

  public set _sideDrawerFeatureContentRef(sideDrawerContentRef: TemplateRef<any>) {
    this._sideDrawerFeatureContentRefSubject.next(sideDrawerContentRef);
  }

  public get sideDrawerFeatureContentRef$(): Observable<TemplateRef<any>> {
    return this._sideDrawerFeatureContentRefSubject.asObservable();
  }

  public setShownComponent(component: PrognosisShownComponent) {
    this.shownComponentState.setState({ shownComponent: component });
  }

  public startLoading(message: string) {
    setTimeout(() => {
      this.mapLoadingState.start(message);
    });
  }

  public stopLoading() {
    setTimeout(() => {
      this.mapLoadingState.stop();
    });
  }

  /**
   * Gets the cover flow layers items.
   * @returns {Observable<MapCoverFlowItem[]>} The cover flow layers items.
   */
  public getCoverFlowLayersItems(): Observable<MapCoverFlowItem[]> {
    return combineLatest([
      this.accessControlService.hasAccessTo('prognosis_corn'),
      this.accessControlService.hasAccessTo('prognosis_growth_regulation'),
      this.accessControlService.hasAccessTo('prognosis_yield_prognosis'),
      this.accessControlService.hasAccessTo('prognosis_potato_blight'),
    ]).pipe(
      map(([hasPrognosisCorn, hasPrognosisGrowthRegulation, hasPrognosisYieldPrognosis, hasPrognosisPotatoBlight]) => {
        return [
          new MapCoverFlowItem({
            mapCoverFlowLayersId: LayerId.CORN_PROGNOSIS,
            displayName: 'main.fieldmap.layers.corn',
            name: 'Majsprognose',
            isVisible: false,
            isDisabled: !hasPrognosisCorn,
            layers: this.getInitMapLayerSettings([LayerId.CORN_PROGNOSIS]),
          }),
          new MapCoverFlowItem({
            mapCoverFlowLayersId: LayerId.GROWTH_REGULATION,
            displayName: 'main.fieldmap.layers.growthRegulation',
            name: 'Vækstregulering',
            isVisible: false,
            isDisabled: !hasPrognosisGrowthRegulation,
            layers: this.getInitMapLayerSettings([LayerId.GROWTH_REGULATION]),
          }),
          new MapCoverFlowItem({
            mapCoverFlowLayersId: LayerId.YIELD_PROGNOSIS,
            displayName: 'main.fieldmap.layers.yieldPrognosis',
            name: 'Udbytteprognose',
            isVisible: false,
            isDisabled: !hasPrognosisYieldPrognosis,
            layers: this.getInitMapLayerSettings([LayerId.YIELD_PROGNOSIS]),
          }),
          new MapCoverFlowItem({
            mapCoverFlowLayersId: LayerId.POTATO_BLIGHT,
            displayName: 'main.fieldmap.layers.potatoBlight',
            name: 'Kartoffel Skimmel',
            isVisible: false,
            isDisabled: !hasPrognosisPotatoBlight,
            layers: this.getInitMapLayerSettings([LayerId.POTATO_BLIGHT]),
          }),
        ];
      })
    );
  }

  /**
   * Gets the map layer settings for this map.
   */

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private getInitMapLayerSettings(layerIds: LayerId[] = null): MapLayerSetting[] {
    const layers: MapLayerSetting[] = [
      {
        layerId: LayerId.CORN_PROGNOSIS,
        isVisible: false,
        layerType: MapLayerType.VECTOR,
        zIndex: 1,
      },
      {
        layerId: LayerId.GROWTH_REGULATION,
        isVisible: false,
        layerType: MapLayerType.VECTOR,
        zIndex: 2,
      },
      {
        layerId: LayerId.YIELD_PROGNOSIS,
        isVisible: false,
        layerType: MapLayerType.VECTOR,
        zIndex: 2,
      },
      {
        layerId: LayerId.POTATO_BLIGHT,
        isVisible: false,
        layerType: MapLayerType.VECTOR,
        zIndex: 2,
      },
    ];

    const matchedLayers = layerIds ? layerIds.map((layerId) => layers.find((layer) => layer.layerId === layerId)) : layers;

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return matchedLayers;
  }

  public findMapCoverFlowIndex(mapCoverFlowItem: MapCoverFlowItem, mapCoverFlowItemList: MapCoverFlowItem[]) {
    if (mapCoverFlowItem.isVisible) {
      return mapCoverFlowItemList.findIndex(
        (mapCoverFlowItm) => mapCoverFlowItm.mapCoverFlowLayersId === mapCoverFlowItem.mapCoverFlowLayersId
      );
    } else {
      return null;
    }
  }
}

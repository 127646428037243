import { Injectable } from '@angular/core';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NdviRepo } from '@app/core/ndvis/ndvis-repo.service';
import { MethodTypes } from '@app/method-types.enum';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { SatelliteImageDate } from '../interfaces/ndvi-image-date.interface';

@Injectable()
export class NdviService {
  public static NdviImageMapDateFormat = 'DD-MM-YYYY';
  private readonly cloudCoveragePct = 100;
  constructor(
    private ndviRepo: NdviRepo,
    private languageService: LanguageService
  ) {}

  public getNdviMapCoverFlowItem(isDisabled: boolean = false) {
    const ndviMapCoverFlowItem = new MapCoverFlowItem({
      mapCoverFlowLayersId: LayerId.BIOMASS,
      displayName: 'main.fieldmap.layers.redEdgeNdvi',
      name: this.languageService.getText('main.fieldmap.layers.redEdgeNdvi'),
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: LayerId.BIOMASS,
          layerType: MapLayerType.TILE,
          zIndex: 5,
          useAuthHeaders: true,
          isVisible: true,
          url: undefined,
          method: MethodTypes.GET,
        },
      ],
    });

    return ndviMapCoverFlowItem;
  }

  public getNDVIImageDates(farms: Farm[]) {
    return this.ndviRepo.getSatelliteImageDates(farms.map((farm) => farm.id)).pipe(
      map((ndviImageDates) => {
        if (!ndviImageDates) return null;
        return ndviImageDates
          .filter((imgDto) => imgDto.cloudCoveragePct <= this.cloudCoveragePct)
          .map<SatelliteImageDate>((imgDto) => ({ ...imgDto, date: DateTime.fromISO(imgDto.date) }));
      })
    );
  }

  public getClearSkyImageDates(farms: Farm[]) {
    return this.ndviRepo.getCloudlessImageDates(farms.map((farm) => farm.id));
  }

  public findNdviHistory(ndviHistories: NdviHistory[], featureModel: FieldLayerItem) {
    return ndviHistories.find((ndviHistory) => ndviHistory.featureId === featureModel.featureId);
  }
  public findNdviHistoryForField(ndviHistories: NdviHistory[], fieldId: number) {
    return ndviHistories.find((ndviHistory) => ndviHistory.fieldId === fieldId);
  }
}

import { Injectable } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';

@Injectable({
  providedIn: 'root',
})
export class CreateHotspotService {
  public geometryTypes: GeometryType[] = [GeometryType.POINT, GeometryType.POLYGON, GeometryType.LINE];

  public hotspotTypes: HotspotType[] = [
    {
      id: 1,
      name: 'main.hotspots.weed',
      geometryType: GeometryType.POLYGON,
      displayIndex: 10,
      iconName: 'hotspot-weeds',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 2,
      name: 'main.hotspots.pests',
      geometryType: GeometryType.POLYGON,
      displayIndex: 20,
      iconName: 'hotspot-pests',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 3,
      name: 'main.hotspots.fungus',
      geometryType: GeometryType.POLYGON,
      displayIndex: 30,
      iconName: 'hotspot-diseases',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 4,
      name: 'main.hotspots.well',
      geometryType: GeometryType.POINT,
      displayIndex: 40,
      iconName: 'hotspot-well',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 5,
      name: 'main.hotspots.drainage',
      geometryType: GeometryType.LINE,
      displayIndex: 50,
      iconName: 'hotspot-drain',
      geometryTypes: [GeometryType.LINE],
    },
    {
      id: 6,
      name: 'main.hotspots.rocks',
      geometryType: GeometryType.POINT,
      displayIndex: 60,
      iconName: 'hotspot-stone',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 8,
      name: 'main.hotspots.spray',
      geometryType: GeometryType.POLYGON,
      displayIndex: 70,
      iconName: 'hotspot-spraywindow',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
    {
      id: 7,
      name: 'main.hotspots.misc',
      geometryType: GeometryType.POLYGON,
      displayIndex: 100,
      iconName: 'hotspot-miscellaneous',
      geometryTypes: [GeometryType.POINT, GeometryType.POLYGON],
    },
  ];

  constructor() {}
}

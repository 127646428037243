import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Farm } from '@app/core/interfaces/farm.interface';
import { ObservableType } from '@app/core/types/observable-type.type';
import { AsAppliedTask } from '@app/new-map/features/field-analysis/features/as-applied/as-applied-task.class';
import { map, shareReplay, startWith } from 'rxjs';
import { filterNullish } from '../operators';
import { DownloadManagerData, DownloadManagerResult, List, ListOfLists } from './download-manager.interface';

@Component({
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadManagerDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DownloadManagerData | undefined,
    public dialogRef: MatDialogRef<DownloadManagerDialogComponent>
  ) {
    if (!data) throw new Error('DownloadManagerData is missing');
  }

  protected onModelChange(selectedItems: Array<AsAppliedTask>, originalList: List, index: number) {
    this.selectedItems[index] = { ...originalList, items: selectedItems };
  }

  protected onSave() {
    if (!this.selectedFarm.value) throw new Error('Farm is null. Has the FormControl been reset?');

    const result: DownloadManagerResult = { farm: this.selectedFarm.value, items: this.selectedItems };

    this.dialogRef.close(result);
  }

  private isEmpty(items: List | ListOfLists): boolean {
    return this.isListOfLists(items) ? items.every((x) => x.items.length === 0) : items?.items.length === 0;
  }

  private isListOfLists = (data: ObservableType<typeof this.items$>): data is ListOfLists => {
    if (!Array.isArray(data)) return false;
    return data.some((x) => Array.isArray(x.items));
  };

  protected selectedItems: ListOfLists = [];

  // extend farm to whether its items list is empty
  protected farms: Array<Farm & { disabled: boolean }> = this.data?.map((x) => ({ ...x.farm, disabled: this.isEmpty(x.items) })) ?? [];

  public selectedFarm = new FormControl<Farm | undefined>(this.data?.find((x) => this.isEmpty(x.items) === false)?.farm);

  protected items$ = this.selectedFarm.valueChanges.pipe(
    startWith(this.selectedFarm.value),
    filterNullish(),
    map((farm) => this.data?.find((x) => x.farm.id === farm.id)?.items),
    map((result) => result ?? []),
    shareReplay()
  );

  protected isListOfLists$ = this.items$.pipe(map(this.isListOfLists));

  protected compareFarms = (farm1: Farm, farm2: Farm) => farm1.id === farm2.id;

  /**
   * Template type casting
   */

  protected asListOfLists = (data: ObservableType<typeof this.items$>): ListOfLists => {
    return data as ListOfLists;
  };

  protected asList = (data: ObservableType<typeof this.items$>): List => {
    return data as List;
  };
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { AsAppliedMetadataHelper } from '@app/helpers/as-applied-metadata-helper.ts';
import { MetadataTask } from '@app/new-map/features/field-analysis/features/as-applied/metadata-task.class';
import { DateTime } from 'luxon';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-meta-data-child-form',
  templateUrl: './meta-data-child-form.component.html',
  styleUrls: ['./meta-data-child-form.component.scss'],
})
export class MetaDataChildFormComponent implements OnInit, OnDestroy {
  @Input() public form!: FormGroup;
  @Input() public selectableFields$!: Observable<Field[]>;
  @Input() public selectableTasks$!: Observable<MetadataTask[]>;
  @Input() public maxDate!: Date;
  @Input() public isEditMode = false;
  @Output() public onRemoveChild = new EventEmitter();
  @Output() public onPointToggle = new EventEmitter();
  @Output() public onFieldNumberChange = new EventEmitter();

  public units: string[] = ['kg/ha', 'l/ha', 'ton/ha'];
  public selectableTasksGroupedByOperationTypeGroup$!: Observable<OperationTypeGroupTaskCollection[]>;

  private subscriptions = new Subscription();
  private _selectedField!: ReplaySubject<Field>;
  private originalFieldNumber: any;
  private originalMetadataTask: any;
  private originalDate?: string | number | Date;

  constructor(private languageService: LanguageService) {}

  public removeChildClicked() {
    this.onRemoveChild.emit(this.form);
  }

  public ngOnInit(): void {
    this.originalFieldNumber = this.form.get('fieldNumber')?.value;
    this.originalMetadataTask = this.form.get('metadataTask')?.value;
    this.originalDate = this.form.get('appliedDate')?.value;

    if (!this.isEditMode) {
      this.subscriptions.add(
        // If the form returns to its original values, it is marked as pristine
        this.form.valueChanges.subscribe((changes) => {
          const jsDate = new Date(this.originalDate as string);
          const appliedJsDate = new Date(changes.appliedDate);

          const sameDay = DateTime.fromJSDate(jsDate).hasSame(DateTime.fromJSDate(appliedJsDate), 'day');
          // the original time is lost when formatting the date, this ensures that the original date and the date from changes are on the same day
          const sameFieldNumber = changes.fieldNumber === this.originalFieldNumber;
          // This ensures that the difference between null and undefined doesnt count as a change.
          const bothFalsey = !changes.metadataTask && !this.originalMetadataTask;

          const isTheSameTask = changes.metadataTask === this.originalMetadataTask;

          if (sameFieldNumber && sameDay && (bothFalsey || isTheSameTask)) {
            this.form.markAsPristine();
          }

          if (changes.fieldNumber) {
            this.onFieldNumberChange.emit();
          }
        })
      );
      this._selectedField = new ReplaySubject<Field>(1);
      // Filter tasks to only include tasks with field id matching the selected fields id
      this.selectableTasksGroupedByOperationTypeGroup$ = combineLatest([this.selectableTasks$, this.selectedField$]).pipe(
        map(([tasks, selectedField]) => tasks.filter((task) => task.fieldId === selectedField?.id)),
        map((tasks) => AsAppliedMetadataHelper.groupAndSortTasks(tasks, this.languageService))
      );

      const fieldNumberControl = this.form.get('fieldNumber');

      this.subscriptions.add(
        fieldNumberControl?.valueChanges
          .pipe(
            startWith(fieldNumberControl.value),
            distinctUntilChanged(),
            withLatestFrom(this.selectableFields$),
            map(([selectedFieldNumber, selectableFields]) => selectableFields.find((field) => field.number === selectedFieldNumber))
          )
          .subscribe((selectedField) => this.updateSelectedFieldAndMetadataTaskControl(selectedField))
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private get selectedField$() {
    return this._selectedField.asObservable().pipe(distinctUntilChanged());
  }

  private updateSelectedFieldAndMetadataTaskControl(selectedField?: Field) {
    const metadataTaskControl = this.form.get('metadataTask');

    const isTaskFormEnabled = metadataTaskControl?.enabled;
    if (selectedField) this._selectedField.next(selectedField);

    if (!!selectedField && !isTaskFormEnabled) {
      metadataTaskControl?.enable();
    } else {
      if (!selectedField && isTaskFormEnabled) {
        metadataTaskControl.reset();
        metadataTaskControl.disable();
      }
    }
  }

  public togglePoints() {
    this.onPointToggle.emit(this.form);
  }
}

export interface OperationTypeGroupTaskCollection {
  operationTypeGroupName: string;
  tasks: MetadataTaskWithText[];
}

export interface MetadataTaskWithText extends MetadataTask {
  text: string;
}

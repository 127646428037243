import { Injectable } from '@angular/core';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { MapConstants } from '@app/core/map/map.constants';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerBundle, LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { TranslateService } from '@ngx-translate/core';
import colormap from 'colormap';
import { Feature } from 'ol';
import { WKT } from 'ol/format';
import { Type } from 'ol/geom/Geometry';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { BehaviorSubject } from 'rxjs';
import { MetadataGeometry } from '../../as-applied/file-upload/metadata-parent';
import { FindReplaceDdiNamesPipe } from '../pipes/findReplaceDdiNames.pipe';
import { DataOption } from './iso.repository';

@Injectable({ providedIn: 'root' })
export class IsoMapService {
  constructor(
    private mapService: OlMapService,
    private decimalService: DecimalService,
    private translateService: TranslateService,
    private layerService: OlLayerService
  ) {}

  public legend$ = new BehaviorSubject<ScaleLegendSettings | null>(null);

  public get map() {
    return this.mapService.mapComponent;
  }

  public addMetadataGeometriesToMap(metadataGeometries: MetadataGeometry[]) {
    const dataGeometries = [];
    const noDataGeometries = [];

    for (const mg of metadataGeometries) {
      if (mg.quantity !== undefined) {
        dataGeometries.push(mg);
      } else {
        noDataGeometries.push(mg);
      }
    }

    // Create layers for both dataGeometries and noDataGeometries
    const layer = this.createLayerFromMetadataGeometries(dataGeometries);
    const noDataLayer = this.createLayerFromMetadataGeometries(noDataGeometries, true);

    // Fit map to extent of log points
    const layerExtent = layer?.getSource()?.getExtent();
    const noDataLayerExtent = noDataLayer?.getSource()?.getExtent();
    (layerExtent || noDataLayerExtent) && this.mapService.fitMapToExtent(layerExtent ?? noDataLayerExtent, true);
  }

  private createLayerFromMetadataGeometries(
    metadataGeometries: MetadataGeometry[],
    noDataLayer = false
  ): VectorLayer<VectorSource> | undefined {
    const wktFeatures = this.getFeaturesFromGeometries(metadataGeometries);
    const mapLayerId = this.getMapLayerId(metadataGeometries[0]?.type, noDataLayer);
    const oldFeatures = this.map.getFeaturesFromLayer(mapLayerId!);
    const wtkFeatureIds = new Set(wktFeatures.map((feature) => feature.getId()));

    // Check if the old and new features are the same
    const hasSameObjects = oldFeatures?.every((oldFeature) => wtkFeatureIds.has(oldFeature.getId())) && noDataLayer;

    if (wktFeatures.length === 0 && !noDataLayer) {
      this.layerService.removeLayers([LayerId.ISOXML, LayerId.ISOXML_POINTS]);
    } else if (hasSameObjects) return;

    return (this.layerService.createFeatureLayer(mapLayerId!, wktFeatures) as LayerBundle[])?.first()?.layerObjects?.first() as
      | VectorLayer<VectorSource>
      | undefined;
  }

  private getFeaturesFromGeometries(metadataGeometries: MetadataGeometry[]): Feature[] {
    return metadataGeometries.map((geom) => {
      // Convert geometry string into feature using the Well-Known Text format
      const wkt = new WKT().readFeature(geom.geometry, {
        dataProjection: MapConstants.dataProjection,
        featureProjection: MapConstants.mapProjection,
      });

      // Set relevant attributes for the feature
      wkt.setProperties({
        layerId: this.getMapLayerId(geom.type),
        color: geom.color,
        quantity: geom.quantity,
        quantityText: geom.quantityText,
        unitText: geom.unitText,
        decimals: geom.decimals,
        ddiName: geom.ddiName,
        time: geom.time,
      });

      return wkt;
    });
  }

  public getMapLayerId(geometryType: Type, noDataLayer = false) {
    switch (geometryType) {
      case 'Point':
        return noDataLayer ? LayerId.ISOXML_POINTS_NODATA : LayerId.ISOXML_POINTS;
      case 'Polygon':
        return LayerId.ISOXML;
      default:
        return;
    }
  }

  public getIsoLegendColorValues(max: number, min: number, decimals?: number): LegendColorValue[] {
    const levels = 20;
    const colors = colormap({
      colormap: 'density',
      nshades: levels,
      format: 'hex',
      alpha: 1,
    });

    // Ensure that there is at least one level even for close min and max
    const legendEntriesToCreate = Math.abs(max - min) <= 0.1 ? 1 : levels;
    const steps = legendEntriesToCreate === 1 ? 0 : (max - min) / (legendEntriesToCreate - 1);

    const legend: LegendColorValue[] = [];
    const existingValues = new Set<number>();

    for (let step = 0; step < legendEntriesToCreate; step++) {
      let value = max - steps * step;

      // Apply decimals formatting if required
      if (decimals !== undefined) {
        value = parseFloat(value.toFixed(decimals));
      }

      if (existingValues.has(value)) continue;

      legend.push({
        color: colors[step],
        value,
        customLevel: false,
      });

      existingValues.add(value);
    }

    // Sort in descending order
    return legend.sort((a, b) => b.value - a.value);
  }

  public getIsoFeatureColor(quantity: number, legend: LegendColorValue[]): string {
    // If quantity is NaN or greater than the largest value in the legend, use the last color as fallback.
    if (isNaN(quantity) || quantity < legend[legend.length - 1].value) {
      return legend[legend.length - 1].color;
    }

    // Iterate from the end of the legend array to find the closest greater or equal value.
    for (let i = legend.length - 1; i >= 0; i--) {
      if (legend[i].value >= quantity) {
        return legend[i].color;
      }
    }

    // In case the quantity is greater than any legend value, return the last legend color.
    return legend[legend.length - 1].color;
  }

  public cleanIsoXmlLayers() {
    [LayerId.ISOXML, LayerId.ISOXML_POINTS, LayerId.ISOXML_POINTS_NODATA].forEach((layerId) => {
      this.layerService.removeLayers([layerId]);
    });
  }

  public updateLegend(legendColorValues: LegendColorValue[], dataOption: DataOption, profileSelected?: boolean) {
    const formattedItems = legendColorValues.map((color) => this.formatLegendItem(color, dataOption));
    const legendScaleSettings: ScaleLegendSettings = {
      items: formattedItems,
      title: profileSelected
        ? new FindReplaceDdiNamesPipe(this.translateService).transform(`${dataOption.DDEntityName} (${dataOption.unit})`)
        : `${dataOption.DDEntityName} (${dataOption.unit})`,
      visible: true,
      unit: dataOption.unit,
      operationTypeGroupId: 99,
    };

    this.legend$.next(legendScaleSettings);
  }

  private formatLegendItem(colorValue: LegendColorValue, dataOption: DataOption): ScaleLegendItem {
    return {
      color: colorValue.color,
      text: this.decimalService.toLocaleString(colorValue.value, undefined, dataOption.numberOfDecimals ?? 0),
    };
  }
}

import { Injectable } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { LocalState } from '@app/helpers/local-state';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { filterNullish, filterNullOrEmpty } from '@app/shared/operators';
import { ScaleLegendOptions, ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { ScaleLegendService } from '@app/shared/scale-legend/service/scale-legend.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import _ from 'lodash';
import Feature from 'ol/Feature';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { YieldCropType } from './interfaces/yield-crop-type';
import { YieldPrognosis } from './interfaces/yield-prognosis';
import { YieldPrognosisRepoService } from './repository/yield-prognosis-repo.service';
import { YieldInfoDialogComponent } from './yield-prognosis-details/yield-info-dialog/yield-info-dialog.component';

@Injectable()
export class YieldPrognosisService {
  // Map paddings
  private readonly SIDE_MAP_PADDING = 50;
  private readonly TOP_BOTTOM_MAP_PADDING = 100;
  private readonly SIDEDRAWER_MAP_PADDING = 400;
  // Takes the part of the map that is hidden behind the sidedrawer into account
  private readonly NON_MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDEDRAWER_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];
  private readonly MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];

  // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private selectedFieldState = new LocalState<Feature>(null);
  public selectedFieldFeature$ = this.selectedFieldState.changes$;
  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private fieldFeatures$: Observable<FieldFeatures> = this.farmStateService.fieldFeatures$;
  // Only fields with main crops with these directorate crop norm numbers are allowed.
  private readonly allowedDirectorateCropNormNumbers = [11, 13, 216, 218];
  private readonly allowedCropTypeNumbers = [97, 98];
  private readonly allowedMaizeDirectorateCropNormNumbers = [216, 218];

  private selectableFieldFeatures$: Observable<FieldFeatures> = this.fieldFeatures$.pipe(
    filterNullOrEmpty(),
    map((fieldFeatures) => {
      return {
        ...fieldFeatures,
        fieldFeatures: fieldFeatures.fieldFeatures
          // Filters out fields without geometry or crops with lawDk
          .filter((fieldLayerItem) => !!fieldLayerItem.field!.geometry)
          .filter((fieldLayerItem) => !!fieldLayerItem.field!.crops)
          // Sort crops by successionNo, so the main crop is first.
          .map((ff) => {
            ff.field!.crops.sort((a, b) => a.successionNo - b.successionNo);
            return ff;
          })
          // Filters away results where main crop doesnt have the lawDk property.
          // LawDk contans directorateCropNormNumber, used to determine if the crop is relevant for prognosis
          .filter((fieldLayerItem) => !!fieldLayerItem.field!.crops[0])
          .filter((fieldLayerItem) => !!fieldLayerItem.field!.crops[0].lawDK)
          // filters away results where the main crops directorateCropNormNumber doesnt match any of the allowedDirectorateCropNormNumbers
          .filter((fieldLayerItem) =>
            this.allowedDirectorateCropNormNumbers.includes(fieldLayerItem.field!.crops[0].lawDK.directorateCropNormNumber)
          )
          // If the crop on the field is Maize (216, 218), filter out everything but the types in allowedCropTypeNumbers (97 and 98)
          .filter((fieldLayerItem) => {
            return !this.allowedMaizeDirectorateCropNormNumbers.includes(fieldLayerItem.field!.crops[0].lawDK.directorateCropNormNumber)
              ? true
              : this.allowedCropTypeNumbers.includes(fieldLayerItem.field!.crops[0].cropTypeNumber);
          }),
      };
    })
  );

  private _selectedDirectorateCropNormNumber!: number;

  private _legendSettingsSubject$ = new BehaviorSubject<ScaleLegendSettings | null>(null);
  public legendSettings$ = this._legendSettingsSubject$.asObservable();

  public get selectedDirectorateCropNormNumber(): number {
    return this._selectedDirectorateCropNormNumber;
  }

  public set selectedDirectorateCropNormNumber(directorateCropNormNumber: number) {
    this._selectedDirectorateCropNormNumber = directorateCropNormNumber;
  }

  constructor(
    private dialogService: DialogService,
    private yieldPrognosisRepo: YieldPrognosisRepoService,
    private _mapService: OlMapService,
    private scaleLegendService: ScaleLegendService,
    private screenSizeService: ScreenSizeService,
    private farmStateService: FarmStateService
  ) {}

  /*
  Fits the map to the currently selectable field features
   */
  public fitMapToSelectableFeatures() {
    combineLatest([this.selectableFieldFeatures$, this.screenSizeService.isMobile()])
      .pipe(take(1))
      .subscribe(([fieldFeatures, mobile]) => {
        this._mapService.fitMapToExtent(fieldFeatures.extent as number[]);
      });
  }

  /*
  The map pans and zooms to the given feature.
   */
  public fitMapToFeature(feature: Feature) {
    const extent = feature?.getGeometry()?.getExtent();

    this._mapService.fitMapToExtent(extent);
  }

  public setSelectedFieldFeature(feature: Feature) {
    this.selectedFieldState.setState(this.setFieldFeatureText(feature));
  }

  public openExpectedYieldInfoDialog() {
    return this.dialogService.openCustomDialog(YieldInfoDialogComponent, {
      maxWidth: '900px',
      data: this.getSelectedFieldCropType(),
    });
  }

  public getYieldPrognosis(field: Field, harvestYear: number): Observable<YieldPrognosis> {
    return this.yieldPrognosisRepo.getPrognosisForField(field, harvestYear);
  }

  /*
  Returns an array of CropColor elements, created from the features in yieldPrognosisFieldFeatures$
   */
  public get cropsForLegend$(): Observable<CropColor[]> {
    return this.yieldPrognosisFieldFeatures$.pipe(
      filterNullish(),
      map((features) => features.map((feature) => feature.get('field')?.crops[0])),
      map((crops) => crops.filter((x) => !!x)),

      // Create CropColor from feature. Only returns CropColors from features with crops.
      map((crops) => crops.map((crop) => ({ color: crop.cropColor, name: crop.cropName }) as CropColor)),

      // Filter out duplicates
      map((cropColors) => _(cropColors).uniqWith(_.isEqual).value())
    );
  }

  /*
  Converts and filters FieldFeatures to relevant features
   */
  public get yieldPrognosisFieldFeatures$(): Observable<Feature[]> {
    // Get all FieldFeatures (all features of fields on the maps FIELDS layer)
    return this.selectableFieldFeatures$.pipe(
      map((fieldFeatures) =>
        fieldFeatures.fieldFeatures
          // Converts the filtered FieldLayerItems to Features, to be used on the map.
          .map((fieldLayerItem) => {
            const feat = FeatureUtil.getMapFeature(
              LayerId.YIELD_PROGNOSIS,
              GeometryType.POLYGON,
              WKTUtil.getCoordinatesFromWktString(fieldLayerItem.field!.geometry!)
            );
            feat.setProperties({
              layerId: LayerId.YIELD_PROGNOSIS,
              field: fieldLayerItem.field,
              text: `${fieldLayerItem.field!.number}`,
            });
            return feat;
          })
      )
    );
  }

  /*
  Returns a feature for each cell in the given YieldPrognosis entities cells array.
   */
  public getPrognosisCellFeatures(yieldPrognosis: YieldPrognosis): Feature[] {
    return yieldPrognosis.cells.map((cell) => {
      const isMultiPolygon = cell.geometry.startsWith('MULTIPOLYGON');
      const type = isMultiPolygon ? GeometryType.MULTIPOLYGON : GeometryType.POLYGON;
      const coordinates = WKTUtil.getCoordinatesFromWktString(cell.geometry);

      const feat = FeatureUtil.getMapFeature(LayerId.VRA_PROGNOSIS, type, coordinates);

      feat.setProperties({
        layerId: LayerId.VRA_PROGNOSIS,
        color: cell.color,
        quantity: cell.quantityPrHa,
      });

      return feat;
    });
  }

  /*
  Returns a ScaleLegendSettings object, used to populate the Legend component, created from the given YieldPrognosis.
   */
  public getLegendFromPrognosis(yieldPrognosis: YieldPrognosis): ScaleLegendSettings {
    const mapCoverFlowItem: MapCoverFlowItem = {
      isVisible: true,
      mapCoverFlowLayersId: LayerId.YIELD_PROGNOSIS,
      isDisabled: false,
      displayName: 'yield-prognosis-vra',
      layers: [],
      tooltip: '',
      name: '',
    };

    const options: ScaleLegendOptions = {
      selectedAvgQuantity: yieldPrognosis.quantity / yieldPrognosis.area / 1000,
      selectedLegend: yieldPrognosis.legend,
      selectedTotalQuantity: yieldPrognosis.quantity / 1000,
    };

    const legend = this.scaleLegendService.getScaleLegendSettings(mapCoverFlowItem, options, null, 'ton');
    this._legendSettingsSubject$.next(legend);

    return legend;
  }

  /*
  Mutates the text property of the feature to contain field number and area
   */
  private setFieldFeatureText(fieldFeature: Feature): Feature {
    if (fieldFeature) {
      fieldFeature.setProperties({ text: this.getFieldFeatureTextFromField(fieldFeature.get('field')) });
    }
    return fieldFeature;
  }

  /*
  Returns a styled string containing the given fields number and area
   */
  private getFieldFeatureTextFromField(field: Field): string {
    return `${field.number}\n${field.area} ha`;
  }

  private getSelectedFieldCropType(): Observable<YieldCropType> {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.selectedFieldFeature$.pipe(
      map((feature) => {
        const directorateCropNormNumber = feature.get('field').crops[0]?.lawDK?.directorateCropNormNumber;
        switch (directorateCropNormNumber) {
          case 11:
          case 13:
            return 'wheat';
          case 216:
          case 218:
            return 'maize';
          default:
            return;
        }
      })
    );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { LoadingState } from '@app/helpers/loading-state';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { NdviStateService } from '@app/state/services/ndvi/ndvi-state.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { FieldAnalysisSideDrawerService } from '../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { SatelliteImageDate } from './interfaces/ndvi-image-date.interface';
import { ClearSkyTileType } from './ndvi-feature.component';
import { NdviService } from './service/ndvi.service';

@Component({
  selector: 'app-ndvi',
  templateUrl: './ndvi.component.html',
  styleUrls: ['./ndvi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdviComponent implements OnInit, OnDestroy {
  public readonly selectedFarms$ = this.farmStateService.selectedFarms$;
  public readonly harvestYear$ = this.harvestYearStateService.harvestYear$;

  private subscriptions: Subscription[] = [];

  // TODO: Remove when naesgaard has access to cloudless
  protected showImageSelectorButtons$ = this.accessControlService
    .hasAccessTo('naesgaard_specific_information')
    .pipe(map((hasAccess) => !hasAccess));

  protected buttonColorCode = '#007828';
  protected buttonColorCodeNotSelected = '#c8c8c8';
  protected textColorCodeNotSelected = '#000000';
  protected imagesFromClearSky$ = this.ndviStateService.imagesFromClearSky$;
  protected clearSkyTileTypes = [
    {
      type: ClearSkyTileType.ClearSkyNDVI,
      translationName: 'mapFieldInspectorItem.ndviInfo.ndviName',
    },
    {
      type: ClearSkyTileType.ClearSkyNDRE,
      translationName: 'mapFieldInspectorItem.ndviInfo.ndreName',
    },
    {
      type: ClearSkyTileType.ClearSkyRGB,
      translationName: 'mapFieldInspectorItem.ndviInfo.rgbName',
    },
  ];
  protected selectedTileType$ = this.ndviStateService.selectedTileType$;

  public ndviImageDatesSubscription = new Subscription();
  public ndviImageDates: SatelliteImageDate[] | null | undefined = [];
  public ndviImageDatesMap!: { [key: string]: SatelliteImageDate };
  public cloudCoveragePct = 100;
  public maxDate = new Date();
  public minDate = DateTime.fromObject({ year: 2017, month: Month.January, day: 1 }).toJSDate();
  public startDate = new Date();
  public loadingState = new LoadingState();

  public headerTranslateKey$ = this.accessControlService
    .hasAccessTo('field_analysis_biomass_benchmark')
    .pipe(
      map((hasAccessToBiomassBenchmark) =>
        hasAccessToBiomassBenchmark ? 'mapFieldInspectorItem.ndviInfo.title' : 'mapFieldInspectorItem.ndviInfo.titleNoBenchmark'
      )
    );

  constructor(
    private ndviService: NdviService,
    private ndviStateService: NdviStateService,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private accessControlService: AccessControlService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private layerService: OlLayerService
  ) {}

  public ngOnInit() {
    this.showImageSelectorButtons$.pipe(first()).subscribe((showImageSelectorButtons) => {
      if (!showImageSelectorButtons) {
        this.ndviStateService.imagesFromClearSky = false;
      }
    });

    this.subscriptions.push(
      this.ndviStateService.sateliteImageDate$.pipe(filterNullish()).subscribe((date) => {
        this.startDate = date.toJSDate();
        this.changeDetectorRef.detectChanges();
      })
    );

    this.subscriptions.push(
      this.ndviStateService.imagesFromClearSky$.subscribe((imagesFromClearSky) => {
        if (imagesFromClearSky) {
          this.getClearSkyDates();
        } else {
          this.getNDVIImageDates();
        }
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.layerService.updateLayerBundles(this.layerService.createCommonBiomassLayer());
  }

  public onNdviImageDatesChange(ndviImageDates: SatelliteImageDate[] | null) {
    if (!ndviImageDates || ndviImageDates.length === 0) return;
    this.ndviImageDates = ndviImageDates;

    const ndviImageDatesObj: { [key: string]: SatelliteImageDate } = {};

    ndviImageDates?.forEach((img) => {
      ndviImageDatesObj[img.date.toFormat('dd-MM-yyyy')] = img;
    });

    this.ndviImageDatesMap = ndviImageDatesObj;
    this.ndviStateService.sateliteImageDate = ndviImageDates[ndviImageDates.length - 1].date;
    this.ndviStateService.ndviImageDates = ndviImageDates;
    this.changeDetectorRef.detectChanges();
  }

  public onDateSelect(date: Date) {
    this.startDate = date;

    this.ndviStateService.sateliteImageDate = DateTime.fromJSDate(date); // PREVIOUSLY A STRING WITH FORMAT: 'yyyy-MM-dd' (Luxon formatting)
  }

  public disabledDates = (date: Date) => {
    return this.ndviImageDatesMap ? !this.ndviImageDatesMap[DateTime.fromJSDate(date).toFormat('dd-MM-yyyy')] : false;
  };

  public farmsTrackByFn(index: number) {
    return index;
  }

  protected onSatelliteClicked() {
    this.ndviStateService.imagesFromClearSky = false;
  }

  protected onClearSkyClicked() {
    this.ndviStateService.imagesFromClearSky = true;
  }

  protected selectTilType($event: ClearSkyTileType) {
    if (!$event) return;
    this.ndviStateService.selectedTileType = $event;
  }

  private getClearSkyDates() {
    this.ndviImageDatesSubscription.unsubscribe();
    this.ndviStateService.sateliteImageDate = null;
    this.loadingState.start('common.loadingData');

    return this.selectedFarms$
      .pipe(
        first(),
        switchMap((farms) => {
          return this.ndviService.getClearSkyImageDates(farms);
        }),
        finalize(() => this.loadingState.stop())
      )
      .subscribe((ndviImageDates) => {
        return this.onNdviImageDatesChange(ndviImageDates);
      });
  }

  private getNDVIImageDates() {
    this.ndviImageDatesSubscription.unsubscribe();
    this.ndviStateService.sateliteImageDate = null;
    this.loadingState.start('common.loadingData');

    return this.selectedFarms$
      .pipe(
        first(),
        switchMap((farms) => {
          return this.ndviService.getNDVIImageDates(farms);
        }),
        finalize(() => this.loadingState.stop())
      )
      .subscribe((ndviImageDates) => {
        return this.onNdviImageDatesChange(ndviImageDates);
      });
  }
}

export const urls_dev = {
  dataExchangeApi: 'https://dev-dataexchangegateway.vfltest.dk/api/v1/',
  agrirouterManagerApi: '/api/ar/',
  foApi: '/api/fo/',
  weatherDataApi: '/api/wd/',
  climateModelsApi: '/api/cm/',
  ftApi: '/api/ft/',
  machineApi: '/api/ma/',
  elasticSearchForward: '/api/es/',
  dataforsyningApi: '/api/df/',
  azureMapsApi: '/api/am/',
  bffCnhApi: '/api/cnh/',
  cropmanagerApi: '/api/cmapi/',
  cnhApi: 'https://dev-gateway-cnh.segestest.dk/api',
  customerCentre: 'https://kundecenter.seges.dk/',
  delegation: 'https://si-www-delegation.vfltest.dk/',
  userProfile: 'https://si-myprofile.agroidwebsites.dk/',
  unleashUrl: 'https://unleash-dev-proxy-wa.segestest.dk/',
  notAuthenticatedRedirectUrl: '/#/login',
};

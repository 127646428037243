import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Farm } from '@app/core/interfaces/farm.interface';
import { SoilSampleGroup } from '@app/core/repositories/soil-samples/soil-sample-group.class';
import { SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapControlSoilsampleService } from '@app/shared/map-layer-controls/map-layer-control-soilsample/map-control-soilsample.service';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { orderBy } from 'lodash-es';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { FieldAnalysisShownComponentEnum } from '../../../field-analysis-side-drawer/field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerContentComponent } from '../../../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from '../../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { SoilSampleShortName } from './soil-sample-short-name.enum';
import { SoilSampleSideDrawerService } from './soil-sample-side-drawer.service';
import { SoilSampleParameterIds } from './soil-samples-analysis-parameter-ids.enum';

export interface MeasurementNumbers {
  reaction: number;
  potassium: number;
  magnesium: number;
  phosphor: number;
  boron: number;
  copper: number;
  humus: number;
  clayPct: number;
  texture: number;
  nitrogen: number;
  carbon: number;
  orgCarbon: number;
}

@Component({
  selector: 'app-soil-sample-side-drawer',
  templateUrl: './soil-sample-side-drawer.component.html',
  styleUrls: ['./soil-sample-side-drawer.component.scss'],
  providers: [SoilSampleSideDrawerService],
})
export class SoilSampleSideDrawerComponent implements OnInit, OnDestroy {
  private _soilSampleGroups: SoilSampleGroup[] = [];
  private subscriptions: Subscription[] = [];

  public selectedSoilSampleControl = new FormControl<SoilSampleShortName>(SoilSampleShortName.Reaction);

  public sampleDateControl = new UntypedFormControl();

  public isLoadingGroups = false;
  public isLoadingSamples = false;
  public isLegendLoading = false;

  public measurementNumbers$ = new BehaviorSubject<MeasurementNumbers>({
    reaction: 0,
    potassium: 0,
    magnesium: 0,
    phosphor: 0,
    boron: 0,
    copper: 0,
    humus: 0,
    clayPct: 0,
    texture: 0,
    nitrogen: 0,
    carbon: 0,
    orgCarbon: 0,
  });

  constructor(
    private farmStateService: FarmStateService,
    private soilSampleSideDrawerService: SoilSampleSideDrawerService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private changeDetectorRef: ChangeDetectorRef,
    private soilSampleService: MapControlSoilsampleService,
    private layerService: OlLayerService
  ) {}

  public ngOnInit() {
    this.layerService.setActivePage('soil-sample');
    this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, false);
    this.soilSampleService.reset();

    this.subscriptions.push(
      this.farmStateService.selectedFarms$
        .pipe(
          filterNullish(),
          filter((farms) => !!farms.length),
          distinctUntilChanged((x, y) => isEqual(x, y))
        )
        .subscribe((farms) => this.onSelectedFarmsChange(farms)),

      this.sampleDateControl.valueChanges
        .pipe(
          tap((group) => {
            this.soilSampleService.setSelectedSampleGroups(group);
            this.soilSampleService.getSoilSamples();

            if (this.selectedSoilSampleControl.value === SoilSampleShortName.Reaction) {
              this.selectedSoilSampleControl.setValue(SoilSampleShortName.Reaction);
            }

            if (group.length < 1) {
              this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, false);
              this.onSampleDateGroupChange([]);
              this.soilSampleService.reset();
            }
          }),
          filterNullOrEmpty(),
          distinctUntilChanged(),
          switchMap((group) => this.getSoilSamples(group)),
          filterNullish()
        )
        .subscribe((soilSamples: SoilSampleDTO[]) => this.onSampleDateGroupChange(soilSamples)),

      this.selectedSoilSampleControl.valueChanges.pipe(filterNullish()).subscribe((val) => {
        this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, true);
        this.soilSampleService.setSelectedType(val);
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.onCloseClick();
    this.changeDetectorRef.detectChanges();
    this.soilSampleService.reset();
  }

  public onSampleDateGroupChange(soilSamples: SoilSampleDTO[]) {
    this.measurementNumbers$.next(this.soilSampleSideDrawerService.getNumberOfMeasurements(soilSamples));

    if (this.getValueFromSelectedMeasurementShortName(this.selectedSoilSampleControl.value!) === 0) {
      this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, false);
    } else {
      this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, true);
    }

    this.soilSampleService.setSelectedSampleGroups(this.sampleDateControl.value);
    this.soilSampleService.setSelectedType(this.selectedSoilSampleControl.value || SoilSampleParameterIds.None);
  }

  public set soilSampleGroups(soilSampleGroups: SoilSampleGroup[]) {
    const newestSamplesFirst = orderBy(soilSampleGroups, (g) => g.date, 'desc');
    this._soilSampleGroups = newestSamplesFirst;
    setTimeout(() => {
      if (this.soilSampleGroups.length === 1) {
        this.soilSampleService.setSelectedSampleGroups([this.soilSampleGroups[0]]);
        this.sampleDateControl.setValue([this.soilSampleGroups[0]]);
      }
    });
  }

  public get soilSampleGroups() {
    return this._soilSampleGroups.sort((a, b) => (a.year && b.year && a.year > b.year ? -1 : 1));
  }

  public onCloseClick() {
    this.fieldAnalysisSideDrawerService.clearFieldAnalysisMapLayeridxQueryParam();
    this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
  }

  public onHideClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  private onSelectedFarmsChange(farms: Farm[]) {
    this.getAndSetSoilSampleDateGroups(farms);
  }

  private getAndSetSoilSampleDateGroups(farms: Farm[]) {
    this.isLoadingGroups = true;
    this.soilSampleSideDrawerService
      .getSoilSampleGroups(farms)
      .pipe(
        finalize(() => {
          this.isLoadingGroups = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((soilSampleDateGroups) => (this.soilSampleGroups = soilSampleDateGroups));
  }

  private getSoilSamples(group: SoilSampleGroup[]) {
    const dates = group.map((g) => g.date);
    this.isLoadingSamples = true;
    return this.farmStateService.selectedFarms$.pipe(
      take(1),
      switchMap((farms) => {
        return this.soilSampleSideDrawerService.getSoilSamples(farms, dates);
      }),
      finalize(() => {
        this.isLoadingSamples = false;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private getValueFromSelectedMeasurementShortName(shortName: SoilSampleShortName) {
    switch (shortName) {
      case SoilSampleShortName.Reaction:
        return this.measurementNumbers$.value.reaction;
      case SoilSampleShortName.Potassium:
        return this.measurementNumbers$.value.potassium;
      case SoilSampleShortName.Magnesium:
        return this.measurementNumbers$.value.magnesium;
      case SoilSampleShortName.Phosphor:
        return this.measurementNumbers$.value.phosphor;
      case SoilSampleShortName.Boron:
        return this.measurementNumbers$.value.boron;
      case SoilSampleShortName.Copper:
        return this.measurementNumbers$.value.copper;
      case SoilSampleShortName.Humus:
        return this.measurementNumbers$.value.humus;
      case SoilSampleShortName.ClayPercentage:
        return this.measurementNumbers$.value.clayPct;
      case SoilSampleShortName.SoilType:
        return this.measurementNumbers$.value.texture;
      default:
        return 0;
    }
  }
}

import { WebGLStyle } from 'ol/style/webgl';

export const IsoXMLStyles = {
  getPointsStyle: (): WebGLStyle => {
    return {
      'circle-radius': 10,
      'circle-fill-color': ['color', ['get', 'red'], ['get', 'green'], ['get', 'blue']],
      'circle-opacity': ['get', 'alpha'] ?? 1,
    };
  },

  getNoDataPointsStyle: (): WebGLStyle => {
    return {
      'circle-radius': 10,
      'circle-fill-color': ['color', ['get', 'red'], ['get', 'green'], ['get', 'blue']],
      'circle-opacity': 0.25,
    };
  },
};

<app-side-drawer-wrapper>
  <app-side-drawer-header
    (action)="onCloseClick()"
    [showMinimizeButton]="true"
    cancelIconName="back"
    (onHide)="onHideClick()"
    title="{{ 'main.fieldmap.layers.soilSamples' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <div class="content-wrapper">
      <app-spinner
        *ngIf="isLoadingGroups || isLoadingSamples || isLegendLoading"
        [hasBackdrop]="true"
        [message]="'main.soilSamples.loading' | translate"
      ></app-spinner>
      <div class="soil-sample-date-group-select">
        <app-select [formControl]="sampleDateControl" [multiple]="true" placeholder="{{ 'main.soilSamples.soilSamples' | translate }}">
          <app-select-option [value]="group" *ngFor="let group of soilSampleGroups">
            {{ group.dateString }}
            ({{ group.numberOfSamples }} {{ 'main.soilSamples.samples' | translate }})</app-select-option
          >
        </app-select>
      </div>
      
      <h4 class="soil-sample-title">{{ 'main.soilSamples.showSoilSamplesFor' | translate }}:</h4>

      <div class="soil-radios" *ngIf="measurementNumbers$ | async as measurementNumbers">
        <mat-radio-group [formControl]="selectedSoilSampleControl">
          <app-radio-button value="Rt" labelPosition="after" [disabled]="!measurementNumbers.reaction">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.reactionNumber' | translate"
              [measurements]="measurementNumbers.reaction"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Pt" labelPosition="after" [disabled]="!measurementNumbers.phosphor">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.phosphorNumber' | translate"
              [measurements]="measurementNumbers.phosphor"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Kt" labelPosition="after" [disabled]="!measurementNumbers.potassium">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.potassiumNumber' | translate"
              [measurements]="measurementNumbers.potassium"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Mgt" labelPosition="after" [disabled]="!measurementNumbers.magnesium">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.magnesiumNumber' | translate"
              [measurements]="measurementNumbers.magnesium"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Cut" labelPosition="after" [disabled]="!measurementNumbers.copper">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.copperNumber' | translate"
              [measurements]="measurementNumbers.copper"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Jb" labelPosition="after" [disabled]="!measurementNumbers.texture">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.soilType' | translate"
              [measurements]="measurementNumbers.texture"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Bt" labelPosition="after" [disabled]="!measurementNumbers.boron">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.boronNumber' | translate"
              [measurements]="measurementNumbers.boron"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Humus" labelPosition="after" [disabled]="!measurementNumbers.humus">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.humus' | translate"
              [measurements]="measurementNumbers.humus"
            ></app-soil-sample-radio-label>
          </app-radio-button>
          <app-radio-button value="Ler%" labelPosition="after" [disabled]="!measurementNumbers.clayPct">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.clayPercentage' | translate"
              [measurements]="measurementNumbers.clayPct"
            ></app-soil-sample-radio-label>
          </app-radio-button>

          <app-radio-button value="N%" labelPosition="after" [disabled]="!measurementNumbers.nitrogen">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.totalNitrogen' | translate"
              [measurements]="measurementNumbers.nitrogen"
            ></app-soil-sample-radio-label>
          </app-radio-button>

          <app-radio-button value="C%" labelPosition="after" [disabled]="!measurementNumbers.carbon">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.totalCarbon' | translate"
              [measurements]="measurementNumbers.carbon"
            ></app-soil-sample-radio-label>
          </app-radio-button>

          <app-radio-button value="OrgC" labelPosition="after" [disabled]="!measurementNumbers.orgCarbon">
            <app-soil-sample-radio-label
              [text]="'main.soilSamples.results.organicCarbon' | translate"
              [measurements]="measurementNumbers.orgCarbon"
            ></app-soil-sample-radio-label>
          </app-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

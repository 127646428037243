import { Injectable, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { SoilSampleLegend } from '@app/core/repositories/soil-samples/soil-sample-legend.class';
import { LocalState } from '@app/helpers/local-state';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { fieldAnalysisMapLayerIdxQueryParamKey } from '../features/field-analysis-features.component';
import { FieldAnalysisFeaturesActionsService } from '../features/selector/field-analysis-features.actions.service';
import { FieldAnalysisShownComponentEnum } from './field-analysis-shown-component.enum';

@Injectable({
  providedIn: 'root',
})
export class FieldAnalysisSideDrawerService {
  private shownComponentState = new LocalState<{ shownComponent: FieldAnalysisShownComponentEnum }>({
    shownComponent: FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent,
  });
  private legendSettingsState = new LocalState<{ legendSettings: ScaleLegendSettings } | null>(null);
  private soilSampleLegendState = new LocalState<{ soilSampleLegend: SoilSampleLegend } | null>(null);
  private drawerWidthSubject = new ReplaySubject<string>(1);
  private _sideDrawerFeatureContentRef!: TemplateRef<any>;

  public drawerWidth$: Observable<string>;
  public legendNotExcluded$ = new BehaviorSubject<boolean>(true);
  public shownComponentState$ = this.shownComponentState.changes$;
  public legendSettings$ = this.legendSettingsState.changes$;
  public soilSampleLegend$ = this.soilSampleLegendState.changes$;

  constructor(
    private queryParamService: QueryParamService,
    private fieldAnalysisFeaturesActionsService: FieldAnalysisFeaturesActionsService,
    private mapControlService: MapLayerControlService
  ) {
    this.drawerWidth$ = this.drawerWidthSubject.asObservable();

    this.drawerWidth$.pipe(takeUntilDestroyed()).subscribe((width) => {
      this.mapControlService.setSidedrawerWidth(width === '360px' ? 360 : 0);
    });
  }

  public setShownComponentState(component: FieldAnalysisShownComponentEnum) {
    this.shownComponentState.setState({ shownComponent: component });
  }

  public setLegendSettingsState(legendSettings: ScaleLegendSettings) {
    // Promise resolve so angular detects changes on next microtask to prevent expression changed error.
    Promise.resolve()
      .then(() => this.legendSettingsState.setState({ legendSettings: legendSettings }))
      .catch((err) => console.error(err));
  }

  public setSoilSampleLegendState(soilSampleLegend: SoilSampleLegend) {
    Promise.resolve()
      .then(() => this.soilSampleLegendState.setState({ soilSampleLegend: soilSampleLegend }))
      .catch((err) => console.error(err));
  }

  public setExcludedLegendState(shouldBeExcluded: boolean) {
    this.legendNotExcluded$.next(!shouldBeExcluded);
  }

  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }

  public get sideDrawerFeatureContentRef() {
    return this._sideDrawerFeatureContentRef;
  }

  public set sideDrawerFeatureContentRef(ref: TemplateRef<any>) {
    this._sideDrawerFeatureContentRef = ref;
  }

  public changeSideDrawerContent(selectedMapFeatureId: LayerId) {
    switch (selectedMapFeatureId) {
      case LayerId.BIOMASS:
        this.setShownComponentState(FieldAnalysisShownComponentEnum.NdviFeatureComponent);
        break;
      case LayerId.SOILSAMPLE:
        this.setShownComponentState(FieldAnalysisShownComponentEnum.SoilSampleMapFeatureComponent);
        break;
      case LayerId.AS_APPLIED:
        this.setShownComponentState(FieldAnalysisShownComponentEnum.AsAppliedComponent);
        break;
      default:
        break;
    }
  }

  public clearFieldAnalysisMapLayeridxQueryParam() {
    this.fieldAnalysisFeaturesActionsService.selectedMapCoverFlowItemIdxChanged(null);
    this.queryParamService.removeQueryParam(fieldAnalysisMapLayerIdxQueryParamKey);
  }
}

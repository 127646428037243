import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { FieldAnalysisSideDrawerService } from '@app/new-map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { OlMapComponent } from '@app/new-map/ol-map/ol-map.component';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { NdviStateService } from '@app/state/services/ndvi/ndvi-state.service';
import { DateTime } from 'luxon';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { SelectEvent } from 'ol/interaction/Select';
import { Subscription, combineLatest } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FieldAnalysisShownComponentEnum } from '../../field-analysis-side-drawer/field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerContentComponent } from '../../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { NdviFeatureService } from './ndvi-feature.service';
import { NdviService } from './service/ndvi.service';

export enum ClearSkyTileType {
  ClearSkyNDVI = 13,
  ClearSkyNDRE = 14,
  ClearSkyRGB = 15,
}

@Component({
  selector: 'app-ndvi-feature',
  templateUrl: './ndvi-feature.component.html',
  styleUrls: ['./ndvi-feature.component.scss'],
  providers: [NdviFeatureService],
})
export class NdviFeatureComponent implements OnInit, OnDestroy {
  @Output() public isLoadingChange: EventEmitter<boolean> = new EventEmitter();
  private ndviDates?: DateTime[] | null;

  private subscriptions: Subscription[] = [];
  public readonly selectableLayers: LayerId[] = [LayerId.FIELDS];
  public isFeatureSelectEnabled = true;

  public get map(): OlMapComponent {
    return this.mapService.mapComponent;
  }

  constructor(
    private ndviService: NdviService,
    private mapService: OlMapService,
    private ndviStateService: NdviStateService,
    private ndviFeatureService: NdviFeatureService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>
  ) {}

  public ngOnInit(): void {
    this.mapService.mapReady$
      .pipe(
        filter((isReady) => isReady),
        first()
      )
      .subscribe(() => {
        setTimeout(() => {
          this.initSubscriptions();
          this.adjustNdviDateYearToHarvestyearSubscription();
        });
      });

    this.mapService.setNewActivePage('biomass');
  }

  public onCloseClick() {
    this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
  }

  public onHideClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  private initSubscriptions(): void {
    this.setupFieldFeaturesSubscriptions();
    this.setupUpdateRedEdgeNDVISubscriptions();
  }

  public ngOnDestroy(): void {
    this.fieldAnalysisSideDrawerService.clearFieldAnalysisMapLayeridxQueryParam();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.mapService.deselectFeatures();

    this.updateGrowthCurve(null, null);
  }

  public onGrowthCurveFieldSelect(features: Feature[]) {
    if (features.length) {
      const ndviHistory = features[0].get('ndviHistory');
      const field = features[0].get('field');

      this.updateGrowthCurve(ndviHistory, field);
    }
  }

  private updateGrowthCurve(ndviHistory: NdviHistory | null, field: Field | null) {
    this.ndviStateService.selectedNdviHistory = ndviHistory;
    this.ndviStateService.selectedNdviField = field;
  }

  public onFeatureSelected(event: SelectEvent): void {
    if (event.selected.length) {
      const selectedFeature = event.selected[0];
      const isGroupFeature = selectedFeature.get('features') !== undefined;

      this.onGrowthCurveFieldSelect(isGroupFeature ? selectedFeature.get('features') : [selectedFeature]);
    } else if (event.deselected.length) {
      this.updateGrowthCurve(null, null);
    }
  }

  private setupFieldFeaturesSubscriptions() {
    this.subscriptions.push(
      combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$])
        .pipe(
          filter(([farms, harvestYear]) => !!farms && !!farms.length && !!harvestYear),
          tap(() => this.isLoadingChange.next(true)),
          switchMap(([farms, harvestYear]) =>
            combineLatest([this.farmStateService.fieldFeatures$, this.ndviFeatureService.getNDVIHistories(farms, harvestYear!)])
          ),
          filter(([featureModels, ndviHistories]) => !!featureModels)
        )
        .subscribe(([featureModels, ndviHistories]) => this.onFieldFeaturesChange(featureModels!.fieldFeatures, ndviHistories, [0, 0]))
    );
  }

  private adjustNdviDateYearToHarvestyearSubscription() {
    this.subscriptions.push(
      this.harvestYearStateService.harvestYear$
        .pipe(
          filter(() => !!this.ndviDates && this.ndviDates.length > 0),
          map((harvestYear) => {
            const ndviDatesFromYear = this.ndviDates?.filter((date) => {
              return date.year === harvestYear;
            });

            return ndviDatesFromYear;
          }),
          filterNullish()
        )
        .subscribe((ndviDatesFromYear) => {
          if (ndviDatesFromYear.length === 0) {
            return;
          }

          this.ndviStateService.sateliteImageDate = DateTime.max(...ndviDatesFromYear);
        })
    );
  }

  private setupUpdateRedEdgeNDVISubscriptions() {
    this.subscriptions.push(
      combineLatest([this.ndviStateService.sateliteImageDate$, this.ndviStateService.selectedTileType$])
        .pipe(filter(([fieldFeatures, date]) => !!fieldFeatures && !!date))
        .pipe(
          withLatestFrom(
            this.farmStateService.selectedFarms$,
            this.harvestYearStateService.harvestYear$,
            this.farmStateService.fieldFeatures$,
            this.ndviStateService.imagesFromClearSky$
          )
        )
        .subscribe(([[date, selectedTileType], farms, harvestYear, fieldFeatures, imagesFromClearSky]) => {
          if (date && fieldFeatures) {
            if (imagesFromClearSky) {
              this.ndviFeatureService.addClearSkyImagesToMap(farms, harvestYear!, date, fieldFeatures, selectedTileType);
            } else {
              this.ndviFeatureService.addNDVIImagesToMap(farms, harvestYear!, date, fieldFeatures);
            }
            this.fieldAnalysisSideDrawerService.setLegendSettingsState(this.ndviFeatureService.getLegend(date));
          }
        })
    );
  }

  private onFieldFeaturesChange(fieldFeatures: FieldLayerItem[], ndviHistories: NdviHistory[], clickedCoords: Coordinate) {
    const growthCurveFieldFeatures = this.ndviFeatureService.mapNdviHistoriesToFeatures(fieldFeatures, ndviHistories);

    this.isFeatureSelectEnabled = false;
    this.isFeatureSelectEnabled = true;

    this.ndviFeatureService.setFeatureForClickedCoords(clickedCoords, growthCurveFieldFeatures);

    this.ndviStateService.selectedNdviField = null;

    this.ndviStateService.selectedNdviHistory = null;

    this.isLoadingChange.next(false);
  }
}

import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { NdviRepo } from '@app/core/ndvis/ndvis-repo.service';
import { MethodTypes } from '@app/method-types.enum';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { ScaleLegendOptions } from '@app/shared/scale-legend/scale-legend-options.interface';
import { ScaleLegendService } from '@app/shared/scale-legend/service/scale-legend.service';
import { NdviStateService } from '@app/state/services/ndvi/ndvi-state.service';
import { DateTime } from 'luxon';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClearSkyTileType } from './ndvi-feature.component';
import { NdviService } from './service/ndvi.service';

@Injectable()
export class NdviFeatureService {
  constructor(
    private mapService: OlMapService,
    private ndviRepo: NdviRepo,
    private ndviService: NdviService,
    private scaleLegendService: ScaleLegendService,
    private ndviStateService: NdviStateService,
    private accessControlService: AccessControlService,
    private endpoints: EndpointsService,
    private layerService: OlLayerService
  ) {}

  /**
   * Returns an observable of an array of NDVI history objects, filtered by farms and harvest year.
   * @param farms An array of farms to filter by.
   * @param harvestYear The harvest year to filter by.
   * @returns An observable of an array of NDVI history objects.
   */
  public getNDVIHistories(farms: Farm[], harvestYear: number): Observable<NdviHistory[]> {
    // Check if user has access to biomass benchmark
    return this.accessControlService.hasAccessTo('field_analysis_biomass_benchmark').pipe(
      switchMap((hasAccessToBiomassBenchmark) => {
        if (hasAccessToBiomassBenchmark) {
          // If user has access, get NDVI history for specified farms and harvest year
          return this.ndviRepo
            .getNDVIHistory(
              farms.map((farm) => farm.id),
              harvestYear
            )
            .pipe(map((ndviHistories) => ndviHistories.ndviHistories));
        } else {
          // If user doesn't have access, disable clickable fields and return empty array
          this.mapService.disableSelectInteraction();
          return of([] as NdviHistory[]);
        }
      })
    );
  }

  public getLegend(date: DateTime) {
    const options: ScaleLegendOptions = {
      selectedNdviDate: date,
    };
    return this.scaleLegendService.getScaleLegendSettings(this.ndviService.getNdviMapCoverFlowItem(), options);
  }

  public addClearSkyImagesToMap(
    farms: Farm[],
    harvestYear: number,
    date: DateTime,
    fieldFeatures: FieldFeatures,
    clearSkyTileType: ClearSkyTileType | null
  ) {
    if (!clearSkyTileType) return;
    const url = this.getClearSkyNdviUrl(harvestYear, farms, date, clearSkyTileType);
    const redEdgeLayerSettings = MapCoverFlowItem.findLayerSettingsForItem(this.ndviService.getNdviMapCoverFlowItem(), LayerId.BIOMASS);
    if (fieldFeatures.fieldFeatures.length === 0) {
      this.layerService.removeLayers([LayerId.BIOMASS]);
      return;
    }
    let farmIds: (number | undefined)[] = new Array(farms.length);
    farms.forEach((farm, index) => {
      farmIds[index] = farm.id;
    });

    const postBody = fieldFeatures.fieldFeatures.map((ff) => ff.field?.geometry);

    if (!redEdgeLayerSettings) return;

    redEdgeLayerSettings.requestBody = postBody;
    redEdgeLayerSettings.url = url;
    redEdgeLayerSettings.method = MethodTypes.POST;

    this.layerService.createBiomassAnalysisLayer(redEdgeLayerSettings);
  }

  public addNDVIImagesToMap(farms: Farm[], harvestYear: number, date: DateTime, fieldFeatures: FieldFeatures) {
    const url = this.getRedEdgeNdviUrl(farms, harvestYear, date);
    const redEdgeLayerSettings = MapCoverFlowItem.findLayerSettingsForItem(this.ndviService.getNdviMapCoverFlowItem(), LayerId.BIOMASS);
    if (fieldFeatures.fieldFeatures.length === 0) {
      this.layerService.removeLayers([LayerId.BIOMASS]);
      return;
    }
    var fieldGeometry: (string | undefined)[] = new Array(fieldFeatures.fieldFeatures.length);
    fieldFeatures.fieldFeatures.forEach((fieldFeature, index) => {
      fieldGeometry[index] = fieldFeature.field!.geometry;
    });

    const postBody = fieldGeometry;

    if (!redEdgeLayerSettings) return;

    redEdgeLayerSettings.requestBody = postBody;
    redEdgeLayerSettings.url = url;
    redEdgeLayerSettings.method = MethodTypes.POST;
    redEdgeLayerSettings.zIndex = 1;

    this.layerService.createBiomassAnalysisLayer(redEdgeLayerSettings);
  }

  public mapNdviHistoriesToFeatures(fieldFeatures: FieldLayerItem[], ndviHistories: NdviHistory[]) {
    return fieldFeatures
      .map((fieldFeature: FieldLayerItem) => {
        const ndviHistory = ndviHistories.find((history) => history.featureId === fieldFeature.featureId);
        const feature = this.addNdviHistoryToFieldFeature(fieldFeature, ndviHistory);
        return feature;
      })
      .filter((feature) => !!feature);
  }

  public setFeatureForClickedCoords(clickedCoords: Coordinate, features: Feature[]) {
    // if the user has already selected a field we need to update the selected ndvi history.
    if (clickedCoords) {
      for (const feature of features) {
        // not an ideal way of finding the field but it's the easiest and most precise at the moment.
        const ndviHistory: NdviHistory = feature.get('ndviHistory');
        const geom = feature.getGeometry();

        if (geom && geom.intersectsCoordinate(clickedCoords)) {
          this.ndviStateService.selectedNdviHistory = ndviHistory;
          break;
        }
      }
    }
  }

  private addNdviHistoryToFieldFeature(featureModel: FieldLayerItem, ndviHistory?: NdviHistory) {
    const fieldFeatures = this.layerService.getFeaturesFromLayer(LayerId.FIELDS);

    const fieldFeature = fieldFeatures.find((ff) => ff.get('field')?.id === featureModel.fieldId);
    if (!fieldFeature) return;

    fieldFeature.set('ndviHistory', ndviHistory);
    return fieldFeature;
  }

  private getClearSkyNdviUrl(harvestYear: number, farms: Farm[], sateliteImageDate: DateTime, clearSkyTileType: ClearSkyTileType) {
    const baseUrl = this.buildLayerUrl('clearsky');

    const getFormatedImageDate = () => sateliteImageDate.toFormat('yyyy-LL-dd');
    const sateliteImageDateQueryParam = sateliteImageDate ? `&sateliteImageDate=${getFormatedImageDate()}` : '';
    const farmIds = farms.map((f) => f.id).join(',');

    const params = `?farmIds=${farmIds}&harvestYear=${harvestYear}` + `${sateliteImageDateQueryParam}&tileType=${clearSkyTileType}`;
    return baseUrl + params;
  }

  private getRedEdgeNdviUrl(farms: Farm[], harvestYear: number, sateliteImageDate: DateTime): string | undefined {
    const baseUrl = this.buildLayerUrl('rededgendvi');

    if (!farms || farms.length === 0) return;

    const maxCloudCoveragePct = 20;
    const farmIds = farms.map((f) => f.id).join(',');

    const getFormatedImageDate = () => sateliteImageDate.toFormat('yyyy-LL-dd');
    const sateliteImageDateQueryParam = sateliteImageDate ? `&satelliteImageDate=${getFormatedImageDate()}` : '';

    const params =
      `?farmIds=${farmIds}&harvestYear=${harvestYear}` + `&maxCloudCoveragePct=${maxCloudCoveragePct}${sateliteImageDateQueryParam}`;
    return baseUrl + params;
  }

  private buildLayerUrl(type: string, projectId?: number): string {
    switch (type) {
      case 'rededgendvi':
        return `${this.endpoints.foApi}/maps/overlays/rededgendvi/{z}/{x}/{y}`;
      case 'clearsky':
        return `${this.endpoints.foApi}/maps/clearsky/gettiles/{z}/{x}/{y}/fields`;
      default:
        return `${this.endpoints.foApi}/maps/overlays/${type}/{z}/{x}/{y}`;
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { AppUpdaterService } from '@app/core/updater/app-updater.service';
import { NavigationComponent } from '@app/layouts/navigation/navigation.component';
import { NavigationService } from '@app/layouts/navigation/navigation.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FieldAnalysisSideDrawerContentComponent } from '../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from '../field-analysis-side-drawer/field-analysis-side-drawer.service';

@Component({
  selector: 'app-field-analysis-feature-picker',
  templateUrl: './field-analysis-feature-picker.component.html',
  styleUrls: ['./field-analysis-feature-picker.component.scss'],
})
export class FieldAnalysisFeaturePickerComponent {
  public queryParams$ = this.navigationService.getQueryParamsWhenNavigating$();
  protected isoXMLLayerId = LayerId.ISOXML;
  @Input() public fieldAnalysisMapFeatures: MapCoverFlowItem[] = [];

  @Output() public selectedMapFeatureChanged = new EventEmitter<MapCoverFlowItem>();

  private _selectedMapFeature: LayerId = LayerId.NONE;
  public get selectedMapFeature(): LayerId {
    return this._selectedMapFeature;
  }

  public set selectedMapFeature(selectedMapFeatureId: LayerId) {
    if (this._selectedMapFeature === selectedMapFeatureId) {
      return;
    }

    this._selectedMapFeature = selectedMapFeatureId;

    const fieldAnalysisMapFeature = this.fieldAnalysisMapFeatures.find(
      (mapFeature) => mapFeature.mapCoverFlowLayersId === selectedMapFeatureId
    );

    if (!fieldAnalysisMapFeature) {
      return;
    }

    fieldAnalysisMapFeature.isVisible = true;

    this.fieldAnalysisSideDrawerService.changeSideDrawerContent(selectedMapFeatureId);

    this.selectedMapFeatureChanged.emit(fieldAnalysisMapFeature);
  }

  constructor(
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private appUpdater: AppUpdaterService,
    private navigation: NavigationComponent,
    private navigationService: NavigationService,
    private mapService: OlMapService
  ) {
    this.mapService.setNewActivePage('field-analysis');
  }

  public onCloseClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  public onHideClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  public onSelect(selectedMapFeatureId: LayerId) {
    this.selectedMapFeature = selectedMapFeatureId;
  }

  public navigate(selectedMapFeatureId: LayerId, queryParams: any) {
    switch (selectedMapFeatureId) {
      case LayerId.ISOXML:
        void this.navigateToIsoxml(queryParams);
        break;
    }
  }

  public async navigateToIsoxml(queryParams: any) {
    this.appUpdater.checkForUpdate();
    await this.navigation.navigate('newmap/iso', queryParams, false);
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { MapConstants } from '@app/core/map/map.constants';
import { MetadataChild, MetadataGeometry } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerBundle, LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { Feature } from 'ol';
import { createEmpty, extend } from 'ol/extent';
import { WKT } from 'ol/format';
import { Type } from 'ol/geom/Geometry';
import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable()
export class MetadataMapService implements OnDestroy {
  private _metadataLayers = new Map<number, Layer>();
  private readonly UNIQUE_LAYER_ID = 42069; // magic number to ensure unique layer id
  private _fieldFeatures: Feature[] | null = null;

  constructor(
    private mapService: OlMapService,
    private layerService: OlLayerService
  ) {}

  public ngOnDestroy(): void {
    this.clearLayers();
  }

  public clearLayers() {
    this._metadataLayers = new Map<number, Layer>();
    this.layerService.removeLayers([LayerId.AS_APPLIED, LayerId.AS_APPLIED_POINTS]);
  }

  public removeMetadataChildFromMap(indexId: number) {
    this.map.getMap().removeLayer(this._metadataLayers.get(indexId) as BaseLayer);
    this._metadataLayers.delete(indexId);
  }

  public toggleMetadataChildVisibility(indexId: number, visible: boolean) {
    this._metadataLayers.get(indexId)?.setVisible(visible);

    if (visible) {
      const extend = (this._metadataLayers.get(indexId)?.getSource() as VectorSource).getExtent();

      this.mapService.fitMapToExtent(extend, false);
    }
  }

  public addMetadataChildrenToMap(metadataChildren: MetadataChild[], units?: string) {
    if (metadataChildren.length === 0 || metadataChildren.find((metadataChild) => metadataChild.geometries.length === 0)) {
      return;
    }
    metadataChildren.forEach((metadataChild, index) => {
      const layer = this.createLayerFromMetadataGeometries(metadataChild.geometries, units);
      this._metadataLayers.set(metadataChild.indexId ?? index, layer);
    });

    this.slideToMetadataExtend();
  }

  public addMetadataGeometriesToMap(metadataGeometries: MetadataGeometry[], unitType?: string) {
    if (!metadataGeometries?.length) {
      return;
    }

    const layer = this.createLayerFromMetadataGeometries(metadataGeometries, unitType);
    const extent = layer.getSource()?.getExtent();
    this.mapService.fitMapToExtent(extent, false);
  }

  public addLocationsToMap(locations: MetadataGeometry[] | undefined, unitText?: string) {
    if (!locations) {
      return;
    }

    const layer = this.createLayerFromMetadataGeometries(locations, unitText);
    this._metadataLayers.set(this.UNIQUE_LAYER_ID, layer);
    this.slideToMetadataExtend();
  }

  public getMapLayerId(geometryType: Type) {
    switch (geometryType) {
      case 'Point':
        return LayerId.AS_APPLIED_POINTS;
      case 'Polygon':
        return LayerId.AS_APPLIED;
      default:
        return;
    }
  }

  private get map() {
    return this.mapService.mapComponent;
  }

  private createLayerFromMetadataGeometries(metadataGeometries: MetadataGeometry[], unitText?: string): VectorLayer<VectorSource> {
    const wtkFeatures = this.getFeaturesFromGeometries(metadataGeometries, unitText);
    const mapLayerId = this.getMapLayerId(metadataGeometries[0]?.type) ?? LayerId.AS_APPLIED;

    return (this.layerService.createFeatureLayer(mapLayerId, wtkFeatures) as LayerBundle[])?.first()
      ?.layerObjects[0] as VectorLayer<VectorSource>;
  }

  private getFeaturesFromGeometries(metadataGeometries: MetadataGeometry[], unitText?: string): Feature[] {
    return metadataGeometries.map((geom) => {
      const wkt = new WKT().readFeature(geom.geometry, {
        dataProjection: MapConstants.dataProjection,
        featureProjection: MapConstants.mapProjection,
      });
      wkt.set('layerId', this.getMapLayerId(geom.type));
      wkt.set('color', geom.color);
      wkt.set('quantity', geom.quantity);
      if (unitText) {
        wkt.set('unitText', unitText);
      }
      return wkt;
    });
  }

  private slideToMetadataExtend() {
    let totalExtend = createEmpty();
    this._metadataLayers.forEach((layer) => {
      totalExtend = extend(totalExtend, (layer.getSource() as VectorSource).getExtent());
    });

    this.mapService.fitMapToExtent(totalExtend, false);
  }
}

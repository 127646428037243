import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { LegendStatistics } from '@app/new-map/features/vra/domain/legend';
import { Statistics } from '@app/new-map/features/vra/state/prescription-map/prescription-map.store';

export function createLegendStatistics(
  quantities: number[],
  statistics: Statistics,
  operationTypeGroup?: OperationTypeGroupEnum | null
): LegendStatistics {
  switch (operationTypeGroup) {
    case OperationTypeGroupEnum.Lime:
      return {
        min: 0,
        max: 10,
        avg: statistics.avg,
        total: statistics.total,
      };
    default:
      return {
        min: quantities.reduce((acc, curr) => Math.min(acc, curr)),
        max: quantities.reduce((acc, curr) => Math.max(acc, curr)),
        avg: statistics.avg,
        total: statistics.total,
      };
  }
}

import { Injectable } from '@angular/core';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { OperationTypeGroupsService } from '@app/core/operation-type-groups/operation-type-groups.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { ArrayHelper } from '@app/helpers/arrays/array-helpers';
import { DownloadHelper } from '@app/helpers/file/download-helper';
import { FileExtensionHelper } from '@app/helpers/file/file-extension-helper';
import { LoadingState } from '@app/helpers/loading-state';
import { AsAppliedShownComponentEnum } from '@app/new-map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { AsAppliedService } from '@app/new-map/features/field-analysis/features/as-applied/as-applied.service';
import {
  MetadataChild,
  MetadataFile,
  MetadataParent,
  MetadataParentDto,
  ShapefileAnalysisDto,
  ShapefileConfigInput,
  TemporalShapefileConfigInput,
} from '@app/new-map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { AsAppliedFileUploadRepositoryService } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/services/as-applied-file-upload-repository.service';
import { MetadataTask, MetadataTaskDto } from '@app/new-map/features/field-analysis/features/as-applied/metadata-task.class';
import { FieldAnalysisSideDrawerService } from '@app/new-map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYear } from '@app/state/services/harvest-year/harvest-year';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { NEVER, Observable, ReplaySubject, combineLatest, forkJoin } from 'rxjs';
import { distinctUntilChanged, finalize, first, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AsAppliedFileUploadService {
  private _selectedFarm = new ReplaySubject<Farm>(1);
  private _selectableFarms$ = this.farmStateService.selectedFarms$;
  private _metaDataParent = new ReplaySubject<MetadataParent>(1);
  private _uploadedFile = new ReplaySubject<MetadataFile>(1);

  private readonly MODULE_NAME = 'asAppliedUpload';

  public readonly ALLOWED_FILE_FORMATS = ['zip'];

  public get selectableTasks$(): Observable<MetadataTask[]> {
    return this.selectedFarm$.pipe(
      first(),
      switchMap((farm) => this.getSelectableTasks(farm.id))
    );
  }

  public getSelectableTasks(farmId: number): Observable<MetadataTask[]> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      filterNullish(),
      switchMap((harvestYear) =>
        forkJoin([
          this.asAppliedFileUploadRepositoryService.getTaskSuggestions(farmId, harvestYear),

          this.produceNormsService.getProduceNorms(farmId, harvestYear).pipe(first()),
          this.operationTypeGroupsService.get().pipe(first()),
        ])
      ),
      map(([taskSuggestions, produceNorms, operationTypeGroups]) =>
        this.enrichMetadataTaskDto(taskSuggestions, produceNorms, operationTypeGroups)
      )
    );
  }

  public get selectableFields$(): Observable<Field[]> {
    return this.farmStateService.fields$.pipe(
      withLatestFrom(this.selectedFarm$),
      map(([fields, farm]) => fields?.filter(ArrayHelper.notEmpty).filter((field) => field.farmId === farm.id)!)
    );
  }

  public get selectableHarvestYears$(): Observable<number[]> {
    // From https://stackoverflow.com/a/55776744
    // This is a workaround for the fact that Array.from() does not support a step parameter
    // This creates an array of 7 years from 5 years before current harvest year to 1 year later than the current harvest year
    // The array is reversed so that the latest year is the first element
    // Example: current harvest year is 2020, the array will be [2021, 2020, 2019, 2018, 2017, 2016, 2015]
    const range = (start: number, stop: number, step: number) =>
      Array.from(
        {
          length: (stop - start) / step + 1,
        },
        (_, i) => start + i * step
      );

    return this.harvestYearStateService.harvestYear$.pipe(
      map((year) => {
        const start = year! - 5;
        const stop = year! + 1;

        // Reverse the array so that the latest year is the first element
        return range(start, stop, 1).reverse();
      })
    );
  }

  public get selectableFarms$(): Observable<Farm[]> {
    return this._selectableFarms$;
  }

  public set selectedFarm(farm: Farm) {
    this._selectedFarm.next(farm);
  }

  public get selectedFarm$(): Observable<Farm> {
    return this._selectedFarm.asObservable();
  }

  public get metaDataParent$(): Observable<MetadataParent> {
    return this._metaDataParent.asObservable();
  }

  public set metaDataParent(metaDataParent: MetadataParent) {
    this._metaDataParent.next(metaDataParent);
  }

  public get uploadedFile$(): Observable<MetadataFile> {
    return this._uploadedFile.asObservable();
  }

  public set uploadedFile(metadataFile: MetadataFile) {
    this._uploadedFile.next(metadataFile);
  }

  public loadingState = new LoadingState();
  public loading$ = this.loadingState.changes$;

  constructor(
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private asAppliedFileUploadRepositoryService: AsAppliedFileUploadRepositoryService,
    private asAppliedService: AsAppliedService,
    private dialogService: DialogService,
    private dirtyCheckService: DirtyCheckService,
    private notificationService: NotificationService,
    private operationTypeGroupsService: OperationTypeGroupsService,
    private produceNormsService: ProduceNormsService,
    private globalStateService: GlobalStateService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private languageService: LanguageService
  ) {}

  public toggleDirty(dirty: boolean) {
    this.dirtyCheckService.setIsFeatureDirty(this, dirty, this.MODULE_NAME);
  }

  public isDirty() {
    return this.dirtyCheckService.isAppDirty;
  }

  public notDirty() {
    return this.dirtyCheckService.clearAllStates();
  }

  public saveAsAppliedShpConfigFile() {
    let configs: ShapefileConfigInput = this.asAppliedService.shapefileConfigInput;
    // remove index
    configs.configsInput.forEach((item: TemporalShapefileConfigInput) => delete item.index);

    this.loadingState.start('main.asApplied.loadingMessage');
    this.selectedFarm$
      .pipe(
        first(),
        withLatestFrom(this.harvestYearStateService.harvestYear$),
        filterNullish(),
        switchMap(async ([farm, harvestYear]) =>
          this.asAppliedFileUploadRepositoryService
            .setExecutedArchiveData(farm.id, harvestYear!, this.asAppliedService.executedArchiveFileUpload.id, configs)
            .subscribe((metaDataCollection: MetadataParentDto | null) => {
              this.metaDataParent = this.enrichMetaDataParentDto(metaDataCollection!, farm, harvestYear!);
              this.asAppliedService.updateTasksAndGroups();
              this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent);
              this.loadingState.stop();
            })
        )
      )
      .subscribe(() => {});
  }

  public setAndShowAsAppliedShpConfigFile() {
    this.selectedFarm$.pipe(first()).subscribe((farm: Farm) => {
      this.asAppliedFileUploadRepositoryService
        .getShapefileAnalysis(farm.id, HarvestYear.currentHarvestYear, this.asAppliedService.executedArchiveFileUpload.id)
        .pipe(first())
        .subscribe((shapeFile: ShapefileAnalysisDto) => {
          // sæt current shapefile
          this.asAppliedService.shapeFileAnalysis = shapeFile;
          this.asAppliedService.shapefileConfigInput = {} as ShapefileConfigInput;
          this.asAppliedService.shapefileConfigInput.configsInput = [];
          this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedConfigFile);
        });
    });
  }

  public uploadFile(file: File, farm: Farm, harvestYear: number | undefined) {
    if (!harvestYear) return;

    if (FileExtensionHelper.validateFileExtensions([file], this.ALLOWED_FILE_FORMATS)) {
      this.loadingState.start('uploader fil');
      this.globalStateService.specificLoadingStarted('main.asApplied.filePicker.uploadMessage');
      this.selectedFarm = farm;
      this.asAppliedFileUploadRepositoryService
        .uploadAsAppliedFile(farm.id, harvestYear, file)
        .pipe(
          first(),
          finalize(() => {
            this.globalStateService.specificLoadingCompleted('main.asApplied.filePicker.uploadMessage');
          })
        )
        .subscribe((executedArchiveFileUpload) => {
          if (!executedArchiveFileUpload) return;

          this.asAppliedService.executedArchiveFileUpload = executedArchiveFileUpload;
          this.asAppliedFileUploadRepositoryService
            .getExecutedArchiveData(farm.id, harvestYear, executedArchiveFileUpload.id)
            .pipe(first())
            .subscribe((metaDataCollection) => {
              if (!metaDataCollection) return;

              this.metaDataParent = this.enrichMetaDataParentDto(metaDataCollection, farm, harvestYear);
              this.asAppliedService.updateTasksAndGroups();
              switch (executedArchiveFileUpload.executedArchiveFileType) {
                // isoXml
                case 1:
                  if (!metaDataCollection.items?.length) {
                    this.notificationService.showError('main.asApplied.filePicker.invalidFileUploaded', 15000);
                  } else {
                    this.notificationService.showSuccess('main.asApplied.filePicker.uploadSuccess', 20000);
                    this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent);
                  }
                  break;
                // Shp
                case 2:
                  this.notificationService.showSuccess('main.asApplied.filePicker.uploadSuccessShape', 20000);
                  this.setAndShowAsAppliedShpConfigFile();
                  break;
                // ? if not isoXml or Shp ... error?
                default:
                  this.notificationService.showError('main.asApplied.filePicker.invalidFileUploaded', 15000);
                  break;
              }
            });
        });
    }
  }

  public convertMetaDataParentDtoToMetadataParentWithFarm(metadataParentDto: MetadataParentDto) {
    return this.farmStateService.selectedFarms$.pipe(
      first(),
      map((farms) => {
        return new MetadataParent(
          metadataParentDto,
          this.languageService,
          farms.find((farm) => farm.id === metadataParentDto.farmId)
        );
      })
    );
  }

  public createMetadataChildren(metadataParentId: number, metadataChildrenFromForm: MetadataChild[]) {
    const input = this.asAppliedService.shapefileConfigInput;
    let shapefileConfigInput = input ? input.configsInput : null;

    this.asAppliedService.loading(true, 'main.asApplied.metaData.creatingDocumentation');
    this.selectedFarm$
      .pipe(
        first(),
        withLatestFrom(this.harvestYearStateService.harvestYear$),
        switchMap(([farm, harvestYear]) =>
          this.asAppliedFileUploadRepositoryService.postMetadataChildren(
            farm.id,
            metadataParentId,
            metadataChildrenFromForm,
            shapefileConfigInput,
            harvestYear
          )
        ),
        finalize(() => this.asAppliedService.loading(false, 'main.asApplied.metaData.creatingDocumentation'))
      )
      .subscribe(() => {
        this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
        this.asAppliedService.updateTasksAndGroups();
        this.dirtyCheckService.clearAllStates();
        this.globalStateService.loadingCompleted();
        this.notificationService.showCreated('main.asApplied.metaData.documentation');
      });
  }

  public getMetadataParentDtos(): Observable<MetadataParentDto[]> {
    return combineLatest([
      this.farmStateService.selectedFarmIds$,
      this.harvestYearStateService.harvestYear$.pipe(distinctUntilChanged()),
      this.asAppliedService.fileJustUploaded.pipe(startWith(null)),
    ]).pipe(
      switchMap(([selectedFarmIds, harvestYear, _]) => {
        return this.asAppliedFileUploadRepositoryService.getMetadataParents(selectedFarmIds, harvestYear);
      })
    );
  }

  public getMetadataFile(metadataParent: MetadataParent): Observable<MetadataFile> {
    return combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$]).pipe(
      distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
      switchMap(([farms, harvestYear]) =>
        this.asAppliedFileUploadRepositoryService
          .getMetadataFile(
            farms.map((farm) => farm.id),
            metadataParent,
            harvestYear
          )
          .pipe(map((metadataFile) => new MetadataFile(metadataFile, metadataParent)))
      )
    );
  }

  /**
   * If no metadata parent is supplied, delete the selected
   */
  public deleteMetadataParent(metadataParent?: MetadataParent) {
    const data = {
      title: 'main.asApplied.metaData.deleteTitle',
      text: 'main.asApplied.metaData.deleteText',
    };

    this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
      this.asAppliedService.loading(true, 'main.asApplied.metaData.deletingUpload');
      if (!metadataParent) {
        this.metaDataParent$
          .pipe(
            first(),
            switchMap((selectedMetadataParent) => {
              const { id, farm, harvestYear } = selectedMetadataParent;

              if (!farm) return NEVER;

              return this.asAppliedFileUploadRepositoryService.deleteMetadataParent(id, farm.id, harvestYear);
            }),
            finalize(() => this.asAppliedService.loading(false, 'main.asApplied.metaData.deletingUpload'))
          )
          .subscribe(() => {
            this.notificationService.showDeleted('main.asApplied.metaData.file');
            this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
          });
      } else {
        const { id, farm, harvestYear } = metadataParent;
        if (farm)
          this.asAppliedFileUploadRepositoryService
            .deleteMetadataParent(id, farm.id, harvestYear)
            .pipe(finalize(() => this.asAppliedService.loading(false, 'main.asApplied.metaData.deletingUpload')))
            .subscribe(() => {
              this.notificationService.showDeleted('main.asApplied.metaData.file');
              this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
            });
      }
    });
  }

  private enrichMetadataTaskDto(
    taskSuggestionDtos: MetadataTaskDto[],
    produceNorms: ProduceNorm[],
    operationTypeGroups: OperationTypeGroup[]
  ): MetadataTask[] {
    return taskSuggestionDtos.flatMap((taskSuggestionDto) => {
      const operationTypeGroup = operationTypeGroups.find((operationType) => operationType.id === taskSuggestionDto.operationTypeGroup);
      const produceNormsInTask = produceNorms.filter((produceNorm) => taskSuggestionDto.produceNormNumbers.includes(produceNorm.number));

      // flatMap allows us to return nothing if the operationTypeGroup is not found
      if (!operationTypeGroup) return [];

      return new MetadataTask(taskSuggestionDto, operationTypeGroup, produceNormsInTask);
    });
  }

  private enrichMetaDataParentDto(metadataParentDto: MetadataParentDto, farm: Farm, harvestYear: number): MetadataParent {
    if (!metadataParentDto.harvestYear) {
      metadataParentDto.harvestYear = harvestYear;
    }
    return new MetadataParent(metadataParentDto, this.languageService, farm);
  }

  public downloadZipFile(metadataParent: MetadataParent) {
    const { farm, harvestYear, id, displayDate, name } = metadataParent;

    if (farm)
      this.asAppliedFileUploadRepositoryService.downloadZipFile(farm.id, harvestYear, id).subscribe((data) => {
        DownloadHelper.downloadBlob(`${displayDate}_${name}`, data);
        this.notificationService.showSuccess('main.asApplied.metaData.downLoadSuccess');
      });
  }
}

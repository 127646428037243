import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SIDE_DRAWER_ANIMATION_SETTING } from '@app/map/features/side-drawer-const';
import { MapSelector } from '@app/map/map.selector';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PrognosisActionsService } from '../prognosis.actions.service';
import { PrognosisService } from '../prognosis.service';
import { PrognosisShownComponent } from './prognosis-shown-component.enum';

const prognosisLayerIdxQueryParamKey = 'prognosis-layer-idx';

@Component({
  selector: 'app-prognosis-side-drawer-content',
  templateUrl: './prognosis-side-drawer.component.html',
  styleUrls: ['./prognosis-side-drawer.component.scss'],
  animations: SIDE_DRAWER_ANIMATION_SETTING,
})
export class PrognosisSideDrawerComponent implements OnInit, OnDestroy {
  public shownComponent$ = this.prognosisService.shownComponentState$.pipe(map((obj) => obj.shownComponent));
  public components = PrognosisShownComponent;
  public isInitialized!: boolean;
  private subscriptions: Subscription[] = [];
  public sideDrawerFeatureContentRef$ = this.prognosisService.sideDrawerFeatureContentRef$;

  public selectedMapFeature: MapLayerId = MapLayerId.NONE;
  public mapCoverFlowItems$ = this.prognosisService.getCoverFlowLayersItems();
  public selectedMapFeatureId$ = this.mapSelector.selectedMapCoverFlowItem$.pipe(
    map((item) => {
      if (!item) {
        return MapLayerId.NONE;
      }

      return item.mapCoverFlowLayersId;
    })
  );

  public selectedPrognosisFeature: MapCoverFlowItem | null = null;

  constructor(
    private prognosisService: PrognosisService,
    private mapSelector: MapSelector,
    public prognosisActionDispatchers: PrognosisActionsService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, void, void>,
    private harvestYearStateService: HarvestYearStateService,
    private cdr: ChangeDetectorRef,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this.isInitialized = true;
    const isShowingFieldDetails = this.sideDrawerFeatureContentRef$.subscribe((sideDrawerFeatureContentRef) => {
      if (sideDrawerFeatureContentRef) {
        this.sideDrawerRef.show();
      }
    });
    this.subscriptions.push(isShowingFieldDetails);

    const resetSideDrawerSub = this.harvestYearStateService.harvestYear$
      .pipe(
        tap(() => {
          this.prognosisService.setShownComponent(this.components.PrognosisPicker);
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.subscriptions.push(resetSideDrawerSub);
  }

  public ngOnDestroy(): void {
    this.prognosisActionDispatchers.selectedMapCoverFlowItemIdxChanged(null as any);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.prognosisService.setShownComponent(this.components.PrognosisPicker);
  }

  public onCloseClick() {
    this.sideDrawerRef.hide();
    this.prognosisService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public onPrognosisSelect(prognosisFeature: MapCoverFlowItem) {
    this.selectedPrognosisFeature = prognosisFeature;

    switch (prognosisFeature.mapCoverFlowLayersId) {
      case LayerId.GROWTH_REGULATION:
        this.prognosisService.setShownComponent(this.components.GrowthRegulation);
        break;
      case LayerId.CORN_PROGNOSIS:
        this.prognosisService.setShownComponent(this.components.CornHarvest);
        break;
      case LayerId.YIELD_PROGNOSIS:
        this.prognosisService.setShownComponent(this.components.YieldPrognosis);
        break;
      case LayerId.POTATO_BLIGHT:
        this.prognosisService.setShownComponent(this.components.PotatoBlight);
        break;
      default:
        this.prognosisService.setShownComponent(this.components.PrognosisPicker);
    }
  }
}

import { NavigationItem } from './navigation-item';

export const navigationItemsContainer: Readonly<NavigationItemsContainer> = {
  cultivationJournalSection: new NavigationItem({
    translationString: 'navigation.cultivationJournal',
    items: [],
    route: 'newmap/cultivation-journal',
    icon: 'field_overview',
    module: 'field_plan',
  }),

  fieldAnalysisSection: new NavigationItem({
    translationString: 'navigation.fieldAnalysis',
    items: [],
    route: 'newmap/field-analysis',
    icon: 'field_analysis',
    module: 'field_analysis',
  }),

  vraSection: new NavigationItem({
    translationString: 'navigation.vra',
    items: [],
    route: 'newmap/vra',
    icon: 'prescription_maps',
    module: 'vra',
  }),

  prognosesSection: new NavigationItem({
    translationString: 'navigation.prognosis',
    items: [],
    route: 'newmap/prognosis',
    icon: 'Prognose',
    module: 'prognosis',
  }),

  hotspotsSection: new NavigationItem({
    translationString: 'navigation.hotspots',
    items: [],
    route: 'newmap/hotspots',
    icon: 'Hotspots',
    module: 'hotspots',
  }),

  basisLayerSection: new NavigationItem({
    translationString: 'navigation.basislayer',
    items: [],
    route: 'newmap/basis-layer',
    icon: 'custom_maps',
    module: 'basis_layer',
  }),

  yieldBenchmarkSection: new NavigationItem({
    translationString: 'navigation.yieldBenchmark',
    items: [],
    route: 'map/yield-benchmark',
    icon: 'Benchmark',
    module: 'yield_benchmark',
  }),

  economySection: new NavigationItem({
    translationString: 'navigation.economy',
    items: [],
    route: 'economy/table',
    icon: 'fremstillingspris',
    module: 'economy',
  }),

  settings: new NavigationItem({
    translationString: 'navigation.settings.title',
    items: [
      new NavigationItem({
        translationString: 'navigation.settings.dataConnections',
        items: [],
        route: 'settings/connections',
        resource: 'settings_data_connections',
      }),
      new NavigationItem({
        translationString: 'navigation.datamanagement.fieldTransfer',
        items: [],
        route: 'settings/datamanagement/fields',
        resource: 'settings_transfer',
      }),
      new NavigationItem({
        translationString: 'navigation.datamanagement.taskTransfer',
        items: [],
        route: 'settings/datamanagement/tasks',
        resource: 'settings_transfer',
      }),
    ],
    route: 'settings',
    icon: 'Indstillinger',
    module: 'settings',
  }),
} as const;

export interface NavigationItemsContainer {
  cultivationJournalSection: NavigationItem;
  fieldAnalysisSection: NavigationItem;
  vraSection: NavigationItem;
  prognosesSection: NavigationItem;
  basisLayerSection: NavigationItem;
  hotspotsSection: NavigationItem;
  yieldBenchmarkSection: NavigationItem;
  economySection: NavigationItem;
  settings: NavigationItem;
}

import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { ImageRequestService } from '@app/core/image-request/image-request.service';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { getStyle } from '@app/helpers/map/map-styles/map-styles';
import Feature, { FeatureLike } from 'ol/Feature';
import Map from 'ol/Map';
import { Geometry, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import TileLayer from 'ol/layer/WebGLTile.js';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import { default as VectorSource } from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';

interface RGBColor {
  red: number;
  green: number;
  blue: number;
  alpha?: number;
}

// Can be used to adjust the tile layer (0 = default, except for gamma which is 1 by default)
const webGlTileStyle = {
  exposure: 0,
  saturation: 0,
  contrast: 0,
  brightness: 0,
  hue: 0,
  gamma: 1, // 1 default
};

// Extract TileLayer constructor parameters and take the first one (options object)
type TileLayerOptions = ConstructorParameters<typeof TileLayer>[0];

@Injectable({ providedIn: 'root' })
export class LayerService {
  constructor(
    private imageRequestService: ImageRequestService,
    private endpoints: EndpointsService
  ) {}

  public getLayer(layerSetting: MapLayerSetting, features: Feature[], map: Map) {
    switch (layerSetting.layerType) {
      case MapLayerType.TILE:
        return this.getTileLayer(layerSetting);
      case MapLayerType.VECTOR:
        return this.getVectorLayer(features, layerSetting, map);
      case MapLayerType.VECTORIMAGE:
        return this.getVectorImageLayer(features, layerSetting, map);
      case MapLayerType.POINTS:
        return this.getPointsLayer(features as Feature<Point>[], layerSetting, map);
      default:
        return;
    }
  }
  public getOSMLayer() {
    const options = {
      source: new OSM(),
    };
    return this.createTileLayer(MapLayerId.OSM, options);
  }

  public getOrtoPhotoLayer() {
    const options = {
      style: webGlTileStyle,
      source: new XYZ({
        url: `${this.endpoints.foApi}/mapproxies/kortforsyningen/orto_foraar_webm/{z}/{x}/{y}`,
        crossOrigin: 'Anonymous',
        minZoom: 0,
        maxZoom: 19,
      }),
    };

    return this.createTileLayer(MapLayerId.AIRPHOTO, options);
  }

  public getBingMapsLayer() {
    const options = {
      style: webGlTileStyle,
      source: new BingMaps({
        key: 'An8C4dphGoI_r8q_BP9WxXEY7z7HHmOpke_EPvoAsEFEacyPbY2A7366GtdERi8y', //TODO - Remove key from source code to appsettings or environment
        imagerySet: 'Aerial',
        placeholderTiles: true,
      }),
      visible: false,
    };

    return this.createTileLayer(MapLayerId.AIRPHOTO_BING, options);
  }

  private createTileLayer(layerId: MapLayerId, options: TileLayerOptions) {
    const layer = new TileLayer(options);
    layer.set('id', layerId);
    return layer;
  }

  private getTileLayer(layerSetting: MapLayerSetting) {
    const tileSource = new XYZ({
      minZoom: 2,
      url: layerSetting.url,
      crossOrigin: 'Anonymous',
    });

    const mapLayer = new TileLayer({
      maxResolution: 2000,
      minResolution: 0,
      source: tileSource,
      opacity: layerSetting.opacity ?? 1,
    });

    // If tile layer source needs authorization headers (e.g. RedEdgeNdvi) use custom tile load function
    if (layerSetting.useAuthHeaders) {
      tileSource.setTileLoadFunction((tile, url) => {
        this.imageRequestService.getImageAsDataUrlWebGl(url, layerSetting.method, layerSetting.requestBody).subscribe(
          (dataUrl: string) => {
            const image = (tile as any).getImage() as HTMLImageElement;
            image.src = dataUrl;
          },
          (error) => {
            console.error('Error loading tile:', error);
            // TODO - Handle the error appropriately, e.g., set a default image, log the error, etc.
          }
        );
      });
    }

    this.setCommonLayerProperties(mapLayer, layerSetting);

    return mapLayer;
  }

  private getVectorImageLayer(features: Feature[], layerSetting: MapLayerSetting, map: Map) {
    // Specify the type of geometry that the VectorSource will contain
    const source = new VectorSource({
      features: features || [],
    });

    // Modify the style function to return a single Style object
    const style = (feature: FeatureLike) => {
      const zoom = map.getView().getZoom();

      return getStyle(zoom ?? 0, 0, feature as Feature<Geometry>);
    };

    const mapLayer = new VectorImageLayer({
      maxResolution: 200,
      minResolution: 0,
      source: source,
      style: style,
      opacity: layerSetting.opacity ?? 1,
    });

    this.setCommonLayerProperties(mapLayer, layerSetting);

    return mapLayer;
  }

  private getVectorLayer(features: Feature[], layerSetting: MapLayerSetting, map: Map) {
    const mapLayer = new VectorLayer({
      maxResolution: this.getMaxResolution(layerSetting.layerId! as MapLayerId),
      minResolution: this.getMinResolution(layerSetting.layerId! as MapLayerId),
      source: new VectorSource({
        features: features || [],
      }),
      style: (feature) => getStyle(map.getView().getZoom()!, 0, feature as Feature),
      opacity: layerSetting.opacity ?? 1,
    });

    this.setCommonLayerProperties(mapLayer, layerSetting);

    return mapLayer;
  }

  private getPointsLayer(features: Feature<Point>[], layerSetting: MapLayerSetting, map: Map) {
    // Mutate features: Map feature colors to RGB components
    this.mapFeatureColors(features);

    const source = new VectorSource({
      features: features || [],
    });

    const mapLayer = new WebGLPointsLayer({
      maxResolution: this.getMaxResolution(layerSetting.layerId! as MapLayerId),
      minResolution: this.getMinResolution(layerSetting.layerId! as MapLayerId),
      source: source as VectorSource<Feature<Point>>,
      opacity: layerSetting.opacity ?? 1,
      style: {
        'circle-radius': layerSetting.size ?? 16,
        'circle-fill-color': ['color', ['get', 'red'], ['get', 'green'], ['get', 'blue']],
        'circle-opacity': ['get', 'alpha'] ?? 1,
      },
    });

    mapLayer.set('id', layerSetting.layerId);
    mapLayer.setZIndex(layerSetting.zIndex);
    mapLayer.setVisible(layerSetting.isVisible);

    return mapLayer;
  }

  /**
   * Maps the feature's color attribute to individual RGB components.
   * This ensures WebGL shaders correctly interpret the color.
   * Default color is set to `255, 1, 1` (red).
   */
  private mapFeatureColors(features: Feature<Point>[]) {
    features.forEach((feature) => {
      const color = this.parseColor(feature.get('fill') ?? feature.get('color'));
      feature.set('red', color.red);
      feature.set('green', color.green);
      feature.set('blue', color.blue);
      feature.set('alpha', color.alpha ?? 1);
    });
  }

  /**
   * Parses a color value into its RGB components.
   *
   * @param {string | number[]} colorValue - The color value to parse.
   * @returns {RGBColor} Parsed RGB/RGBA values.
   */
  private parseColor(colorValue: string | number[]): RGBColor {
    const DEFAULT_COLOR: RGBColor = { red: 255, green: 1, blue: 1 };

    if (typeof colorValue === 'string') {
      if (colorValue.startsWith('#')) {
        return this.parseHexColor(colorValue);
      } else if (colorValue.startsWith('rgb')) {
        return this.parseRgbColor(colorValue);
      }
    } else if (Array.isArray(colorValue) && (colorValue.length === 3 || colorValue.length === 4)) {
      return this.parseArrayColor(colorValue);
    }

    // Log a warning for unrecognized color formats.
    console.warn(`Unrecognized color format: ${colorValue}`);
    return DEFAULT_COLOR;
  }

  /**
   * Parses a HEX color string into its RGB components.
   *
   * @param {string} colorValue - HEX color string to parse.
   * @returns {RGBColor} Parsed RGB/RGBA values.
   */
  private parseHexColor(colorValue: string): RGBColor {
    // Handle standard 6-digit (RRGGBB) or 8-digit (RRGGBBAA) HEX colors.
    if (colorValue.length === 7 || colorValue.length === 9) {
      return {
        red: parseInt(colorValue.substring(1, 3), 16),
        green: parseInt(colorValue.substring(3, 5), 16),
        blue: parseInt(colorValue.substring(5, 7), 16),
        alpha: colorValue.length === 9 ? parseInt(colorValue.substring(7, 9), 16) / 255 : 1,
      };
    }
    return { red: 255, green: 1, blue: 1 };
  }

  /**
   * Parses an RGB or RGBA color string into its RGB components.
   *
   * @param {string} colorValue - RGB/RGBA color string to parse.
   * @returns {RGBColor} Parsed RGB/RGBA values.
   */
  private parseRgbColor(colorValue: string): RGBColor {
    // Using regex to capture both integer and percentage color values.
    const regex = /(\d+\.?\d*%?)/g;
    const values = colorValue.match(regex);

    // Ensure we have the right amount of captured values (3 for RGB, 4 for RGBA).
    if (values && (values.length === 3 || values.length === 4)) {
      return {
        red: this.parseColorValue(values[0]),
        green: this.parseColorValue(values[1]),
        blue: this.parseColorValue(values[2]),
        alpha: values.length === 4 ? parseFloat(values[3]) : 1,
      };
    }

    return { red: 255, green: 1, blue: 1 };
  }

  /**
   * Parses an array of RGB or RGBA values.
   *
   * @param {number[]} colorValue - Array of RGB/RGBA values.
   * @returns {RGBColor} Parsed RGB/RGBA values.
   */
  private parseArrayColor(colorValue: number[]): RGBColor {
    return {
      red: colorValue[0],
      green: colorValue[1],
      blue: colorValue[2],
      alpha: colorValue.length === 4 ? colorValue[3] : 1,
    };
  }

  /**
   * Converts an individual color value (either an integer or percentage) to its 0-255 range representation.
   *
   * @param {string} value - The color component value to parse.
   * @returns {number} Parsed value in 0-255 range.
   */
  private parseColorValue(value: string): number {
    if (value.endsWith('%')) {
      // Convert percentage values to the 0-255 range.
      return Math.round(parseFloat(value) * 2.55);
    }
    return parseInt(value);
  }

  /**
   * Returns the maximum resolution at which the specified layer should be visible.
   * This determines how far a user can zoom out and still see the layer.
   * @param layerId MapLayerId
   */
  private getMaxResolution(layerId: MapLayerId) {
    switch (layerId) {
      case MapLayerId.HIGH_RES_FIELD_MARKERS:
      case MapLayerId.HIGH_RES_POTATO_MARKERS:
      case MapLayerId.BLIGHT_POLYGONS:
      case MapLayerId.REGION:
      case MapLayerId.HOTSPOTMARKERS:
        return 10000;
      default:
        return 200;
    }
  }

  private setCommonLayerProperties(mapLayer: any, layerSetting: MapLayerSetting) {
    mapLayer.set('id', layerSetting.layerId);
    mapLayer.setZIndex(layerSetting.zIndex);
    mapLayer.setVisible(layerSetting.isVisible);
    mapLayer.setOpacity(layerSetting.opacity ?? 1);
  }

  /**
   * Get the min map resolution for a given layer.
   * The lower resolution the further the user can zoom in and still see the layer
   * @param layerId MapLayerId
   */
  private getMinResolution(layerId: MapLayerId) {
    switch (layerId) {
      case MapLayerId.BLIGHT_POLYGONS:
        return 40;
      case MapLayerId.HIGH_RES_FIELD_MARKERS:
      case MapLayerId.HIGH_RES_POTATO_MARKERS:
        return 200;
      default:
        return 0;
    }
  }
}

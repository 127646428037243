import { Injectable, OnDestroy } from '@angular/core';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { Unit } from '@app/core/interfaces/unit.type';
import { SoilSamplesRepoService } from '@app/core/repositories/soil-samples/soil-samples-repo.service';
import { BasisLayerStateService } from '@app/new-map/features/basis-layer/basis-layer-state.service';
import { IsoMapService } from '@app/new-map/features/field-analysis/features/isoxml/state/iso-map.service';
import { IsoRepository } from '@app/new-map/features/field-analysis/features/isoxml/state/iso.repository';
import { SoilSampleShortName } from '@app/new-map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import { SoilSampleParameterIds } from '@app/new-map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-samples-analysis-parameter-ids.enum';
import { YieldPrognosisService } from '@app/new-map/features/prognosis/yield-prognosis/yield-prognosis.service';
import { CellRepository } from '@app/new-map/features/vra/state/cell.repository';
import { ColorService } from '@app/new-map/features/vra/state/color.state';
import { PrescriptionMapQuery } from '@app/new-map/features/vra/state/prescription-map/prescription-map.query';
import { translate } from '@app/new-map/helpers/utils/translate-util';
import { MapControlBiomassService } from '@app/shared/map-layer-controls/map-layer-control-biomass/map-control-biomass.service';
import { MapControlSoilsampleService } from '@app/shared/map-layer-controls/map-layer-control-soilsample/map-control-soilsample.service';
import { emissionTickDelay, filterNullish, filterNullOrEmpty } from '@app/shared/operators';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { OlLayerQuery } from '../layer/layer.query';
import { LayerId } from '../layer/layer.store';
import { Legend, LegendData } from './legend.model';
import { generateBiomassLegendData } from './util/legend-data-util';
import { getEntriesForSoilSampleType, getSoilSampleAdditionalTexts, getSoilSampleDecimalOverride } from './util/legend-soilsample-util';
import { createLegendStatistics } from './util/legend-statistics-util';

@Injectable({
  providedIn: 'root',
})
export class LegendService implements OnDestroy {
  private readonly _chooseableLegends$: Observable<Legend[]>;
  private readonly _chosenLegendSubject$ = new BehaviorSubject<string>('Crop');

  public readonly chosenLegendName$: Observable<string> = this._chosenLegendSubject$.asObservable();
  public readonly excludeOutliers$ = this.isoRepo.excludeOutliers$;

  private _legendsSubject = new BehaviorSubject<Legend[]>([]);
  private readonly _legends$ = this._legendsSubject.asObservable();

  private readonly _quantities$ = this.cellRepo.cells$.pipe(
    map((cells) =>
      cells
        .filter((cell) => cell.customLevel === false)
        .flatMap((cell) => cell.quantity)
        .filter((quantity): quantity is number => quantity !== undefined)
    )
  );

  private readonly _prescriptionMapStatistics$ = this.pmQuery.statistics$;
  private readonly _operationTypeGroup$ = this.pmQuery.operationTypeGroup$;
  private readonly _subs = new SubscriptionArray();

  private readonly _isNaesgaard$ = this.authService.isCurrentUserFromNaesgaard$;

  private readonly _basisLayerCategories$ = this.basisLayerStateService.basisLayerCategories$.pipe(
    withLatestFrom(this.farmStateService.selectedFarms$),
    map(([basisLayerCategories, farmIds]) => basisLayerCategories.filter((category) => category.farmId === farmIds[0].id))
  );

  private readonly _statistics$ = combineLatest([
    this._quantities$.pipe(filterNullOrEmpty()),
    this._prescriptionMapStatistics$,
    this._operationTypeGroup$,
  ]).pipe(map(([quantities, statistics, operationTypeGroup]) => createLegendStatistics(quantities, statistics, operationTypeGroup)));

  constructor(
    private layerQuery: OlLayerQuery,
    private farmStateService: FarmStateService,
    private cellRepo: CellRepository,
    private pmQuery: PrescriptionMapQuery,
    private colorService: ColorService,
    private decimalService: DecimalService,
    private mapControlBiomassService: MapControlBiomassService,
    private translateService: TranslateService,
    private soilSamplesRepoService: SoilSamplesRepoService,
    private mapControlSoilSamplesService: MapControlSoilsampleService,
    private yieldPrognosisService: YieldPrognosisService,
    private basisLayerStateService: BasisLayerStateService,
    private authService: BffAuthService,
    private isoRepo: IsoRepository,
    private isoMapService: IsoMapService
  ) {
    this._chooseableLegends$ = this.setupChooseableLegendsStream();
    this.initializeLegends();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public getChooseableLegends$(): Observable<Legend[]> {
    return this._chooseableLegends$;
  }

  public getLegendData$(legendName: string): Observable<LegendData[] | undefined> {
    return this._legends$.pipe(map((legends) => legends.find((l) => l.name === legendName)?.data));
  }

  public getLegend$(legendName: string): Observable<Legend | undefined> {
    return this._legends$.pipe(map((legends) => legends.find((l) => l.name === legendName)));
  }

  public setChosenLegend(legendName: string): void {
    if (this._legendsSubject.getValue().some((legend) => legend.name === legendName)) {
      this._chosenLegendSubject$.next(legendName);
    } else {
      console.warn(`Legend with name '${legendName}' does not exist.`);
    }
  }

  public updateAsAppliedLegend(legendData: LegendData[]) {
    const legend = this._legendsSubject.getValue().find((l) => l.name === 'AsApplied');
    if (legend) {
      legend.data = legendData;
      legend.unit = legendData[0].unit;
      legend.unitText = legendData[0].unitText;
      this._legendsSubject.next(this._legendsSubject.getValue());
    }
  }

  private generateIsoXmlLegendSubscription() {
    return this.isoMapService.legend$.pipe(filterNullOrEmpty()).subscribe((legendScaleSettings) => {
      const legend = this._legendsSubject.getValue().find((l) => l.name === 'IsoXml');

      const data: LegendData[] = legendScaleSettings.items.map((item) => {
        return {
          color: item.color,
          value: item.text,
          unit: (legendScaleSettings.unit as Unit).split('/')[0] as Unit,
          unitText: legendScaleSettings!.unit,
        };
      });
      const description = legendScaleSettings.title;

      if (legend) {
        legend.data = data;
        legend.unit = data[0].unit;
        legend.unitText = data[0].unitText;
        legend.description = description;
        this._legendsSubject.next(this._legendsSubject.getValue());
      }
    });
  }

  private initializeLegends() {
    const legends = this.createInitialLegends();
    this._legendsSubject.next(legends);

    this._subs.add(
      this.generateCropsLegendDataSubscription(),
      this.generateVraLegendSubscription(),
      this.generateBiomassLegendSubscription(),
      this.generateSoilSamplesLegendSubscription(),
      this.generateYieldFieldsLegendDataSubscription(),
      this.generateYieldPrognosisLegendDataSubscription(),
      this.generateBasisLayersLegendSubscription(),
      this.switchToNewlyAvailableLegendSubscription(),
      this.generateIsoXmlLegendSubscription()
    );

    this.translateService.onLangChange.pipe(first()).subscribe(() => {
      const legends = this.createInitialLegends();
      this._legendsSubject.next(legends);
    });
  }

  private createInitialLegends(): Legend[] {
    return [
      { name: 'VRA', layerBundle: LayerId.VRA_CELLS, data: this.createInitialLegendData('VRA') },
      { name: 'Biomass', layerBundle: LayerId.BIOMASS, data: this.createInitialLegendData('Biomass') },
      { name: 'Crop', layerBundle: LayerId.FIELDS, data: this.createInitialLegendData('Crop') },
      { name: 'SoilSamples', layerBundle: LayerId.SOILSAMPLE, data: this.createInitialLegendData('SoilSamples') },
      {
        name: 'Blight',
        layerBundle: LayerId.BLIGHT_FIELDS,
        data: this.createInitialLegendData('Blight'),
        additionalText: [
          translate(this.translateService, 'common.map-controls.legend.values.blight.days-since-spray'),
          translate(this.translateService, 'common.map-controls.legend.values.blight.blight-marker'),
        ],
      },
      {
        name: 'GrowthRegulation',
        layerBundle: LayerId.GROWTH_REGULATION,
        data: this.createInitialLegendData('GrowthRegulation'),
        additionalText: [translate(this.translateService, 'common.map-controls.legend.values.growth-regulation.choose-map')],
      },
      { name: 'YieldFields', layerBundle: LayerId.YIELD_PROGNOSIS, data: this.createInitialLegendData('YieldFields') },
      {
        name: 'YieldPrognosis',
        layerBundle: LayerId.VRA_PROGNOSIS,
        data: this.createInitialLegendData('YieldPrognosis'),
      },
      {
        name: 'BasisLayers',
        layerBundle: LayerId.BASIS_LAYERS,
        data: this.createInitialLegendData('BasisLayers'),
      },
      {
        name: 'AsApplied',
        layerBundle: [LayerId.AS_APPLIED, LayerId.AS_APPLIED_POINTS],
        data: this.createInitialLegendData('AsApplied'),
      },
      {
        name: 'IsoXml',
        layerBundle: [LayerId.ISOXML, LayerId.ISOXML_POINTS],
        data: this.createInitialLegendData('IsoXml'),
      },
      {
        name: 'SoilTypes',
        layerBundle: LayerId.GEO_SOIL,
        data: this.createInitialLegendData('SoilTypes'),
      },
      {
        name: 'Humus',
        layerBundle: LayerId.HUMUS,
        data: this.createInitialLegendData('Humus'),
      },
      {
        name: 'Clay',
        layerBundle: LayerId.CLAY,
        data: this.createInitialLegendData('Clay'),
      },
      {
        name: 'Dexter',
        layerBundle: LayerId.DEXTER,
        data: this.createInitialLegendData('Dexter'),
      },
    ];
  }

  private generateSoilSamplesLegendSubscription() {
    return combineLatest([
      this._isNaesgaard$,
      this.mapControlSoilSamplesService.selectedSoilsampleType$.pipe(startWith(SoilSampleParameterIds.ReactionNumber), filterNullish()),
    ])
      .pipe(
        filter(([isNaesgaard]) => !isNaesgaard),
        switchMap(([_, soilSampleType]) => {
          const shortHand = this.mapControlSoilSamplesService.getShortHandType(soilSampleType);
          if (!shortHand) return [];

          return this.soilSamplesRepoService.getSoilSampleLegend(shortHand, getEntriesForSoilSampleType(shortHand)).pipe(
            first(),
            map((legend) => ({ shortHand, legend }))
          );
        })
      )
      .subscribe(({ shortHand, legend }) => {
        const soilSamplesLegend = this.createSoilSamplesLegend(shortHand, legend.reverse());

        // Update existing legend
        const foundLegend = this._legendsSubject.getValue().find((l) => l.name === 'SoilSamples');
        if (foundLegend) {
          Object.assign(foundLegend, soilSamplesLegend);
          this._legendsSubject.next(this._legendsSubject.getValue());
        }
      });
  }

  private generateYieldFieldsLegendDataSubscription() {
    return this.yieldPrognosisService.cropsForLegend$.pipe(filterNullOrEmpty()).subscribe((crops) => {
      const legend = this._legendsSubject.getValue().find((l) => l.name === 'YieldFields');
      if (!legend) return;

      legend.data = crops.map(({ color, name }) => ({ color, value: name }));
      this._legendsSubject.next(this._legendsSubject.getValue());
    });
  }

  private generateYieldPrognosisLegendDataSubscription() {
    return this.yieldPrognosisService.legendSettings$.pipe(filterNullish()).subscribe((legendSettings) => {
      const legend = this._legendsSubject.getValue().find((l) => l.name === 'YieldPrognosis');
      if (!legend) return;

      const cropNormNumber = this.yieldPrognosisService.selectedDirectorateCropNormNumber;
      const unitPrefix = this.getUnitPrefix(cropNormNumber);

      legend.unit = legendSettings.summaryLines?.first()?.unit as Unit;
      legend.unitText = `${legendSettings.summaryLines?.first()?.unit}${unitPrefix}/ha`;

      legend.data = legendSettings.items.map((item) => ({
        color: item.color,
        value: item.text,
        unitText: legend.unitText,
      }));
      this._legendsSubject.next(this._legendsSubject.getValue());
    });
  }

  private generateCropsLegendDataSubscription() {
    return this.farmStateService.fields$
      .pipe(
        map((fields) => {
          const crops = fields?.map((field) => field.crops).flat();
          const uniqueCrops = _.uniqBy(crops, 'cropName');
          return uniqueCrops.map((crop) => ({
            color: crop.cropColor,
            value: crop.cropName,
          }));
        })
      )
      .subscribe((items) => {
        const cropLegend = this._legendsSubject.getValue().find((l) => l.name === 'Crop');
        items.sort((a, b) => a.value.localeCompare(b.value));
        if (cropLegend) {
          cropLegend.data = items;
          this._legendsSubject.next(this._legendsSubject.getValue());
        }
      });
  }

  private generateBiomassLegendSubscription() {
    return this.mapControlBiomassService.selectedDate$.subscribe((date) => {
      const harvestYearText = this.translateService.instant('common.harvestYear');

      const legend = this._legendsSubject.getValue().find((l) => l.name === 'Biomass');
      if (legend) {
        (legend.description = harvestYearText + ' ' + date?.year), this._legendsSubject.next(this._legendsSubject.getValue());
      }
    });
  }

  private generateVraLegendSubscription() {
    return combineLatest([this.colorService.colors$, this._statistics$, this.cellRepo.customLevels$, this.pmQuery.unit$])
      .pipe(
        emissionTickDelay(),
        map(([colors, { min, max, avg, total }, cells, unit]) => {
          const showOneLegendEntry = Math.abs(max - min) <= 0.1;
          const steps = showOneLegendEntry ? 1 : colors.length - 1;
          const range = showOneLegendEntry ? 1 : colors.length;

          const interval = (max - min) / steps;

          unit = unit?.trim() as Unit;
          unit = (unit && ((unit.charAt(0).toLowerCase() + unit.slice(1)) as Unit)) || null;
          unit = unit?.replace('liter', 'l') as Unit;

          const gradiation = _.range(range).map<LegendColorValue>((i) => ({
            color: colors[i],
            value: this.decimalService.format(max - interval * i)!, // start high, end low
            customLevel: false,
            unit: unit,
            unitText: unit + '/ha',
          }));

          const customLevels = cells.map<LegendColorValue>((cell) => ({
            color: cell.color!,
            value: this.decimalService.format(cell.quantity)!,
            customLevel: true,
            unit: unit,
            unitText: unit + '/ha',
          }));

          const legend: Legend = {
            name: 'VRA',
            layerBundle: LayerId.VRA_CELLS,
            data: [...gradiation, ...customLevels].sort((a, b) => b.value - a.value),
            totalAmount: total,
            averageAmount: avg,
            unit: unit as Unit,
            unitText: unit + '/ha',
          };

          return legend;
        })
      )
      .subscribe((legend) => {
        const vraLegend = this._legendsSubject.getValue().find((l) => l.name === 'VRA');
        legend.unit = legend.unit?.trim() as Unit;
        legend.unitText = legend.unitText?.trim() as Unit;
        legend.unit = (legend.unit && ((legend.unit.charAt(0).toLowerCase() + legend.unit.slice(1)) as Unit)) || null;
        legend.unitText = (legend.unitText && ((legend.unitText.charAt(0).toLowerCase() + legend.unitText.slice(1)) as Unit)) || undefined;
        legend.unit = legend.unit?.replace('liter', 'l') as Unit;
        legend.unitText = legend.unitText?.replace('liter', 'l') as Unit;

        if (vraLegend) {
          vraLegend.data = legend.data;
          vraLegend.totalAmount = legend.totalAmount;
          vraLegend.averageAmount = legend.averageAmount;
          vraLegend.unit = legend.unit;
          vraLegend.unitText = legend.unitText;
          this._legendsSubject.next(this._legendsSubject.getValue());
        }
      });
  }

  private generateBasisLayersLegendSubscription() {
    return this._basisLayerCategories$.subscribe((categories) => {
      const legend = this._legendsSubject.getValue().find((l) => l.name === 'BasisLayers');
      if (!legend) return;

      const basisLayerData = categories.map((category) => ({
        color: category.color,
        value: category.name,
      }));

      legend.data = basisLayerData as LegendData[];
      this._legendsSubject.next(this._legendsSubject.getValue());
    });
  }

  private switchToNewlyAvailableLegendSubscription() {
    let previousLegendNames = new Set<string>();

    return this.getChooseableLegends$().subscribe((currentLegends) => {
      const currentLegendNames = new Set(currentLegends.map((legend) => legend.name));
      const newlyAvailableLegends = currentLegends.filter((legend) => !previousLegendNames.has(legend.name));

      if (newlyAvailableLegends.length > 0) {
        this.setChosenLegend(newlyAvailableLegends[0].name);
      }

      previousLegendNames = currentLegendNames; // Update the previous legend names
    });
  }

  private createSoilSamplesLegend(shortHand: SoilSampleShortName, legendData: any[]): Legend {
    const legend = {
      name: 'SoilSamples',
      description: this.translateService.instant(`common.map-controls.soilsample.types.${shortHand}`),
      layerBundle: LayerId.SOILSAMPLE,
      data: this.processSoilSampleLegendData(legendData, shortHand),
      additionalText: getSoilSampleAdditionalTexts(shortHand, this.translateService),
    } as Legend;
    return legend;
  }

  private processSoilSampleLegendData(data: any[], shortHand: SoilSampleShortName): any[] {
    const uniqueValues = new Set();
    return data.map((entry) => {
      entry.decimalOverride = getSoilSampleDecimalOverride(shortHand);
      if (uniqueValues.has(entry.value)) {
        entry.value = '';
      } else {
        uniqueValues.add(entry.value);
      }
      return entry;
    });
  }

  private getUnitPrefix(cropNormNumber: number): string {
    switch (cropNormNumber) {
      case 216:
      case 218:
        return ' ' + this.translateService.instant('main.yieldPrognosis.maize.dryMatter');
      case 11:
      case 13:
        return ' ' + this.translateService.instant('main.yieldPrognosis.wheat.grain');
      default:
        return '';
    }
  }

  private setupChooseableLegendsStream(): Observable<Legend[]> {
    return this.layerQuery.mapLayersByActivePage$.pipe(
      map((layers) =>
        this._legendsSubject.getValue().filter((legend) => {
          const layerBundles = Array.isArray(legend.layerBundle) ? legend.layerBundle : [legend.layerBundle];
          return layers.some((layer) => layer.enabled && layerBundles.includes(layer.id)) && legend.data && legend.data.length > 0;
        })
      )
    );
  }

  private createInitialLegendData(legendName: string): LegendData[] {
    const dataMap: { [key: string]: LegendData[] } = {
      VRA: [],
      Biomass: generateBiomassLegendData(),
      Crop: [],
      SoilSamples: [],
      Blight: [
        { color: '#4f734a', value: this.translateService.instant('common.map-controls.legend.values.blight.minimal') },
        { color: '#f9e067', value: this.translateService.instant('common.map-controls.legend.values.blight.low') },
        { color: '#fc995c', value: this.translateService.instant('common.map-controls.legend.values.blight.medium') },
        { color: '#f2464a', value: this.translateService.instant('common.map-controls.legend.values.blight.high') },
      ],
      GrowthRegulation: [
        { color: '#4f734a', value: this.translateService.instant('common.map-controls.legend.values.growth-regulation.low') },
        { color: '#f9e067', value: this.translateService.instant('common.map-controls.legend.values.growth-regulation.medium') },
        { color: '#f2464a', value: this.translateService.instant('common.map-controls.legend.values.growth-regulation.high') },
        { color: '#FFFFFF', value: this.translateService.instant('common.map-controls.legend.values.growth-regulation.no-data') },
      ],
      YieldFields: [],
      YieldPrognosis: [],
      BasisLayers: [],
      AsApplied: [{ color: '#4f734a', value: 'test' }],
      IsoXml: [],
      SoilTypes: [
        { color: '#FFF0A6', value: this.translateService.instant('common.map-controls.legend.values.jb.coarse-sandy-soil') },
        { color: '#FFCEB5', value: this.translateService.instant('common.map-controls.legend.values.jb.fine-sandy-soil') },
        { color: '#FCBB60', value: this.translateService.instant('common.map-controls.legend.values.jb.coarse-clay-mixed-sandy-soil') },
        { color: '#FAA80F', value: this.translateService.instant('common.map-controls.legend.values.jb.fine-clay-mixed-clay-soil') },
        { color: '#C99C57', value: this.translateService.instant('common.map-controls.legend.values.jb.coarse-sand-mixed-clay-soil') },
        { color: '#A16A03', value: this.translateService.instant('common.map-controls.legend.values.jb.fine-sand-mixed-clay-soil') },
        { color: '#825511', value: this.translateService.instant('common.map-controls.legend.values.jb.clay-soil') },
        { color: '#5B9636', value: this.translateService.instant('common.map-controls.legend.values.jb.heavy-clay-soil') },
        { color: '#4C6300', value: this.translateService.instant('common.map-controls.legend.values.jb.very-heavy-clay-soil') },
        { color: '#4A4A06', value: this.translateService.instant('common.map-controls.legend.values.jb.silt-soil') },
        { color: '#C8E650', value: this.translateService.instant('common.map-controls.legend.values.jb.humus') },
      ],
      Dexter: [
        { color: '#95f9c7', value: this.translateService.instant('common.map-controls.legend.values.dexter.1'), forceString: true },
        { color: '#00fe00', value: this.translateService.instant('common.map-controls.legend.values.dexter.2'), forceString: true },
        { color: '#31cc31', value: this.translateService.instant('common.map-controls.legend.values.dexter.3'), forceString: true },
        { color: '#007f00', value: this.translateService.instant('common.map-controls.legend.values.dexter.4'), forceString: true },
      ],
      Humus: [
        { color: '#fcf3e2', value: this.translateService.instant('common.map-controls.legend.values.humus.1'), forceString: true },
        { color: '#f1edd4', value: this.translateService.instant('common.map-controls.legend.values.humus.2'), forceString: true },
        { color: '#feeabe', value: this.translateService.instant('common.map-controls.legend.values.humus.3'), forceString: true },
        { color: '#e1d9ae', value: this.translateService.instant('common.map-controls.legend.values.humus.4'), forceString: true },
        { color: '#d0b896', value: this.translateService.instant('common.map-controls.legend.values.humus.5'), forceString: true },
        { color: '#bc8e81', value: this.translateService.instant('common.map-controls.legend.values.humus.6'), forceString: true },
        { color: '#b58b3f', value: this.translateService.instant('common.map-controls.legend.values.humus.7'), forceString: true },
      ],
      Clay: [
        { color: '#00007f', value: this.translateService.instant('common.map-controls.legend.values.clay.1'), forceString: true },
        { color: '#0000cc', value: this.translateService.instant('common.map-controls.legend.values.clay.2'), forceString: true },
        { color: '#005efd', value: this.translateService.instant('common.map-controls.legend.values.clay.3'), forceString: true },
        { color: '#00befd', value: this.translateService.instant('common.map-controls.legend.values.clay.4'), forceString: true },
        { color: '#1cfde0', value: this.translateService.instant('common.map-controls.legend.values.clay.5'), forceString: true },
        { color: '#7cfd80', value: this.translateService.instant('common.map-controls.legend.values.clay.6'), forceString: true },
        { color: '#dcfd20', value: this.translateService.instant('common.map-controls.legend.values.clay.7'), forceString: true },
        { color: '#fdc200', value: this.translateService.instant('common.map-controls.legend.values.clay.8'), forceString: true },
        { color: '#fd6200', value: this.translateService.instant('common.map-controls.legend.values.clay.9'), forceString: true },
        { color: '#fd0200', value: this.translateService.instant('common.map-controls.legend.values.clay.10'), forceString: true },
      ],
    };

    return dataMap[legendName] || [];
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import Feature from 'ol/Feature';
import { SelectEvent } from 'ol/interaction/Select';
import { Observable } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { HotspotsService } from '../hotspots.service';
import { CreateHotspotComponent } from './create-hotspot/create-hotspot.component';
import { HotspotDetailsService } from './hotspot-details/hotspot-details.service';
import { ShownComponentEnum } from './hotspots-sidedrawer-showncomponent.enum';
import { HotspotTypes } from './shape-file-import/interfaces/hotspot-types.enum';

@Component({
  selector: 'app-hotspots-side-drawer',
  templateUrl: './hotspots-side-drawer.component.html',
  styleUrls: ['./hotspots-side-drawer.component.scss'],
})

/**
 * Handles interaction between the map and hotspot features on the map.
 * Details about the hotspot (data fetched from the backend) are saved on the hot spot map features.
 * When a hotspot is clicked this data is passed to the hotspot-details component which handles editing the data.
 * This component (hotspot map feature) must know about those changes to update the data on hotspot map features.
 */
export class HotspotsSideDrawerComponent implements OnDestroy, OnInit {
  @ViewChild('createHotspot', { static: true }) public createHotspotComponent?: CreateHotspotComponent;
  public readonly selectableLayers = [LayerId.HOTSPOT_MARKERS, LayerId.HOTSPOTS];
  public shownComponent$!: Observable<ShownComponentEnum>;
  public components = ShownComponentEnum;

  // temp list for modified hotspots
  public hotspotToAdd!: HotspotDto;
  public loadingMessage$ = this.hotspotsService.loadingMessage$;
  public isLoading$ = this.hotspotsService.isHotspotsLoading$;
  public isDrawingHotspot$ = this.hotspotsService.isHotpotDrawing$;
  public isSelectDisabled = false;

  public get hotspotSubTypes(): HotspotSubType[] {
    return this.hotspotsService.hotspotSubTypes;
  }
  public set hotspotSubTypes(v: HotspotSubType[]) {
    this.hotspotsService.hotspotSubTypes = v;
  }

  public get selectedHotspot(): HotspotDto | undefined {
    return this.hotspotsService.selectedHotspot;
  }

  public set selectedHotspot(v: HotspotDto) {
    this.hotspotsService.selectedHotspot = v;
  }

  public get hotspotTypes(): HotspotType[] {
    return this.hotspotsService.hotspotTypes;
  }

  public set hotspotTypes(v: HotspotType[]) {
    this.hotspotsService.hotspotTypes = v;
  }

  private isDrawingHotspot = false;
  private _subs = new SubscriptionArray();

  constructor(
    private hotspotsService: HotspotsService,
    private hotspotDetailsService: HotspotDetailsService,
    private sideDrawerService: SideDrawerOverlayService,
    private mapService: OlMapService
  ) {}

  public ngOnInit() {
    this.shownComponent$ = this.hotspotsService.shownComponentState$.pipe(map((sc) => sc.shownComponent));
    this._subs.add(this.isDrawingHotspot$.subscribe((isDrawing: boolean) => (this.isDrawingHotspot = isDrawing)));

    this.mapService.mapReady$.pipe(take(1)).subscribe(() => {
      this.initSubscriptions();
    });
  }

  public ngOnDestroy() {
    this.hotspotsService.isDirty = false;
    this._subs.unsubscribe();
  }

  public onShapeFileImported(hotspots: HotspotDto[]) {
    this.hotspotsService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
    this.hotspotsService.setHotspots(hotspots);
    this.hotspotsService.addHotspotsToMap(hotspots);
    this.hotspotsService.refreshTypesAndSubtypes();
    this.hotspotsService.isDirty = false;
  }

  public onCloseHotspotDetails(isNewHotspot: boolean) {
    this.mapService.e2eSetCanvasReady(false);
    this.hotspotsService.onCloseHotspotDetails(isNewHotspot, this.createHotspotComponent);
    this.hotspotsService.isDirty = false;
    this.mapService.removeModifyInteraction();
    this.isSelectDisabled = false;
  }

  public onCloseShapeFileImportClick() {
    this.mapService.removeModifyInteraction();
  }

  public onHotspotCreated(createdHotspot: HotspotDto) {
    this.hotspotsService.addToHotspotListAndSet(createdHotspot);
    this.hotspotsService.isDirty = false;
    this.isSelectDisabled = false;
  }

  public onHotspotUpdated(updatedHotspot: HotspotDto) {
    this.hotspotsService.addToHotspotListAndSet(updatedHotspot);
    this.hotspotsService.isDirty = false;
    this.isSelectDisabled = false;
  }

  public onHotspotDeleted(deletedHotspot: HotspotDto) {
    this.hotspotsService.onHotspotDeleted(deletedHotspot);
    this.hotspotsService.isDirty = false;
    this.isSelectDisabled = false;
  }

  private initSubscriptions() {
    this.hotspotsService.setupSelectedHotspotQueryParamSync();
    this.hotspotsService.setupHotspotsSubscription();
  }

  public onHotspotClick($event: SelectEvent) {
    if ($event?.selected?.length) {
      const { isDirty, selectedHotspotMapFeature, selectedHotspot } = this.hotspotsService;

      this.hotspotDetailsService
        .onSideDrawerChangeRequest(isDirty, this.isDrawingHotspot) // if hotspottype is selected then ignore
        .pipe(
          first(),
          filter((isHotSpotChangeAllowed) => {
            if (isHotSpotChangeAllowed === false) {
              if (isDirty) {
                const hotspotFeature = this.hotspotsService.findCompanionFeature(selectedHotspotMapFeature, LayerId.HOTSPOTS);
                const markerFeature = this.hotspotsService.findCompanionFeature(selectedHotspotMapFeature, LayerId.HOTSPOT_MARKERS);
                this.hotspotsService.deselectHotspot($event.selected[0]);

                if (hotspotFeature && markerFeature) this.hotspotsService.setSelectedHotspot(hotspotFeature, markerFeature);
                this.hotspotsService.setHotspotFeatureState(selectedHotspotMapFeature, true);
              }
            } else {
              if (isDirty) {
                this.hotspotsService.revertHotspots(undefined);
                this.hotspotsService.isDirty = false;
              }
              this.hotspotsService.deselectHotspot($event.selected[0]);
              this.hotspotsService.onHotspotClick($event.selected[0]);
            }

            return isHotSpotChangeAllowed;
          })
        )
        .subscribe();
    }
  }

  public onNewHotspotDrawn($event: { drawnFeature: Feature; markerFeature: Feature; hotspotTypeId: HotspotTypes }) {
    this.hotspotsService.onNewHotspotDrawn($event.drawnFeature, $event.markerFeature, $event.hotspotTypeId);
    this.hotspotsService.setShownComponentState(ShownComponentEnum.hotspotDetailsComponent);
    this.hotspotsService.isDirty = true;
    this.sideDrawerService.showAllSideDrawers();
  }

  public onImportShapeFileClicked() {
    this.hotspotsService.setShownComponentState(ShownComponentEnum.importShapefileComponent);
  }

  public onCreateHotspotGroupClicked() {
    this.hotspotsService.setShownComponentState(ShownComponentEnum.hotspotGroupComponent);
  }
}

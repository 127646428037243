import { Injectable } from '@angular/core';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { MetadataGeometryDto } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import colormap from 'colormap';
import { combineLatest, map, shareReplay, tap } from 'rxjs';
import { createPrescriptionColorMap } from '../domain/legend';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

export type GradiationLevel = number;

interface GradiationProps {
  selected: GradiationLevel | null;
  colors: string[];
}

@Injectable({ providedIn: 'root' })
export class ColorService {
  constructor(private query: PrescriptionMapQuery) {}

  private readonly _store = createStore({ name: 'vra-color' }, withProps<GradiationProps>({ selected: null, colors: [] }));

  // ! This should maybe be per user instead
  private readonly _persistenceKey = `vra-color`;

  private readonly _persist = persistState(this._store, {
    key: this._persistenceKey,
    storage: localStorageStrategy,
  });

  private readonly _selectedLevel$ = this._store.pipe(select((state) => state.selected));

  private readonly _init$ = combineLatest([this._selectedLevel$, this.query.operationTypeGroup$]).pipe(
    map(([level, group]) => createPrescriptionColorMap(group, level)),
    tap((colors) => this._store.update((state) => ({ ...state, colors }))),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public readonly colors$ = this._store.pipe(select(({ colors }) => colors));

  public init() {
    return this._init$;
  }

  public setGradiationLevel(level: GradiationLevel) {
    this._store.update((state) => ({ ...state, selected: level }));
  }

  public resetGradiationLevel() {
    this._store.update((state) => ({ ...state, selected: null }));
  }

  public setAsAppliedPointsAndCellColors(metadataGeometries: MetadataGeometryDto[] | undefined, legend: LegendColorValue[]) {
    const reversedLegend = legend.reverse();

    metadataGeometries?.forEach((geometry) => {
      let legendForCell: LegendColorValue | undefined;

      legendForCell = reversedLegend.find((legendEntry) => +legendEntry.value >= (geometry.quantity || 0));

      geometry.color = legendForCell?.color;
    });
  }

  public getAsAppliedLegendColorValues(metadataGeometry: MetadataGeometryDto[] | undefined): LegendColorValue[] {
    const cellQuantities = metadataGeometry?.map((cell) => cell.quantity) ?? [];

    const legend = [];

    const maxCellQuantity = this.max(cellQuantities);
    const minCellQuantity = this.min(cellQuantities);

    const levels = 20;

    const colors = colormap({
      colormap: 'density',
      nshades: levels,
      format: 'hex',
      alpha: 1,
    });

    const legendEntriesToCreate = Math.abs(maxCellQuantity - minCellQuantity) <= 0.1 ? 1 : levels;
    const steps = (maxCellQuantity - minCellQuantity) / legendEntriesToCreate;

    for (let step = 0, colorIndex = 0; step < legendEntriesToCreate; step++, colorIndex = step) {
      const legendColorValueForStep = {
        color: colors[colorIndex],
        value: maxCellQuantity - steps * step,
      };

      legend.push(legendColorValueForStep);
    }

    return legend.sort((a, b) => +b.value - +a.value) as LegendColorValue[];
  }

  /**
   * Returns the largest number in the array
   * Can be used on very large arrays when Math.max throws Maximum call size exceeded
   * @param cellQuantities
   */
  private max(cellQuantities: (number | undefined)[]) {
    let length = cellQuantities.length;
    let max: number = -Infinity;

    while (length--) {
      if (typeof cellQuantities[length] === 'number' && cellQuantities[length]! > max) {
        max = cellQuantities[length]!;
      }
    }

    return max;
  }

  /**
   * Returns the smallest number in the array
   * Can be used on very large arrays when Math.min throws Maximum call size exceeded
   * @param cellQuantities
   */
  private min(cellQuantities: (number | undefined)[]) {
    let length = cellQuantities.length;
    let min: number = Infinity;

    while (length--) {
      if (typeof cellQuantities[length] === 'number' && cellQuantities[length]! < min) {
        min = cellQuantities[length]!;
      }
    }
    return min;
  }
}

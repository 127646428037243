import { Injectable } from '@angular/core';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';

@Injectable({
  providedIn: 'root',
})
export class FieldPlanService {
  constructor() {}

  public getFieldBlockCoverFlowLayersItem(): MapCoverFlowItem {
    const coverFlowLayersItem: MapCoverFlowItem = new MapCoverFlowItem({
      mapCoverFlowLayersId: LayerId.FIELD_BLOCKS,
      displayName: 'main.fieldmap.layers.fieldblocks',
      isVisible: false,
      name: 'fieldblocks',
    });
    return coverFlowLayersItem;
  }
}

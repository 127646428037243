import { Injectable, OnDestroy } from '@angular/core';
import { SatelliteImageDate } from '@app/new-map/features/field-analysis/features/ndvi-feature/interfaces/ndvi-image-date.interface';
import { NdviService } from '@app/new-map/features/field-analysis/features/ndvi-feature/service/ndvi.service';
import { filterNullOrEmpty } from '@app/shared/operators';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, first, switchMap, tap } from 'rxjs';

export enum BiomassImageSource {
  clearsky = 1,
  standard = 2,
}
@Injectable({
  providedIn: 'root',
})
export class MapControlBiomassService implements OnDestroy {
  constructor(
    private ndviService: NdviService,
    private farms: FarmStateService
  ) {}

  private allData_ = new BehaviorSubject<SatelliteImageDate[]>([]);
  private yearsSubject_ = new BehaviorSubject<string[] | undefined>(undefined);
  private monthsSubject_ = new BehaviorSubject<string[] | undefined>(undefined);
  private datesSubject_ = new BehaviorSubject<SatelliteImageDate[] | undefined>(undefined);
  private imagesourceSubject_ = new BehaviorSubject<BiomassImageSource>(BiomassImageSource.clearsky);
  private selectedDateSubject_ = new BehaviorSubject<DateTime | undefined>(undefined);

  private _subs = new SubscriptionArray();

  public year$ = this.yearsSubject_.asObservable();
  public months$ = this.monthsSubject_.asObservable();
  public dates$ = this.datesSubject_.asObservable();
  public imagesource$ = this.imagesourceSubject_.asObservable();
  public allData$ = this.allData_.asObservable();
  public selectedDate$ = this.selectedDateSubject_.asObservable();

  private _resetFormSubject = new BehaviorSubject<boolean>(false);
  public resetForm$ = this._resetFormSubject.asObservable();

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public setSelectedDate(date: DateTime | undefined) {
    this.selectedDateSubject_.next(date);
  }

  public setImageSource(source: BiomassImageSource) {
    this.imagesourceSubject_.next(source);
  }

  public getStandardNDVIDates() {
    this._subs.add(
      this.farms.selectedFarms$
        .pipe(
          switchMap((farms) => this.ndviService.getNDVIImageDates(farms!)),
          filterNullOrEmpty()
        )
        .subscribe((dates) => {
          this.allData_.next(dates);
          this.updateYearMonthAndDates(dates);
        })
    );
  }

  public getCloudlessNDVIDates() {
    this._subs.add(
      this.farms.selectedFarms$
        .pipe(
          switchMap((farms) => this.ndviService.getClearSkyImageDates(farms!)),
          filterNullOrEmpty()
        )
        .subscribe((dates) => {
          this.allData_.next(dates);
          this.updateYearMonthAndDates(dates);
        })
    );
  }

  public updateMonths(year: string) {
    this.allData$
      .pipe(
        first(),
        tap((dates) => {
          const months = this.getUniqueMonthsForYear(dates, year);
          this.monthsSubject_.next(months);
          const lastMonth = months.first()!;
          const udates = this.getUniqueDates(dates, year, lastMonth);
          this.datesSubject_.next(udates);
        })
      )
      .subscribe();
  }

  public updateDates(year: string, month: string) {
    this.allData$
      .pipe(
        first(),
        tap((dates) => {
          const udates = this.getUniqueDates(dates, year, month);
          this.datesSubject_.next(udates);
        })
      )
      .subscribe();
  }

  public resetForm() {
    this._resetFormSubject.next(true);
  }

  private updateYearMonthAndDates(dates: SatelliteImageDate[]) {
    const years = this.getUniqueYears(dates);
    this.yearsSubject_.next(years);
    if (years !== undefined) {
      const lastYear = years.first()!;
      const months = this.getUniqueMonthsForYear(dates, lastYear);
      this.monthsSubject_.next(months);
      const lastMonth = months.first()!;
      const udates = this.getUniqueDates(dates, lastYear, lastMonth);
      this.datesSubject_.next(udates);
    }
  }

  private getUniqueYears(dates: SatelliteImageDate[]): string[] {
    const years = new Set<string>();

    dates
      .map((wrappedDate) => wrappedDate.date)
      .forEach((date) => {
        const year = date.year;
        years.add(year.toString());
      });

    return Array.from(years).sort((a, b) => parseInt(b) - parseInt(a));
  }
  private getUniqueMonthsForYear(dates: SatelliteImageDate[], year: string): string[] {
    const months = new Set<string>();

    dates
      .map((wrappedDate) => wrappedDate.date)
      .forEach((date) => {
        if (date.year.toString() === year) {
          const month = date.month.toString();
          months.add(month);
        }
      });

    return Array.from(months).sort((a, b) => parseInt(b) - parseInt(a));
  }

  private getUniqueDates(dates: SatelliteImageDate[], year: string, month: string): SatelliteImageDate[] {
    const days = new Set<SatelliteImageDate>();

    dates.forEach((wrappedDate) => {
      if (wrappedDate.date.year.toString() === year && wrappedDate.date.month.toString() === month) {
        days.add(wrappedDate);
      }
    });

    return Array.from(days).sort((a, b) => b.date.toMillis() - a.date.toMillis());
  }

  public static getCloudImageSource(cloudCoveragePct: number): string {
    if (cloudCoveragePct <= 0) {
      return 'assets/images/weather-symbols/01d.png';
    }
    if (cloudCoveragePct < 50) {
      return 'assets/images/weather-symbols/02d.png';
    }
    if (cloudCoveragePct < 90) {
      return 'assets/images/weather-symbols/03d.png';
    }
    if (cloudCoveragePct <= 100) {
      return 'assets/images/weather-symbols/04.png';
    } else {
      return '';
    }
  }
}

import { Injectable } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { BlightInfoForField } from '@app/core/interfaces/blight-info-for-field.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { PotatoFieldRepoService } from '@app/core/repositories/blight/potato-field-repo.service';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { OlMapService } from '@app/new-map/services/map/ol-map.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { finalize, first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlightStateService {
  public readonly DEFAULT_MONTH = Month.June;
  public readonly DEFAULT_DAY = 15;

  public activeFeature?: Feature<Geometry>;

  private _potatoFieldsSubject = new ReplaySubject<Field[]>(1);

  private _hydrateLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private _blightInfoLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private _blightPolygonsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private _blightFieldsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private _blightInfectionRegistrationLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private _selectedBlightDate$!: BehaviorSubject<Date>;

  constructor(
    private potatoFieldRepoService: PotatoFieldRepoService,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private _mapService: OlMapService
  ) {
    this.harvestYearStateService.harvestYear$
      .pipe(
        first(),
        switchMap((harvestYear) => {
          return this.harvestYearStateService.isCurrentHarvestYear$.pipe(
            first(),
            map((isCurrent) => ({ harvestYear, isCurrent }))
          );
        })
      )
      .subscribe(({ harvestYear, isCurrent }) => {
        // If the selected harvest year is the current harvest year, the default date is today
        if (isCurrent) {
          this._selectedBlightDate$ = new BehaviorSubject<Date>(new Date());
        } else {
          this._selectedBlightDate$ = new BehaviorSubject<Date>(
            DateTime.fromObject({ year: harvestYear, month: this.DEFAULT_MONTH, day: this.DEFAULT_DAY }).toJSDate()
          );
        }
      });
  }

  public get loading$(): Observable<boolean> {
    return combineLatest([
      this._hydrateLoadingSubject$.asObservable(),
      this._blightInfoLoadingSubject$.asObservable(),
      this._blightPolygonsLoadingSubject$.asObservable(),
      this._blightFieldsLoadingSubject$.asObservable(),
      this._blightInfectionRegistrationLoadingSubject$.asObservable(),
    ]).pipe(map((args) => args.some(Boolean)));
  }

  public get potatoFields$(): Observable<Field[]> {
    return this._potatoFieldsSubject.asObservable();
  }

  public set selectedBlightDate(selectedBlightDate: Date) {
    this._selectedBlightDate$.next(selectedBlightDate);
  }

  public get selectedBlightDate$(): Observable<Date> {
    return this._selectedBlightDate$.asObservable();
  }

  public hydrateFields(): Observable<Field[]> {
    this._hydrateLoadingSubject$.next(true);
    return combineLatest([this.farmStateService.selectedFarmIds$, this.harvestYearStateService.harvestYear$]).pipe(
      switchMap(([farmIds, harvestYear]) => this.potatoFieldRepoService.getPotatoFields(farmIds, harvestYear!)),
      tap((fields) => {
        this._potatoFieldsSubject.next(fields);
        this._hydrateLoadingSubject$.next(false);
      })
    );
  }

  public getBlightInfoForField(fieldId: number): Observable<BlightInfoForField> {
    return combineLatest([this.farmStateService.selectedFarmIds$, this.harvestYearStateService.harvestYear$]).pipe(
      first(),
      switchMap(([farmIds, harvestYear]) => this.potatoFieldRepoService.getBlightInfoForField(farmIds, harvestYear!, fieldId))
    );
  }

  public getBlightPolygonsForMap(date: Date): Observable<Feature<Geometry>[]> {
    this._blightPolygonsLoadingSubject$.next(true);
    return this.potatoFieldRepoService.getBlightPolygonsForMap(date).pipe(
      map((blightPolygons) => {
        return blightPolygons.map((blightPolygon) => {
          const feature = FeatureUtil.getMapFeature(
            LayerId.BLIGHT_POLYGONS,
            WKTUtil.getGeometryTypeFromWktString(blightPolygon.geometry),
            WKTUtil.getCoordinatesFromWktString(blightPolygon.geometry)
          );
          feature.set('pressure', blightPolygon.pressure);
          feature.set('color', blightPolygon.color + '80');
          feature.setStyle(new Style({ fill: new Fill({ color: blightPolygon.color }), stroke: new Stroke({ color: '#fff', width: 1 }) }));
          return feature;
        });
      }),
      finalize(() => this._blightPolygonsLoadingSubject$.next(false))
    );
  }

  public getBlightFieldsForMap(date: Date, farmIds: number[], harvestYear: number) {
    this._blightFieldsLoadingSubject$.next(true);
    return this.potatoFieldRepoService.getBlightFieldsForMap(date, farmIds, harvestYear).pipe(
      map((blightFields) => {
        return blightFields.map((blightField) => {
          const feature = FeatureUtil.getMapFeature(
            LayerId.BLIGHT_POLYGONS,
            WKTUtil.getGeometryTypeFromWktString(blightField.geometry),
            WKTUtil.getCoordinatesFromWktString(blightField.geometry)
          );
          feature.set('pressure', blightField.pressure);
          feature.set('color', blightField.color);
          feature.setStyle(new Style({ fill: new Fill({ color: blightField.color }), stroke: new Stroke({ color: '#FFF', width: 1 }) }));
          return feature;
        });
      }),
      finalize(() => this._blightFieldsLoadingSubject$.next(false))
    );
  }

  public saveBlightInfectionRegistration(farmId: number, cropId: number, date: Date): Observable<any> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      switchMap((harvestYear) =>
        this.potatoFieldRepoService.saveBlightInfectionRegistration({
          farmId: farmId,
          cropId: cropId,
          harvestYear: harvestYear!,
          date: date,
        })
      )
    );
  }

  public getBlightInfectionDataInfo() {
    return this.potatoFieldRepoService.getBlightInfectionDataInfo().pipe(map((blightInfectionDataInfo) => blightInfectionDataInfo));
  }

  // Find the active feature in the layer and select it. This is needed because the feature is not the same object as the one in the layer after refetch.
  public reselectActiveFeature() {
    const activeFeatureInLayer = this._mapService.getFeaturesFromLayer(LayerId.BLIGHT_FIELDS).find((feature) => {
      return feature.get('field').id === this.activeFeature?.get('field').id;
    });
    if (activeFeatureInLayer) {
      this._mapService.selectFeatures([activeFeatureInLayer]);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AsAppliedService } from '@app/new-map/features/field-analysis/features/as-applied/as-applied.service';
import { MetadataView } from '@app/new-map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/metadata-view';
import { UtmCardProjectionService } from '@app/new-map/features/hotspots/hotspot-side-drawer/shape-file-import/service/utm-card-projection.service';
import { ShapefileColumnDto, TemporalShapefileConfigInput } from '../../metadata-parent';
@Component({
  selector: 'app-as-applied-config-file',
  templateUrl: './as-applied-config-file.component.html',
  styleUrls: ['../metadata-view.scss'],
  animations: [MetadataView.FLY_IN_OUT],
})
export class AsAppliedConfigFileComponent implements OnInit {
  @Input() columnNames?: ShapefileColumnDto[];
  @Input() srid?: number;
  @Input() index?: number;
  @Input() fileNames?: string[];

  public projections = this.utmCardProjectionService.getCardProjections();
  public canBeRemoved$ = this.asAppliedService.configFormCanBeRemoved$;
  public form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private asAppliedService: AsAppliedService,
    private utmCardProjectionService: UtmCardProjectionService
  ) {}

  public ngOnInit(): void {
    if (this.asAppliedService.shapeFileAnalysis) {
      const configsInput = this.asAppliedService.shapefileConfigInput.configsInput;

      if (this.index === null || this.index === undefined) return;

      if (configsInput[this.index]) {
        const { srid, quantityColumnName } = configsInput[this.index];

        this.form = this.createForm(srid, quantityColumnName);
      } else {
        const configData = {
          index: this.index,
          quantityColumnName: null,
          srid: this.srid,
          fileNames: this.fileNames,
        } as unknown as TemporalShapefileConfigInput;

        configsInput.push(configData);

        this.form = this.createForm(this.srid);

        const shapeFileSelected = this.asAppliedService.shapeFileAnalysis.groups[this.index].selected;
        const control = this.form.get('quantityColumnName');
        control?.setValue(shapeFileSelected);
      }

      if (this.asAppliedService.shapeFileAnalysis.groups.length === 1) {
        this.asAppliedService.canBeRemoved = false;
      } else {
        this.asAppliedService.canBeRemoved = true;
      }
    }
  }

  public setConfigInput() {
    const configsInput = this.asAppliedService.shapefileConfigInput.configsInput;

    if (this.index === null || this.index === undefined) return;

    configsInput[this.index].srid = this.form.get('srid')?.value;
    configsInput[this.index].quantityColumnName = this.form.get('quantityColumnName')?.value;
  }

  public removeConfigForm() {
    const configsInput = this.asAppliedService.shapefileConfigInput.configsInput;

    if (this.index === null || this.index === undefined) return;

    if (configsInput && this.asAppliedService.shapeFileAnalysis) {
      configsInput.splice(this.index, 1);
      this.asAppliedService.shapeFileAnalysis.groups.splice(this.index, 1);

      if (configsInput.length === 1) {
        this.asAppliedService.canBeRemoved = false;
      } else {
        this.asAppliedService.canBeRemoved = true;
      }
    }
  }

  private createForm(srid?: number, quantityColumnName?: string) {
    return this.formBuilder.group({
      quantityColumnName: [quantityColumnName, Validators.required],
      srid: [srid, Validators.required],
    });
  }
}

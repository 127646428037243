import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { TDateISO } from '@app/core/types/iso-date.type';
import { ClearSkyTileType } from '@app/new-map/features/field-analysis/features/ndvi-feature/ndvi-feature.component';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { Farm } from '../../../core/interfaces/farm.interface';
import { BiomassImageSource, MapControlBiomassService } from './map-control-biomass.service';

export interface ImageUrlSource {
  url: string;
  source: BiomassImageSource;
  imageYear?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BiomassUrlService {
  private readonly baseClearskyURL = this.endpointService.foApi + '/maps/clearsky/gettiles/{z}/{x}/{y}/fields';
  private readonly baseStandardURL = this.endpointService.foApi + '/maps/overlays/rededgendvi/{z}/{x}/{y}';

  constructor(
    private farmService: FarmStateService,
    private harvestYearService: HarvestYearStateService,
    private mapControlBiomassService: MapControlBiomassService,
    private endpointService: EndpointsService
  ) {
    const farms$ = this.farmService.selectedFarms$.pipe(filterNullOrEmpty());
    const harvestYear$ = this.harvestYearService.harvestYear$.pipe(filterNullish());
    const biomassSource$ = this.mapControlBiomassService.imagesource$;
    const biomassDate$ = this.mapControlBiomassService.selectedDate$.pipe(filterNullish());
    combineLatest([farms$, harvestYear$, biomassSource$, biomassDate$])
      .pipe(
        map(([farms, year, source, date]) => {
          if (date === undefined) {
            return { url: '', source: BiomassImageSource.standard } as ImageUrlSource;
          }
          if (source === BiomassImageSource.clearsky) {
            return {
              url: this.createClearSkyUrl(farms, year, date.toISODate() as TDateISO),
              source: BiomassImageSource.clearsky,
              imageYear: date.year,
            } as ImageUrlSource;
          } else {
            return {
              url: this.createStandardUrl(farms, year, date.toISODate() as TDateISO),
              source: BiomassImageSource.standard,
              imageYear: date.year,
            } as ImageUrlSource;
          }
        }),
        tap((url: ImageUrlSource) => this.biomassUrlSubject_.next(url))
      )
      .subscribe();
  }

  private biomassUrlSubject_ = new BehaviorSubject<ImageUrlSource | undefined>(undefined);
  public biomassUrl$ = this.biomassUrlSubject_.asObservable();

  private createClearSkyUrl(farms: Farm[], year: number, date: TDateISO) {
    const farmIds = farms.map((f) => f.id).join(',');
    const sateliteImageDateQueryParam = date ? `&sateliteImageDate=${date}` : '';

    const params = `?farmIds=${farmIds}&harvestYear=${year}` + `${sateliteImageDateQueryParam}&tileType=${ClearSkyTileType.ClearSkyNDRE}`;
    return this.baseClearskyURL + params;
  }

  private createStandardUrl(farms: Farm[], year: number, date: TDateISO) {
    const maxCloudCoveragePct = 20;
    const farmIds = farms.map((f) => f.id).join(',');

    const sateliteImageDateQueryParam = date ? `&satelliteImageDate=${date}` : '';

    const params = `?farmIds=${farmIds}&harvestYear=${year}` + `&maxCloudCoveragePct=${maxCloudCoveragePct}${sateliteImageDateQueryParam}`;
    return this.baseStandardURL + params;
    return '';
  }
}
